import { ButtonProps, useToastContext } from "@ttc3k/trekker";
import {
  ImageQualityRating,
  EnumEntityReferenceType,
  OperatorByIdDocument,
  OperatorManyDocument,
  OrganizationByIdDocument,
  useOperatorUpdateImagesMutation,
  useOrganizationUpdateImagesMutation,
  type AssetFile,
  type AssetImage,
} from "gql/generated";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MediaProps } from "./Media";

/**
 * Determine if the original image array has changed
 * - If you sort the array and then return to the same sort-order,
 *   there is no need to save changes
 * - Since this also compares the order of the original and imagesToKeep
 *   array, it ensures the save changes button will be active when
 *   original images are deleted and NOT when newly uploaded images are
 *   deleted
 * @param originalArray
 * @param imagesToKeep
 * @returns
 */
const getIsUnchanged = (
  originalArray: Partial<AssetImage>[] = [],
  imagesToKeep: Partial<AssetImage>[] = [],
) =>
  originalArray.length === imagesToKeep.length &&
  originalArray.every((orig, i) => orig?._id === imagesToKeep[i]?._id);

export const useMedia = ({
  images = [],
  setSaveButtonProps,
  setPrimaryButtonProps,
  _entityId,
  entityType,
}: Omit<MediaProps, "disableFeaturedImageTag">) => {
  const { t } = useTranslation();
  const { toastFactory } = useToastContext();

  /**
   * Handle opening / closing of the upload modal
   */
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const handleUploadClick = () => setIsUploadModalOpen(!isUploadModalOpen);

  /**
   * Keep track of the selected images and load the initial images from the operator
   */
  const originalImagesRef = useRef<Partial<AssetImage>[]>(undefined);
  const [imagesToKeep, setImagesToKeep] = useState<Partial<AssetImage>[]>([
    ...images,
  ]);
  useEffect(() => {
    if (!originalImagesRef.current) {
      originalImagesRef.current = [...images];
      setImagesToKeep([...images]);
    }
  }, [images]);

  /**
   * Handle uploading and saving of the files
   * - Files are uploaded to Cloudinary, which returns JSON objects for each
   * - JSON objects are used to add new Assets to the Asset collection
   * - These Assets are then add to the end of the imagesToKeep array
   */
  const handleUploadComplete = async (
    assets: Partial<AssetFile | AssetImage>[],
  ) => {
    if (assets.length > 0) {
      setImagesToKeep((prev) => [
        ...prev,
        ...(assets as Partial<AssetImage>[]),
      ]);
    }
    setIsUploadModalOpen(false);
  };

  /**
   * Handle tracking of selected images
   * - When an image is deleted, it is removed from the imagesToKeep array and added
   *   to the imagesToDelete array
   */
  const [imagesToDelete, setImagesToDelete] = useState<Partial<AssetImage>[]>(
    [],
  );
  const handleImageDeleted = (image: Partial<AssetImage>) => {
    setImagesToKeep((prev) => prev.filter((item) => item._id !== image._id));
    setImagesToDelete((prev) => [...prev, image]);
  };

  /**
   * Handle updating the selectedImage image AFTER its caption has been updated
   * - The caption editing modal handles the saving and returns the updated Asset
   * @param image
   */
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const handleCaptionUpdated = useCallback(
    (image: Partial<AssetImage>) => {
      setImagesToKeep((prev) =>
        prev.map((item) => (item._id === image._id ? image : item)),
      );
      // We need to force the Sortable component to rerender
      // Note this is a discouraged React practice, so we ONLY do it here
      forceUpdate();
    },
    [setImagesToKeep],
  );

  /**
   * Handle sorting changes and update the imagesToKeep array accordingly
   * @param images
   */
  const handleSortableChange = async (images: Partial<AssetImage>[]) => {
    setImagesToKeep((prev) =>
      images.map(
        (image) =>
          prev.find((item) => item._id === image._id) as Partial<AssetImage>,
      ),
    );
  };

  /**
   * Handle saving changes
   */
  const [updateOperatorImages] = useOperatorUpdateImagesMutation({
    refetchQueries: [OperatorByIdDocument, OperatorManyDocument],
  });
  const [updateOrganizationImages] = useOrganizationUpdateImagesMutation({
    refetchQueries: [OrganizationByIdDocument],
  });

  // This function handles mutating the entity depending on the entityType
  // passed to this hook. It will return whether the mutation has completed
  // without errors or not
  const handleMutateEntityImages = useCallback(
    async (isApprovingOperator?: boolean): Promise<boolean> => {
      // Do not surround async functionality in try/catch to ensure errors are
      // filtered back up to the caller
      switch (entityType) {
        case EnumEntityReferenceType.Operator: {
          const res = await updateOperatorImages({
            variables: {
              entityId: _entityId,
              isApproving: isApprovingOperator,
              imagesToKeep: imagesToKeep.map((image) => image._id) as string[],
              imagesToDelete: imagesToDelete.map(
                (image) => image._id,
              ) as string[],
            },
          });
          return !!res.data?.operatorUpdateImages && !res.errors;
        }
        case EnumEntityReferenceType.Organization: {
          const res = await updateOrganizationImages({
            variables: {
              id: _entityId,
              imagesToKeep: imagesToKeep.map((image) => image._id) as string[],
              imagesToDelete: imagesToDelete.map(
                (image) => image._id,
              ) as string[],
              isLogo: false,
            },
          });
          return !!res.data?.organizationUpdateImages && !res.errors;
        }
        default:
          return false;
      }
    },
    [
      _entityId,
      entityType,
      imagesToDelete,
      imagesToKeep,
      updateOperatorImages,
      updateOrganizationImages,
    ],
  );

  const handleSaveChanges = useCallback(
    async (isApprovingOperator?: boolean) => {
      setSaveButtonProps((prev: ButtonProps) => ({
        ...prev,
        disabled: true,
      }));
      setPrimaryButtonProps?.((prev: ButtonProps) => ({
        ...prev,
        disabled: true,
      }));

      try {
        const isMutationComplete =
          await handleMutateEntityImages(isApprovingOperator);

        if (isMutationComplete) {
          toastFactory.create({
            title: t("core:SUCCESS"),
            description: t("core:MEDIA.SAVING.SUCCESS"),
          });
          originalImagesRef.current = [...imagesToKeep];
          setImagesToDelete([]);
          if (!isApprovingOperator) {
            setPrimaryButtonProps?.((prev: ButtonProps) => ({
              ...prev,
              disabled: false,
              children: t(
                "directory:OPERATOR.SIDEBAR.CTA_BUTTON_COPY.APPROVE_PROFILE",
              ),
            }));
          }
        } else {
          setSaveButtonProps((prev: ButtonProps) => ({
            ...prev,
            disabled: getIsUnchanged(originalImagesRef.current, imagesToKeep),
          }));
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("core:MEDIA.SAVING.ERROR"),
          });
        }
      } catch {
        setSaveButtonProps((prev: ButtonProps) => ({
          ...prev,
          disabled: getIsUnchanged(originalImagesRef.current, imagesToKeep),
        }));
        toastFactory.create({
          title: t("core:ERROR.TITLE"),
          description: t("core:MEDIA.SAVING.ERROR"),
        });
      }
    },
    [
      handleMutateEntityImages,
      imagesToKeep,
      setPrimaryButtonProps,
      setSaveButtonProps,
      t,
    ],
  );

  /**
   * Set the default save button props
   */
  useEffect(() => {
    const isUnchanged = getIsUnchanged(originalImagesRef.current, imagesToKeep);
    setSaveButtonProps({
      disabled: isUnchanged,
      onClick: () => handleSaveChanges(false),
    });
    setPrimaryButtonProps?.((prev: ButtonProps) => ({
      ...prev,
      onClick: () => handleSaveChanges(true),
      children: t(
        `directory:OPERATOR.SIDEBAR.CTA_BUTTON_COPY.${!isUnchanged ? "SAVE_AND_APPROVE" : "APPROVE_PROFILE"}`,
      ),
    }));
  }, [
    imagesToKeep,
    handleSaveChanges,
    setSaveButtonProps,
    setPrimaryButtonProps,
    t,
  ]);

  /**
   * Handle poor image detection on the set of imagesToKeep
   */
  const [
    isPoorQualityImageMessageVisible,
    setIsPoorQualityImageMessageVisible,
  ] = useState(false);
  useEffect(() => {
    setIsPoorQualityImageMessageVisible(
      imagesToKeep.some(
        (image) => image.qualityScore === ImageQualityRating.Low,
      ),
    );
  }, [imagesToKeep, setIsPoorQualityImageMessageVisible]);

  return {
    imagesToDelete,
    isUploadModalOpen,
    isPoorQualityImageMessageVisible,
    imagesToKeep,
    originalImagesRef,
    handleUploadClick,
    handleUploadComplete,
    handleImageDeleted,
    handleCaptionUpdated,
    handleSortableChange,
    setIsPoorQualityImageMessageVisible,
  };
};
