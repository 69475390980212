import { cva } from "styled-system/css";

export const eventLineRecipe = cva({
  base: {
    flexDir: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "300",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "border.light",
    py: 150,
  },
  variants: {
    isFirst: {
      true: {
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: "border.mid",
      },
      false: {},
    },
  },
  defaultVariants: {
    isFirst: false,
  },
});
