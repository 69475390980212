import { Center, styled } from "styled-system/jsx";
import { JsxStyleProps, RecipeVariantProps } from "styled-system/types";
import { cva } from "styled-system/css";

const skeletonStyles = cva({
  base: {
    px: "400",
    py: "300",
    gap: "250",
    bg: "bg.wa.lightest",
    borderColor: "border.light",
    borderWidth: "1px",
    borderStyle: "dashed",
    borderRadius: "100",
    flexDir: "column",
    minH: "200px",
    w: "full",
  },
  variants: {
    visual: {
      default: {},
      fullPage: {
        borderStyle: "none",
        borderRadius: 0,
        h: "full",
      },
    },
    /**
     * Two modes have been added
     * - When displaying the skeleton on a light background (e.g. main content area),
     *   we use the lightest variant (that is the default)
     * - When displaying the skeleton on a light background (e.g. left panel area),
     *   we use the light variant
     */
    mode: {
      lightest: {
        bg: "bg.wa.lightest",
      },
      light: {
        bg: "bg.wa.light",
      },
      unset: {
        bg: "unset",
      },
    },
  },
  defaultVariants: {
    visual: "default",
    mode: "lightest",
  },
});

export const Skeleton = styled(Center, skeletonStyles);

export type SkeletonProps = JsxStyleProps &
  NonNullable<RecipeVariantProps<typeof skeletonStyles>>;
