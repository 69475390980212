import { PropsWithChildren } from "react";
import styles from "styled-system/styles.css?inline";
import { StyleRoot } from "@ttc3k/trekker";
import { i18nLanguage, i18nNamespace } from "i18n";
import { I18NContextProvider } from "widgets/shared";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { ApolloProviderPublic } from "shared/apollo";
// Import Swiper styles
import swiperStyles from "swiper/css?inline";
import swiperScrollbarStyles from "swiper/css/scrollbar?inline";
import customSwiperStyles from "./components/OperatorCarousel/swiper.css?inline";
import { OperatorsWidgetContextProvider } from "./components/OperatorsContext";
import { OperatorsWidgetDetailsDisplayMode } from "./OperatorsWidget";

export type OperatorWidgetProvidersProps = PropsWithChildren & {
  widgetId: string;
  accessId: string;
  language: i18nLanguage;
  fontFamilyOverride?: string;
  operatorDetailsLink?: string;
  operatorDetailsDisplayMode?: OperatorsWidgetDetailsDisplayMode;
  displayWidgetName?: boolean;
  gridPageSize?: number;
};

export const OperatorWidgetProviders = ({
  widgetId,
  accessId,
  language,
  fontFamilyOverride,
  operatorDetailsLink,
  operatorDetailsDisplayMode = "slideout",
  displayWidgetName = false,
  gridPageSize = 25,
  children,
}: OperatorWidgetProvidersProps) => {
  posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  });

  // Register the app_context property
  posthog.register({
    app_context: "widget-operator", // This ensures all events are tagged with `app_context: "widget-operator"`
    widget_id: widgetId, // This ensures all widgets are tagged with the id
    access_id: accessId, // This is the organization's id
  });

  const ctxHeaders = {
    authorization: `Bearer ${widgetId}`,
    metadata: JSON.stringify({
      type: "ORGANIZATION",
      entityId: accessId,
    }),
    "wa-type": "operatorWidget",
  };
  return (
    <ApolloProviderPublic ctxHeaders={ctxHeaders}>
      <PostHogProvider client={posthog}>
        <StyleRoot
          styles={[
            styles,
            swiperStyles,
            swiperScrollbarStyles,
            customSwiperStyles,
          ]}
          useShadowDOM
          fontFamilyOverride={fontFamilyOverride}
        >
          <I18NContextProvider
            defaultNS={[i18nNamespace.Widgets]}
            i18nWidgetType={"operator"}
            i18nLang={language}
          >
            <OperatorsWidgetContextProvider
              lang={language}
              operatorDetailsLink={operatorDetailsLink}
              operatorDetailsDisplayMode={operatorDetailsDisplayMode}
              widgetId={widgetId}
              displayWidgetName={displayWidgetName}
              gridPageSize={gridPageSize}
            >
              {children}
            </OperatorsWidgetContextProvider>
          </I18NContextProvider>
        </StyleRoot>
      </PostHogProvider>
    </ApolloProviderPublic>
  );
};
