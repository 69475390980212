import { Tag } from "@ttc3k/trekker";
import { EnumEventAccessOperatorStatus } from "gql/generated";
import { useTranslation } from "react-i18next";

type EventStatusIndicatorProps = {
  status: EnumEventAccessOperatorStatus | null;
};

export const EventStatusIndicator = ({ status }: EventStatusIndicatorProps) => {
  const { t } = useTranslation("events", { keyPrefix: "APP.CARD" });
  return (
    <Tag
      visual={
        status === EnumEventAccessOperatorStatus.Approved
          ? "success"
          : status === EnumEventAccessOperatorStatus.ForReview
            ? "accent.purple"
            : status === EnumEventAccessOperatorStatus.Draft
              ? "primary"
              : status === EnumEventAccessOperatorStatus.Cancelled
                ? "warning"
                : "destructive"
      }
      mode={"light"}
    >
      {t(`STATUS.${status}`)}
    </Tag>
  );
};
