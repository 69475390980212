import { format } from "date-fns";
import {
  Contact,
  EnumEventAccessOperatorStatus,
  EnumEventEventLocationLocationInfoType,
  EnumEventLocationType,
  EnumEventRecurrencePatternDayOfMonth,
  EnumEventRecurrencePatternDaysOfWeek,
  EnumEventRecurrencePatternFrequency,
  EnumEventType,
  EventByIdQuery,
  EventEventLocation,
  useEventCreateMutation,
  useEventUpdateByIdMutation,
} from "gql/generated";
import i18n, { i18nLanguage, i18nNamespace } from "i18n.ts";
import { loadLanguagePacks } from "i18n.ts";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormHandler } from "shared/hooks";
import {
  createMultilingual,
  EVENT_DATE_FORMAT,
  getAddressString,
  getMultilingualString,
} from "shared/utils";
import { z } from "zod";

// Ensure the required language packs are loaded
await loadLanguagePacks(
  [i18nNamespace.Core, i18nNamespace.Events],
  "event-submit",
  i18n.language as i18nLanguage,
);

const getContactSchema = () =>
  z
    .object({
      fullAddress: z.string().optional(),
      address: z
        .object({
          line1: z.string().optional().default(""),
          line2: z.string().optional().default(""),
          city: z.string().optional().default(""),
          subdivision: z.string().optional().default(""),
          postalCode: z.string().optional().default(""),
          country: z.string().optional().default(""),
          location: z
            .object({
              type: z.string().default("Point"),
              coordinates: z.object({
                lat: z.number().min(-90).max(90),
                lng: z.number().min(-180).max(180),
              }),
            })
            .optional(),
        })
        .partial()
        .optional(),
      email: z
        .string()
        .optional()
        .refine(
          (value) => {
            if (value) {
              return z.string().email().safeParse(value).success;
            }
            return true;
          },
          {
            message: i18n.t("events:APP.MODAL.ERRORS.INVALID_EMAIL"),
          },
        ),
      phone: z
        .string()
        .optional()
        .refine(
          (value) => {
            if (value) {
              // Look for valid phone numbers
              return /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}\s\d{3}\s\d{4}|\d{10})$/.test(
                value,
              );
            }
            return true;
          },
          {
            message: i18n.t("events:APP.MODAL.ERRORS.INVALID_PHONE_NUMBER"),
          },
        ),
      tollFreePublicPhone: z.string().optional(),
      links: z
        .object({
          website: z
            .string()
            .optional()
            .refine(
              (value) => {
                if (value) {
                  return z.string().url().safeParse(value).success;
                }
                return true;
              },
              {
                message: i18n.t("events:APP.MODAL.ERRORS.INVALID_URL"),
              },
            ),
          instagram: z.string().optional(),
          x: z.string().optional(),
          bookNow: z.string().optional(),
          youtube: z.string().optional(),
          pinterest: z.string().optional(),
          googleMyBusiness: z.string().optional(),
          tiktok: z.string().optional(),
          facebook: z.string().optional(),
          tripAdvisor: z.string().optional(),
        })
        .optional()
        .default({}),
    })
    .partial()
    .optional()
    .default({});

export type EventContactFormValues = z.infer<
  ReturnType<typeof getContactSchema>
>;

const getEventLocationSchema = () =>
  z
    .object({
      venue: z.string().optional().or(z.literal("")),
      contact: getContactSchema(),
      locationInfo: z
        .object({
          _entityId: z.string().optional(),
          type: z.nativeEnum(EnumEventEventLocationLocationInfoType).optional(),
        })
        .optional()
        .default({}),
    })
    .partial()
    .optional()
    .default({});

export type EventLocationFormValues = z.infer<
  ReturnType<typeof getEventLocationSchema>
>;

const getEventCreateEditFormSchema = () =>
  z.object({
    // Step 1
    createdByEmail: z
      .string({
        required_error: `${i18n.t("events:APP.MODAL.EVENT_DETAILS.LABELS.EVENT_NAME")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
      })
      .email({
        message: i18n.t("events:APP.MODAL.ERRORS.INVALID_EMAIL"),
      }),
    locationType: z.nativeEnum(EnumEventLocationType),
    title: z
      .string({
        required_error: `${i18n.t("events:APP.MODAL.EVENT_DETAILS.LABELS.EVENT_NAME")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
      })
      .trim()
      .min(1, {
        message: `${i18n.t("events:APP.MODAL.EVENT_DETAILS.LABELS.EVENT_NAME")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
      }),
    description: z
      .string({
        required_error: `${i18n.t("events:APP.MODAL.EVENT_DETAILS.LABELS.EVENT_DESCRIPTION")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
      })
      .trim()
      .min(1, {
        message: `${i18n.t("events:APP.MODAL.EVENT_DETAILS.LABELS.EVENT_DESCRIPTION")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
      }),
    price: z.string().optional().or(z.literal("")),
    ticketUrl: z
      .string()
      .url()
      .optional()
      .or(z.literal(""))
      .refine(
        (value) => {
          if (value) {
            return z.string().url().safeParse(value).success;
          }
          return true;
        },
        {
          message: i18n.t("events:APP.MODAL.ERRORS.INVALID_URL"),
        },
      ),
    tags: z.object({
      global: z.array(z.string()).optional().default([]),
      organizationSpecific: z.array(z.string()).optional().default([]),
    }),
    // Step 2
    eventLocation: getEventLocationSchema(),
    otherLocations: z.array(getEventLocationSchema()).optional().default([]),
    access: z.object({
      operator: z
        .object({
          _entityId: z.string(),
        })
        .optional(),
      organizations: z
        .array(
          z.object({
            _entityId: z.string(),
            status: z.nativeEnum(EnumEventAccessOperatorStatus),
          }),
        )
        .default([]),
    }),
    // Step 3
    imagesToKeep: z.array(z.string()).optional().default([]),
    // Step 4
    type: z.nativeEnum(EnumEventType),
    startDate: z.string(),
    endDate: z.string().optional(),
    schedules: z
      .array(
        z.object({
          allDay: z.boolean().optional().default(false),
          timeSlots: z
            .array(
              z
                .object({
                  from: z.string(),
                  to: z.string(),
                  endsNextDay: z.boolean().optional().default(false),
                  note: z.string(),
                })
                .optional()
                .refine(
                  (data) => {
                    // There needs to be either a startTime, endTime, or note
                    if (!data?.from && !data?.to && !data?.note) {
                      return false;
                    }
                    return true;
                  },
                  {
                    message: i18n.t(
                      "events:APP.MODAL.EVENT_SCHEDULE.TIME_SLOTS.ERROR",
                    ),
                    // We'll just show the error on the startTime
                    path: ["from"],
                  },
                ),
            )
            .optional()
            .default([]),
        }),
      )
      .optional(),
    recurrencePattern: z.object({
      frequency: z.nativeEnum(EnumEventRecurrencePatternFrequency),
      interval: z.number().default(1).optional(),
      endDate: z.string().optional(),
      multiDates: z.array(z.string()).optional(),
      daysOfWeek: z
        .array(z.nativeEnum(EnumEventRecurrencePatternDaysOfWeek))
        .optional(),
      dateOfMonth: z.number().optional(),
      dayOfMonth: z.nativeEnum(EnumEventRecurrencePatternDayOfMonth).optional(),
      dayOfMonthOccurrence: z.number().optional(),
      monthAndDateOfYear: z.string().optional(),
      skipDates: z.array(z.string()).optional(),
    }),
    // Step 5
    isSubmitting: z.boolean().default(false),
  });

export type EventCreateEditFormValues = z.infer<
  ReturnType<typeof getEventCreateEditFormSchema>
>;

/**
 * Get the default values for the contact form
 * @param contact - The contact object to get the default values for
 * @returns The default values for the contact form
 */
export const getContactDefaultValue = (contact: Contact | undefined) => ({
  fullAddress: contact?.address ? getAddressString(contact.address) : "",
  address: {
    line1: contact?.address?.line1 ? contact.address.line1 : "",
    line2: contact?.address?.line2 ? contact.address.line2 : "",
    city: contact?.address?.city ? contact.address.city : "",
    subdivision: contact?.address?.subdivision
      ? contact.address.subdivision
      : "",
    postalCode: contact?.address?.postalCode ? contact.address.postalCode : "",
    country: contact?.address?.country ? contact.address.country : "",
    location: {
      type: contact?.address?.location?.type
        ? contact.address.location?.type
        : "Point",
      coordinates: {
        lng: contact?.address?.location?.coordinates[0]
          ? contact.address.location.coordinates[0]
          : 0,
        lat: contact?.address?.location?.coordinates[1]
          ? contact.address.location.coordinates[1]
          : 0,
      },
    },
  },
  email: contact?.email ?? "",
  phone: contact?.phone ?? "",
  tollFreePublicPhone: contact?.tollFreePublicPhone ?? "",
  links: {
    website: contact?.links?.website ?? "",
    instagram: contact?.links?.instagram ?? "",
    x: contact?.links?.x ?? "",
    bookNow: contact?.links?.bookNow ?? "",
    youtube: contact?.links?.youtube ?? "",
    pinterest: contact?.links?.pinterest ?? "",
    googleMyBusiness: contact?.links?.googleMyBusiness ?? "",
    tiktok: contact?.links?.tiktok ?? "",
    facebook: contact?.links?.facebook ?? "",
    tripAdvisor: contact?.links?.tripAdvisor ?? "",
  },
});

/**
 * Get the default values for the event location form
 * @param location - The location object to get the default values for
 * @returns The default values for the event location form
 */
export const getEventLocationFormValues = (location: EventEventLocation) => ({
  venue: location?.venue ? getMultilingualString(location.venue) : "",
  contact: getContactDefaultValue(location?.contact ?? {}),
  locationInfo: {
    ...(location?.locationInfo?._entityId && {
      _entityId: location.locationInfo._entityId,
    }),
    ...(location?.locationInfo?.type && {
      type: location.locationInfo.type,
    }),
  },
});

/**
 * Check if the location is empty
 * - Only the venue name is required, so we'll just make sure that is present
 * @param location - The location to check
 * @returns True if the location is empty, false otherwise
 */
export const isEmptyLocation = (location: EventLocationFormValues) => {
  return !location || !location.venue;
};

/**
 * Convert the form values to a contact object
 * @param values - The form values to convert
 * @returns The contact object
 */
export const convertContactFormValuesToContact = (
  values: EventContactFormValues,
) => ({
  email: values.email ?? "",
  phone: values.phone ?? "",
  links: {
    ...values.links,
  },
  address: {
    ...(values.address?.line1 && { line1: values.address.line1 }),
    ...(values.address?.line2 && { line2: values.address.line2 }),
    ...(values.address?.city && { city: values.address.city }),
    ...(values.address?.subdivision && {
      subdivision: values.address.subdivision,
    }),
    ...(values.address?.postalCode && {
      postalCode: values.address.postalCode,
    }),
    ...(values.address?.country && { country: values.address.country }),
    ...(values.address?.location && {
      location: {
        type: values.address.location.type,
        coordinates: [
          values.address.location.coordinates.lng ?? 0,
          values.address.location.coordinates.lat ?? 0,
        ],
      },
    }),
  },
});

/**
 * Convert the form values to an event location object
 * @param values - The form values to convert
 * @returns The event location object
 */
export const convertFormValuesToEventLocation = (
  values: EventLocationFormValues,
) => ({
  venue: createMultilingual(values.venue ?? ""),
  contact: convertContactFormValuesToContact(values.contact ?? {}),
  locationInfo: {
    ...(values.locationInfo?._entityId && {
      _entityId: values.locationInfo._entityId,
    }),
    ...(values.locationInfo?.type && {
      type: values.locationInfo.type,
    }),
  },
});

interface EventCreateEditFormOptions {
  event: EventByIdQuery["eventById"];
  defaultOrganizationId: string;
  userEmailAddress: string;
  onSuccess?: (event: EventByIdQuery["eventById"]) => void;
  onError?: (message: string) => void;
}

export const useEventCreateEditForm = ({
  event,
  defaultOrganizationId,
  userEmailAddress,
  onSuccess,
  onError,
}: EventCreateEditFormOptions) => {
  const defaultValues: EventCreateEditFormValues = {
    // Step 1
    createdByEmail: event?.createdByEmail ?? userEmailAddress ?? "",
    locationType: event?.locationType ?? EnumEventLocationType.InPerson,
    title: event?.title ? getMultilingualString(event.title) : "",
    description: event?.description
      ? getMultilingualString(event.description)
      : "",
    price: event?.price ?? "",
    ticketUrl: event?.ticketUrl ?? "",
    tags: event?.tags
      ? {
          global: event.tags.global?.map((tag) => tag._id) ?? [],
          organizationSpecific:
            event.tags.organizationSpecific?.map((tag) => tag._id) ?? [],
        }
      : {
          global: [],
          organizationSpecific: [],
        },
    // Step 2
    eventLocation: getEventLocationFormValues(
      event?.eventLocation ?? ({} as EventEventLocation),
    ),
    otherLocations:
      event?.otherLocations?.map((location) =>
        getEventLocationFormValues(location ?? ({} as EventEventLocation)),
      ) ?? [],
    access: {
      operator: event?.access.operator
        ? {
            _entityId: event.access.operator._entityId,
          }
        : undefined,
      organizations:
        event?.access.organizations.map((organization) => ({
          _entityId: organization?._entityId ?? "",
          status: organization?.status ?? EnumEventAccessOperatorStatus.Draft,
        })) ?? [],
    },
    // Step 3
    imagesToKeep: event?.images?.map((image) => image._id) ?? [],
    // Step 4
    type: event?.type ?? EnumEventType.Single,
    startDate: event?.startDate ?? format(new Date(), EVENT_DATE_FORMAT),
    endDate: event?.endDate ?? undefined,
    schedules: event?.schedules?.map((schedule) => ({
      allDay: schedule?.allDay ?? false,
      timeSlots:
        schedule?.timeSlots
          ?.map((slot) => ({
            from: slot?.from ?? "",
            to: slot?.to ?? "",
            endsNextDay: slot?.endsNextDay ?? false,
            note: slot?.note ?? "",
          }))
          .filter((slot) => !!slot) ?? [],
    })) ?? [
      {
        allDay: false,
        timeSlots: [],
      },
    ],
    recurrencePattern: {
      frequency:
        event?.recurrencePattern?.frequency ??
        EnumEventRecurrencePatternFrequency.Once,
      ...(event?.recurrencePattern?.interval && {
        interval: event.recurrencePattern.interval,
      }),
      ...(event?.recurrencePattern?.endDate && {
        endDate: event.recurrencePattern.endDate as string,
      }),
      ...(event?.recurrencePattern?.multiDates && {
        multiDates: event.recurrencePattern.multiDates as string[],
      }),
      ...(event?.recurrencePattern?.daysOfWeek && {
        daysOfWeek: event.recurrencePattern
          .daysOfWeek as EnumEventRecurrencePatternDaysOfWeek[],
      }),
      ...(event?.recurrencePattern?.dateOfMonth && {
        dateOfMonth: event.recurrencePattern.dateOfMonth as number,
      }),
      ...(event?.recurrencePattern?.dayOfMonth && {
        dayOfMonth: event.recurrencePattern
          .dayOfMonth as EnumEventRecurrencePatternDayOfMonth,
      }),
      ...(event?.recurrencePattern?.dayOfMonthOccurrence && {
        dayOfMonthOccurrence: event.recurrencePattern
          .dayOfMonthOccurrence as number,
      }),
      ...(event?.recurrencePattern?.monthAndDateOfYear && {
        monthAndDateOfYear: event.recurrencePattern
          .monthAndDateOfYear as string,
      }),
      ...(event?.recurrencePattern?.skipDates && {
        skipDates: event.recurrencePattern.skipDates as string[],
      }),
    },
    isSubmitting: false,
  };

  /**
   * Instead of using a const schema, we need to use a state variable
   * because the schema is dynamically updated when the language changes
   * - This will load the "en" schema initially, and then if the language
   *   changes, will load the schema of the new language
   */
  const [schema, setSchema] = useState<
    ReturnType<typeof getEventCreateEditFormSchema>
  >(getEventCreateEditFormSchema());

  /**
   * Dynamically update the schema when the language changes
   * - When the language is changed, we need to reload the schema to update the
   *   error messages to the new language
   */
  const { i18n } = useTranslation();
  useEffect(() => {
    const loadLanguagePacksAndUpdateSchemas = async () => {
      await loadLanguagePacks(
        [i18nNamespace.Core, i18nNamespace.Events],
        "event-submit",
        i18n.language as i18nLanguage,
      );
      setSchema(getEventCreateEditFormSchema());
    };
    loadLanguagePacksAndUpdateSchemas();
  }, [i18n.language, setSchema]);

  const [createEvent] = useEventCreateMutation();
  const [updateEvent] = useEventUpdateByIdMutation();

  return useFormHandler<EventCreateEditFormValues>(
    useCallback(
      async (data, formState) => {
        if (data && Object.keys(formState.dirtyFields).length > 0) {
          if (event && event._id) {
            const fieldsToUpdate = Object.keys(formState.dirtyFields).reduce(
              (obj, key) => ({
                ...obj,
                ...{
                  [key]:
                    ["title", "description"].indexOf(key) > -1
                      ? createMultilingual(
                          data[key as "title" | "description"] ?? "",
                        )
                      : key === "eventLocation"
                        ? convertFormValuesToEventLocation(
                            data.eventLocation as EventLocationFormValues,
                          )
                        : key === "otherLocations"
                          ? (data.otherLocations?.map((location) =>
                              convertFormValuesToEventLocation(
                                location as EventLocationFormValues,
                              ),
                            ) ?? [])
                          : data[key as keyof EventCreateEditFormValues],
                },
              }),
              {},
            );
            // If we're adding/deleting images, we need to remove them from the fieldsToUpdate object
            // and pass them as separate variables to the updateEvent mutation
            const imagesToKeep =
              (fieldsToUpdate as EventCreateEditFormValues).imagesToKeep ?? [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete (fieldsToUpdate as any).imagesToKeep;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete (fieldsToUpdate as any).isSubmitting;

            updateEvent({
              variables: {
                id: event._id,
                record: fieldsToUpdate,
                ...(imagesToKeep.length > 0 && { imagesToKeep }),
                ...(data.isSubmitting && { isSubmitting: true }),
              },
            })
              .then((result) => {
                if (!result.errors && result.data?.eventUpdateById?.record) {
                  onSuccess?.(result.data.eventUpdateById.record);
                } else {
                  onError?.(result.errors?.[0]?.message ?? "An error occurred");
                }
              })
              .catch((error) => {
                console.log(error);
                onError?.("");
              });
          } else {
            // Remove the imagesToKeep field from the data object
            delete data.imagesToKeep;
            delete data.isSubmitting;

            const defaultDate = format(new Date(), EVENT_DATE_FORMAT);
            const fieldsToCreate = {
              ...data,
              title: createMultilingual(data.title ?? ""),
              description: createMultilingual(data.description ?? ""),
              startDate: defaultDate,
              // NOTE: These will be updated in step 2
              // DEPRECATED - use eventLocation instead
              venue: createMultilingual(""),
              // DEPRECATED - use eventLocation instead
              contact: {},
              eventLocation: {
                venue: createMultilingual(""),
                contact: {},
              },
              otherLocations: [],
              // NOTE: createdBy is fetched on the server based on createdByEmail
              createdByEmail: data.createdByEmail ?? "",
              // NOTE: This will be updated in step 3
              images: [],
              // NOTE: These will be updated in step 4
              endDate: defaultDate,
              type: EnumEventType.Single,
              schedules: [
                {
                  allDay: false,
                  timeSlots: [],
                },
              ],
              recurrencePattern: {
                frequency: EnumEventRecurrencePatternFrequency.Once,
              },
              // NOTE: The operator field will be updated in step 2
              // based on the 1st venue selected
              // ...If that venue is not an operator, then the operator field will be empty
              access: {
                organizations: [
                  {
                    _entityId: defaultOrganizationId,
                    status: EnumEventAccessOperatorStatus.Draft,
                  },
                ],
              },
            };

            createEvent({
              variables: {
                record: fieldsToCreate,
                skipDefaultVenue: true,
              },
            })
              .then((result) => {
                if (!result.errors && result.data?.eventCreate?.record) {
                  onSuccess?.(result.data.eventCreate.record);
                } else {
                  onError?.(result.errors?.[0]?.message ?? "An error occurred");
                }
              })
              .catch((error) => {
                console.log(error);
                onError?.("");
              });
          }
        }
      },
      [
        createEvent,
        updateEvent,
        onSuccess,
        onError,
        defaultOrganizationId,
        event,
      ],
    ),
    defaultValues,
    { schema, mode: "onBlur" },
  );
};
