import { Modal, ModalProps } from "@ttc3k/trekker";
import { useEffect, useState } from "react";
import { EventWidgetEventDetails } from "shared/components";
import { useEventsWidgetContext } from "../EventsContext";
import { EventWithOccurrences } from "gql/generated";

export type DetailsSidePaneProps = ModalProps;

export const DetailsSidePane = ({ ...rest }: DetailsSidePaneProps) => {
  const {
    lang,
    locale,
    selectedEvent: event,
    setSelectedEvent,
  } = useEventsWidgetContext();

  // Track the open state
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!!event);
  }, [event, setSelectedEvent]);

  const handleCloseChange = ({ open }: { open: boolean }) => {
    if (!open) {
      setSelectedEvent(null);
    }
  };

  const { eventDetailsLink } = useEventsWidgetContext();

  return (
    <Modal
      open={open}
      onOpenChange={handleCloseChange}
      closeTriggerVisual={"light"}
      {...rest}
      zIndex={"max"}
    >
      {event && (
        <EventWidgetEventDetails
          event={event as EventWithOccurrences}
          lang={lang}
          locale={locale}
          eventDetailsLink={eventDetailsLink}
        />
      )}
    </Modal>
  );
};
