import Map, { MapRef, Marker, MarkerDragEvent } from "react-map-gl";
import { styled } from "styled-system/jsx";
import { RefObject, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button } from "@ttc3k/trekker";
import { RefreshDouble } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { JsxStyleProps } from "styled-system/types";

interface MapboxMapProps extends JsxStyleProps {
  mapCoordinates: { latitude: number; longitude: number };
  markerLng: number;
  markerLat: number;
  markerDraggable?: boolean;
  markerOnDragEnd?: (e: MarkerDragEvent) => void;
  mapRef?: RefObject<MapRef | null>;
  removeResetViewToMarkerButton?: boolean;
}

const StyledMapContainer = styled("div", {
  base: {
    width: "full",
    height: 400,
    borderRadius: "100",
    border: "1px solid token(colors.border.light)",
    overflow: "hidden",
    position: "relative",
  },
});

const StyledRestButton = styled(Button, {
  base: {
    position: "absolute",
    top: "300",
    right: "300",
    zIndex: 10,
    boxShadow: "lg",
  },
});

export const MapboxMap = ({
  mapRef,
  mapCoordinates,
  markerLng,
  markerLat,
  markerDraggable = false,
  markerOnDragEnd,
  removeResetViewToMarkerButton = false,
  ...restStyleProps
}: MapboxMapProps) => {
  const { t } = useTranslation("core");
  const [currentViewCoordinates, setCurrentViewCoordinates] =
    useState(mapCoordinates);

  const isViewingMarker =
    currentViewCoordinates.latitude.toFixed(2) === markerLat.toFixed(2) &&
    currentViewCoordinates.longitude.toFixed(2) === markerLng.toFixed(2);

  /**
   * Handler that gets called when a user moves away from the marker
   * This function will reset the map view to center the marker's coordinates
   */
  const handleResetViewToMarker = () => {
    mapRef?.current?.flyTo({
      center: { lat: markerLat, lng: markerLng },
      duration: 1500,
    });
  };

  return (
    <StyledMapContainer {...restStyleProps}>
      {(markerLat !== 0 || markerLng !== 0) &&
        !isViewingMarker &&
        !removeResetViewToMarkerButton && (
          <StyledRestButton
            type={"button"}
            Icon={RefreshDouble}
            mode={"light"}
            size={"lg"}
            onClick={handleResetViewToMarker}
          >
            {t("RECENTER")}
          </StyledRestButton>
        )}
      <Map
        ref={mapRef}
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          ...mapCoordinates,
          zoom: 14,
          pitch: 42.5,
        }}
        mapStyle={import.meta.env.VITE_MAPBOX_STYLESHEET}
        onMove={(e) =>
          setCurrentViewCoordinates({
            latitude: e.viewState.latitude,
            longitude: e.viewState.longitude,
          })
        }
      >
        <Marker
          longitude={markerLng}
          latitude={markerLat}
          anchor="bottom"
          draggable={markerDraggable}
          onDragEnd={markerOnDragEnd}
        >
          <styled.img
            src={"/icons/map-pin.svg"}
            alt={""}
            w={"24px"}
            h={"24px"}
          />
        </Marker>
      </Map>
    </StyledMapContainer>
  );
};
