import { Button } from "@ttc3k/trekker";
import { Box, Flex, styled } from "styled-system/jsx";
import { ArrowRight } from "iconoir-react";
import { FilterButton } from "../FilterCarousel";
import { ArrowLeft } from "iconoir-react/regular";
import { WidgetByIdWithEntitiesQuery } from "gql/generated.ts";
import { getMultilingualString } from "shared/utils";
import { useOperatorsWidgetContext } from "../OperatorsContext";
import { useScroll } from "shared/hooks";

export type TagCarouselProps = {
  tags: WidgetByIdWithEntitiesQuery["widgetById"]["filterableTags"];
  onTagSelect?: (id: string) => void;
  selectedTagId?: string;
};

const TagListContainer = styled("div", {
  base: {
    width: "100%",
    height: "100%",
    mx: "auto",
    display: "flex",
    alignItems: "center",
    columnGap: "100",
    overflowX: "auto",
    scrollBehavior: "smooth",
    // Only display the scrollbar on mobile screens:
    scrollbarWidth: { base: "auto", md: "none" },
    _scrollbar: { md: { display: "none" } },
    py: { base: "100", md: 0 },
  },
});

export const FilterCarousel = ({
  tags,
  onTagSelect,
  selectedTagId,
}: TagCarouselProps) => {
  const {
    scrollRef,
    atStart,
    atEnd,
    scrollLeftOrUp,
    scrollRightOrDown,
    isOverflowing,
  } = useScroll({
    mode: "horizontal",
    postHogEvent: {
      leftOrUp: "scroll-view-more-tags",
      rightOrDown: "scroll-view-previous-tags",
    },
  });
  const { lang } = useOperatorsWidgetContext();

  if (tags.length < 1) {
    return null;
  }

  const displayPrevArrow = !atStart && isOverflowing;
  const displayNextArrow = !atEnd && isOverflowing;

  return (
    <Box
      position="relative"
      overflowX="hidden"
      width="full"
      h={{ base: "auto", md: "48px" }}
    >
      <Box position="absolute" width="full" zIndex={3}>
        <Flex
          transition={"all 200ms ease-out"}
          position="absolute"
          left="0"
          height={displayPrevArrow ? "48px" : 0}
          py={"50"}
          width={displayPrevArrow ? "100px" : 0}
          bg={
            displayPrevArrow
              ? "linear-gradient(270deg, transparent, white 40%)"
              : undefined
          }
          display={{ base: "none", md: "flex" }}
          part={"filter__gradient-left"}
          maskImage={
            displayPrevArrow
              ? "linear-gradient(270deg, transparent, white 40%)"
              : undefined
          }
          maskComposite={displayPrevArrow ? "exclude" : undefined}
        >
          {displayPrevArrow && (
            <Button
              part={"filter__btn-prev"}
              onClick={() => scrollLeftOrUp()}
              mode={"light"}
              Icon={ArrowLeft}
              size="lg"
              border={"2px solid token(colors.border.dark)"}
              outlineColor={"transparent"}
            />
          )}
        </Flex>
        <Flex
          position="absolute"
          right="0"
          height={displayNextArrow ? "48px" : 0}
          py={"50"}
          width={displayNextArrow ? "100px" : 0}
          bg={
            displayNextArrow
              ? "linear-gradient(90deg, transparent, white 40%)"
              : undefined
          }
          justifyContent={"flex-end"}
          display={{ base: "none", md: "flex" }}
          part={"filter__gradient-right"}
          maskImage={
            displayNextArrow
              ? "linear-gradient(90deg, transparent, white 40%)"
              : undefined
          }
          maskComposite={displayNextArrow ? "exclude" : undefined}
        >
          {displayNextArrow && (
            <Button
              part={"filter__btn-next"}
              onClick={() => scrollRightOrDown()}
              mode={"light"}
              Icon={ArrowRight}
              size="lg"
              disabled={atEnd}
              border={"2px solid token(colors.border.dark)"}
              outlineColor={"transparent"}
            />
          )}
        </Flex>
      </Box>
      <TagListContainer ref={scrollRef}>
        {tags.map((tag) => {
          const isSelectedTag = tag._id === selectedTagId;
          return (
            <FilterButton
              part={`filter__btn-tag${isSelectedTag ? "--state-selected" : ""}`}
              onClick={() => onTagSelect?.(tag._id)}
              key={tag._id}
              data-selected={isSelectedTag}
            >
              {getMultilingualString(tag.name, lang)}
            </FilterButton>
          );
        })}
      </TagListContainer>
    </Box>
  );
};
