import { Flex, Grid } from "styled-system/jsx";
import { ProgressIndicatorDotsToLine } from "shared/components";
import { Button, Menu } from "@ttc3k/trekker";
import { MoreHoriz, NavArrowLeft, NavArrowRight } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { EnumEventAccessOperatorStatus, EnumOwnerType } from "gql/generated";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

type EventCreateEditModalFooterProps = {
  isSaving: boolean;
  currentStep: number;
  totalSteps: number;
  userType: EnumOwnerType | "PUBLIC";
  handleApproveClick: () => void;
  handleDeclineClick: () => void;
  handleEditClick: () => void;
  handleMenuAction: (item: { value: "cancel" | "delete" }) => void;
  handleNextClick: () => void;
  handlePreviousClick: () => void;
  handleSubmitClick: () => void;
  hideMenuButtons?: boolean;
};

export const EventCreateEditModalFooter = ({
  isSaving,
  currentStep,
  totalSteps,
  userType,
  handleApproveClick,
  handleDeclineClick,
  handleEditClick,
  handleMenuAction,
  handleNextClick,
  handlePreviousClick,
  handleSubmitClick,
  hideMenuButtons,
}: EventCreateEditModalFooterProps) => {
  const { t } = useTranslation("events", { keyPrefix: "APP.MODAL.FOOTER" });

  const { isOrganizationUser, isOwner, mode, status } =
    useEventCreateEditContext();

  return (
    <Grid
      gridTemplateColumns={mode === "approve" ? "1fr 1fr" : "1fr 1fr 1fr"}
      gridColumnGap="150"
      py="300"
      px="500"
      borderTop="1px solid"
      borderColor="border.light"
      boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.10)"
    >
      {/* The cancel/delete action buttons */}
      {userType === "PUBLIC" || hideMenuButtons ? (
        <div></div>
      ) : mode === "approve" ? (
        <Flex justifyContent="flex-start" alignItems="center" gap="150">
          <Button
            visual="primary"
            mode="light"
            isLoading={isSaving}
            onClick={handleDeclineClick}
          >
            {t("DECLINE")}...
          </Button>
        </Flex>
      ) : (
        <Menu.Root
          trigger={
            <Button
              visual="primary"
              mode="light"
              w="fit-content"
              Icon={MoreHoriz}
              isLoading={isSaving}
            />
          }
          positioning={{
            placement: "top",
            offset: {
              crossAxis: 20,
            },
          }}
          onSelect={(item: { value: string }) =>
            handleMenuAction(item as { value: "cancel" | "delete" })
          }
        >
          <Menu.Item value="cancel">{t("CANCEL")}...</Menu.Item>
          {status === EnumEventAccessOperatorStatus.Draft && isOwner && (
            <Menu.Item value="delete">{t("DELETE")}...</Menu.Item>
          )}
        </Menu.Root>
      )}

      {/* The step indicator (only shown in create/edit mode) */}
      {mode !== "approve" && (
        <Flex
          flexDir="row"
          justifyContent="center"
          alignItems="center"
          gap="150"
        >
          <Button
            visual="primary"
            mode="light"
            Icon={NavArrowLeft}
            size="sm"
            disabled={currentStep === 1}
            isLoading={isSaving}
            onClick={handlePreviousClick}
          />
          <ProgressIndicatorDotsToLine
            currentStep={currentStep}
            totalSteps={totalSteps}
            dotSize={8}
            dotSpacing={4}
          />
          <Button
            visual="accent.blue"
            Icon={NavArrowRight}
            size="sm"
            disabled={currentStep === totalSteps}
            isLoading={isSaving}
            onClick={handleNextClick}
          />
        </Flex>
      )}

      {/* The save/next action buttons */}
      <Flex justifyContent="flex-end" alignItems="center" gap="150">
        {!hideMenuButtons && mode === "approve" && (
          <>
            <Button
              visual="primary"
              mode="light"
              isLoading={isSaving}
              onClick={handleEditClick}
            >
              {t("EDIT")}
            </Button>
            <Button
              visual="success"
              isLoading={isSaving}
              onClick={handleApproveClick}
            >
              {t("APPROVE")}
            </Button>
          </>
        )}
        {!hideMenuButtons &&
          (mode === "edit" || currentStep === totalSteps) && (
            <Button
              visual="success"
              isLoading={isSaving}
              disabled={
                isOrganizationUser &&
                status === EnumEventAccessOperatorStatus.Approved
              }
              onClick={handleSubmitClick}
            >
              {isOrganizationUser ? t("APPROVE") : t("SUBMIT")}
            </Button>
          )}
      </Flex>
    </Grid>
  );
};
