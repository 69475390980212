import { Combobox } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import { useController, useFormContext } from "react-hook-form";

type ControlledComboboxRootProps = ComponentProps<typeof Combobox.Root> & {
  name: string;
};

export const ControlledComboboxRoot = ({
  name,
  hint,
  ...rest
}: ControlledComboboxRootProps) => {
  const { register, control } = useFormContext();
  const { fieldState } = useController({ name, control });

  const props = {
    ...rest,
    ...register(name),
    hint: fieldState.error?.message ?? hint,
    ...(fieldState.error
      ? { invalid: true, "data-invalid": true, "aria-invalid": true }
      : {}),
  };

  return <Combobox.Root {...props} />;
};
