import { useTranslation } from "react-i18next";
import { EmptyState } from "shared/components/root/EmptyState";
import { Flex } from "styled-system/jsx";
import { EventLocationCard } from "./EventLocationCard";
import { getAddressString } from "shared/utils/getAddressString";
import { Address } from "gql/generated";
import { Button, SimpleDialog, Text } from "@ttc3k/trekker";
import { Plus } from "iconoir-react";
import { EventLocationEditModal } from "./EventLocationEditModal";
import { useInPerson } from "./useInPerson";
import { forwardRef, useImperativeHandle, ForwardedRef } from "react";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export interface InPersonEventRef {
  addNewLocation: () => void;
}

export const InPersonEvent = forwardRef<InPersonEventRef>(
  (_, ref: ForwardedRef<InPersonEventRef>) => {
    const { t } = useTranslation("events", {
      keyPrefix: "APP.MODAL.EVENT_HOST",
    });

    const { allLocations, userType } = useEventCreateEditContext();

    const {
      eventLocation,
      otherLocations,
      isEditModalOpen,
      isDeleteConfirmationModalOpen,
      isHost,
      index,
      formFieldRoot,
      locationCopy,
      handleAddLocationClick,
      handleEditLocationClick,
      handleDeleteLocationClick,
      handleDeleteConfirmationModalActionClick,
      handleModalClose,
      handleModalDone,
    } = useInPerson();

    useImperativeHandle(ref, () => ({
      addNewLocation: () => handleAddLocationClick(true, 0),
    }));

    return (
      <>
        {!eventLocation || !eventLocation.venue ? (
          <EmptyState
            ctaButtonProps={{
              children: t("ADD_LOCATION_VENUE"),
              type: "button",
              onClick: () => handleAddLocationClick(true, 0),
            }}
          />
        ) : (
          <Flex flexDir="column" gap="400">
            <Flex flexDir="column" gap="250">
              <EventLocationCard
                isMain={true}
                title={eventLocation.venue}
                address={getAddressString(
                  (eventLocation.contact?.address as Address) ?? {},
                )}
                onEditClick={() =>
                  handleEditLocationClick(
                    true,
                    0,
                    eventLocation,
                    "eventLocation",
                  )
                }
              />
              {!otherLocations ||
                (otherLocations.length === 0 && (
                  <Button
                    visual="unstyled"
                    type="button"
                    Icon={Plus}
                    onClick={() => handleAddLocationClick(false, 0)}
                    color={{
                      base: "text.accent.blue.mid",
                      hover: "text.accent.blue.dark",
                      focusVisible: "text.accent.blue.dark",
                    }}
                    fontSize="16px"
                    width="fit-content"
                  >
                    {t("ADD_LOCATION_VENUE")}
                  </Button>
                ))}
            </Flex>

            {otherLocations && otherLocations.length > 0 && (
              <Flex flexDir="column" gap="250">
                <Flex flexDir="row" justifyContent="space-between">
                  <Text visual="bodySemiBold">{t("ADDITIONAL_LOCATIONS")}</Text>
                  <Button
                    visual="unstyled"
                    type="button"
                    Icon={Plus}
                    onClick={() =>
                      handleAddLocationClick(false, otherLocations.length)
                    }
                    color={{
                      base: "text.accent.blue.mid",
                      hover: "text.accent.blue.dark",
                      focusVisible: "text.accent.blue.dark",
                    }}
                    fontSize="16px"
                    width="fit-content"
                  >
                    {t("ADD_LOCATION_VENUE")}
                  </Button>
                </Flex>
                {otherLocations.map((location, i) => (
                  <EventLocationCard
                    key={`${location.venue}-${i}`}
                    isMain={false}
                    title={location.venue ?? ""}
                    address={getAddressString(
                      (location.contact?.address as Address) ?? {},
                    )}
                    onEditClick={() =>
                      handleEditLocationClick(
                        false,
                        i,
                        location,
                        `otherLocations.${i}`,
                      )
                    }
                    onDeleteClick={() => handleDeleteLocationClick(i)}
                  />
                ))}
              </Flex>
            )}
          </Flex>
        )}
        <EventLocationEditModal
          isOpen={isEditModalOpen}
          onClose={handleModalClose}
          onDone={handleModalDone}
          isHost={isHost}
          index={index}
          allowCustom={userType !== "PUBLIC"}
          allLocations={allLocations}
          locationCopy={locationCopy}
          formFieldRoot={formFieldRoot}
          eventLinkControlledInputProps={{
            name: `${formFieldRoot}.contact.links.website`,
            label: t("LOCATION_SELECTION.EVENT_LINK"),
            hint: t("LOCATION_SELECTION.EVENT_LINK_HINT_IN_PERSON"),
          }}
          emailControlledInputProps={{
            name: `${formFieldRoot}.contact.email`,
            label: t("LOCATION_SELECTION.EMAIL"),
          }}
          phoneControlledInputProps={{
            name: `${formFieldRoot}.contact.phone`,
            label: t("LOCATION_SELECTION.PHONE"),
          }}
        />
        <SimpleDialog
          open={isDeleteConfirmationModalOpen}
          title={t("LOCATION_SELECTION.DELETE_MODAL.TITLE")}
          message={t("LOCATION_SELECTION.DELETE_MODAL.MESSAGE")}
          cancelButtonCopy={t("LOCATION_SELECTION.DELETE_MODAL.CANCEL")}
          onCancelClick={() => handleDeleteConfirmationModalActionClick(false)}
          confirmButtonCopy={t("LOCATION_SELECTION.DELETE_MODAL.DELETE")}
          confirmButtonVisual="destructive"
          onConfirmClick={() => handleDeleteConfirmationModalActionClick(true)}
          size="md"
        />
      </>
    );
  },
);
