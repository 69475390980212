import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { useTranslation } from "react-i18next";
import { Flex } from "styled-system/jsx";
import { Text } from "@ttc3k/trekker";
import { SingleDateSelection } from "../../SingleDateSelection/SingleDateSelection";
import { RecurringEventRepeatRule } from "./RecurringEventRepeatRule";
import { useState } from "react";
import { useCallback } from "react";
import { MAX_DATE } from "shared/utils";
import { TimeSlots } from "../TimeSlots/TimeSlots";
import { SkipDates } from "./SkipDates";

export const RecurringEventSchedule = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.RECURRING_EVENT",
  });

  const { control, setValue } = useFormContext<EventCreateEditFormValues>();

  // We need to reduce the z-index of the other date selections when one is open
  // to avoid the trigger button from remaining active
  const [reducedZIndex, setReducedZIndex] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: -1 });
  const handleDisableDates = useCallback(
    (isOpen: boolean, index: number) => setReducedZIndex({ isOpen, index }),
    [],
  );

  const startDate = useWatch({ control, name: "startDate" });
  const endDate = useWatch({ control, name: "recurrencePattern.endDate" });

  return (
    <Flex flexDir="column" gap="250">
      <Flex flexDir="column" gap="100">
        <Text visual="bodySemiBold">{t("TITLE")}</Text>
        <Text visual="bodyRegular" color="text.mid">
          {t("DESCRIPTION")}
        </Text>
      </Flex>

      {/* The start date */}
      <Flex flexDir="column" gap="50">
        <Text visual="smallMedium" color="text.mid">
          {t("START_DATE")}
        </Text>
        <SingleDateSelection
          index={0}
          reduceZIndex={reducedZIndex.isOpen && reducedZIndex.index !== 0}
          value={startDate}
          onValueChange={(value) =>
            setValue("startDate", value, { shouldDirty: true })
          }
          onOpenChange={handleDisableDates}
        />
      </Flex>

      {/* The repeat rule */}
      <RecurringEventRepeatRule />

      {/*
      The end date 
      - Note for events with NO end date, we set the end date to 9999-12-31
      - This date will be hidden from the user, but is required for server validation
      */}
      <Flex flexDir="column" gap="50">
        <Text visual="smallMedium" color="text.mid">
          {t("END_DATE")}
        </Text>
        <SingleDateSelection
          index={1}
          reduceZIndex={reducedZIndex.isOpen && reducedZIndex.index !== 1}
          min={startDate}
          value={!endDate || endDate === MAX_DATE ? null : endDate}
          onValueChange={(value) =>
            setValue("recurrencePattern.endDate", value, {
              shouldDirty: true,
            })
          }
          onOpenChange={handleDisableDates}
          onClearDatesClick={() =>
            setValue("recurrencePattern.endDate", MAX_DATE, {
              shouldDirty: true,
            })
          }
          clearDatesLabel={t("END_DATE_CLEAR")}
        />
      </Flex>

      <SkipDates />

      <TimeSlots scheduleIndex={0} allowMultipleTimeSlots={true} />
    </Flex>
  );
};
