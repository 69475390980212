import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  i18nLanguage,
  i18nNamespace,
  i18nWidgetType,
  loadLanguagePacks,
} from "i18n";
import i18n from "i18n";

export type I18NContextValues = {
  loading: boolean;
};

type I18NContextProviderProps = PropsWithChildren & {
  defaultNS: i18nNamespace | i18nNamespace[];
  i18nWidgetType: i18nWidgetType;
  i18nLang?: i18nLanguage;
};

const context = createContext<I18NContextValues | null>(null);

export const I18NContextProvider = ({
  defaultNS,
  i18nWidgetType,
  i18nLang,
  children,
}: I18NContextProviderProps) => {
  const [loadingLanguages, setLoadingLanguages] = useState(true);

  useEffect(() => {
    async function loadLanguages() {
      setLoadingLanguages(true);
      const namespaces = Array.isArray(defaultNS)
        ? [...defaultNS]
        : [defaultNS];

      try {
        await loadLanguagePacks(namespaces, i18nWidgetType, i18nLang);
        // Explicitly set the language after resources are loaded
        await i18n.changeLanguage(i18nLang);
      } catch (error) {
        console.error("Failed to load language packs:", error);
      } finally {
        setLoadingLanguages(false);
      }
    }

    loadLanguages();
  }, [defaultNS, i18nWidgetType, i18nLang]);

  if (loadingLanguages) {
    return null; // Or a loading spinner
  }

  return (
    <context.Provider value={{ loading: loadingLanguages }}>
      {children}
    </context.Provider>
  );
};

export function useI18NContext() {
  const i18nContext = useContext(context);

  if (!i18nContext) {
    throw new Error("useI18NContext must be used within a I18NContextProvider");
  }

  return i18nContext;
}
