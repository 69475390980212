import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// NOTE: importing directly from the getClientUrl is required for the build to work on DEV (NO IDEA WHY)
import { getClientUrl } from "./shared/utils/getClientUrl";

export enum i18nLanguage {
  en = "en",
  es = "es",
  fr = "fr",
}

export enum i18nNamespace {
  Core = "core",
  Home = "home",
  Directory = "directory",
  CustomLists = "customLists",
  Campaigns = "campaigns",
  Widgets = "widgets",
  Settings = "settings",
  Onboarding = "onboarding",
  PostOnboarding = "postOnboarding",
  Events = "events",
}

export type i18nWidgetType = "event" | "event-submit" | "operator" | "";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: i18nLanguage.en, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

/**
 * Downloads and adds the new language/namespace to i18n
 * @param lng
 * @param ns
 * @returns
 */
const addLocaleAndNamespace = async (
  lng: i18nLanguage,
  ns: i18nNamespace,
  widgetType: i18nWidgetType,
) => {
  // If the bundle has already been added, no need to load it again
  if (i18n.getResourceBundle(lng, ns)) {
    return i18n;
  }

  // Move CLIENT_URL initialization here where it's used
  const CLIENT_URL = getClientUrl();

  /**
   * If we're loading language files in an embeddable component that is not
   * run on our local environment, return the url that points to the hosted
   * language files
   **/
  const baseUrl =
    import.meta.env.VITE_ENVIRONMENT !== "local" && widgetType
      ? // We can load the JSON directly from the front-end and bipass the API
        `${CLIENT_URL}/widget/${widgetType}`
      : "";

  // Ensure a failed load does not crash the app or embedded widget
  try {
    const response = await fetch(`${baseUrl}/locales/${lng}/${ns}.json`);
    const resources = await response.json();
    return i18n.addResourceBundle(lng, ns, resources, false, true);
  } catch {
    return i18n;
  }
};

// Set the default language to "en"
i18n.changeLanguage(i18nLanguage.en);

export const loadLanguagePacks = async (
  namespaces: i18nNamespace[],
  widgetType: i18nWidgetType = "",
  i18Lang?: i18nLanguage,
) => {
  return Promise.all(
    namespaces.map((ns) =>
      addLocaleAndNamespace(
        i18Lang ?? (i18n.language as i18nLanguage),
        ns,
        widgetType,
      ),
    ),
  );
};

export default i18n;
