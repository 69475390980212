import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { useMemo, useState } from "react";
import { getRecurrencePatternDates } from "shared/utils";
import _ from "lodash";

export const useSkipDates = () => {
  const { control, setValue } = useFormContext<EventCreateEditFormValues>();

  // Watch for changes in the recurrence pattern, startDate, and the skipDates
  const recurrencePattern = useWatch({ control, name: "recurrencePattern" });
  const startDate = useWatch({ control, name: "startDate" });
  const skipDates = useWatch({ control, name: "recurrencePattern.skipDates" });

  /**
   * Memoize the skippable dates
   * @returns An array of skippable dates
   */
  const skippableDates = useMemo<{ label: string; value: string }[]>(() => {
    const dates = getRecurrencePatternDates(recurrencePattern, startDate).map(
      (date) => ({
        label: date,
        value: date,
      }),
    );

    // We need to ensure that any current skip dates are still valid (i.e., they match the pattern)
    const validSkipDates =
      skipDates?.filter((date) => dates.some((d) => d.value === date)) ?? [];
    if (skipDates?.length !== validSkipDates.length) {
      setValue("recurrencePattern.skipDates", validSkipDates, {
        shouldDirty: true,
      });
    }

    return dates;
  }, [recurrencePattern, skipDates, startDate, setValue]);

  // State for the filtered skippable dates
  const [filteredSkippableDates, setFilteredSkippableDates] =
    useState<{ label: string; value: string }[]>(skippableDates);

  // When the combobox opens, show all dates
  const handleComboboxOpenChange = () =>
    setFilteredSkippableDates(skippableDates);

  // When a date is selected, update the skipDates
  const handleComboboxValueChange = (value: string[]) => {
    setValue("recurrencePattern.skipDates", _.sortBy(value), {
      shouldDirty: true,
    });
  };

  // When the user types in the combobox, filter the dates
  const handleComboboxInputValueChange = (search: string) => {
    const filtered = skippableDates.filter((date) =>
      date.label.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredSkippableDates(filtered.length > 0 ? filtered : skippableDates);
  };

  return {
    skipDates,
    filteredSkippableDates,
    handleComboboxOpenChange,
    handleComboboxValueChange,
    handleComboboxInputValueChange,
  };
};
