import {
  Heading,
  Text,
  Button,
  Link,
  Carousel,
  Modal,
  ModalProps,
  Spinner,
} from "@ttc3k/trekker";
import { Dialpad, SelectWindow, SendDiagonal } from "iconoir-react";
import { Box, Center, Flex } from "styled-system/jsx";
import { css } from "styled-system/css";
import { HoursOfOperation } from "./HoursOfOperation";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOperatorsWidgetContext } from "widgets/operators/components/OperatorsContext/OperatorsContext";
import {
  createGoogleMapsUrl,
  getAddressString,
  getBlurhashDataUrl,
  getFormattedOperatorDetailsLink,
  getMultilingualString,
  isLandscape,
} from "shared/utils";
import _ from "lodash";
import { CloudinaryImage, StaticMap, WhereaboutsLogo } from "shared/components";
import { AssetImage, OperatorWithOverrides } from "gql/generated";
import {
  limitFill,
  scale,
} from "@cloudinary/transformation-builder-sdk/actions/resize";
import { autoGravity } from "@cloudinary/transformation-builder-sdk/qualifiers/gravity";

export type DetailsSidePaneProps = {
  selectedOperatorId: string | undefined;
} & ModalProps;

export const DetailsSidePane = ({
  selectedOperatorId,
  ...rest
}: DetailsSidePaneProps) => {
  const { t } = useTranslation();
  const { operators, lang, operatorDetailsLink } = useOperatorsWidgetContext();
  const operator = operators?.find((op) => op._entityId === selectedOperatorId);
  const coordinates: [number, number] = [
    operator?.contact.address?.location?.coordinates[0] ?? 0,
    operator?.contact.address?.location?.coordinates[1] ?? 0,
  ];
  const isValidCoordinates = coordinates[0] !== 0 && coordinates[1] !== 0;
  const isValidHoursOfOperation =
    operator &&
    !_.isEmpty(operator.hoursOfOperation) &&
    Object.keys(operator.hoursOfOperation).length > 0;

  const shortDescription = operator?.overrides?.shortDescription
    ? getMultilingualString(operator.overrides.shortDescription, lang)
    : getMultilingualString(operator?.shortDescription ?? {}, lang);
  const description = operator?.overrides?.description
    ? getMultilingualString(operator.overrides.description, lang)
    : getMultilingualString(operator?.description ?? {}, lang);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!!selectedOperatorId);
  }, [selectedOperatorId]);

  /**
   * When the screen is < 768px in width, the image is full width
   * - In these cases, we need to account for a possible scrollbar (e.g. mobile vs desktop)
   *   and use the scrollWidth of the main flex contianer as the image width to fetch
   *   from Cloudinary
   * - Unfortunately, that main flex container isn't drawn until the operator details are
   *   found, so need to run this code in a timeout
   */
  const [imageWidth, setImageWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  // Recursive function to wait for the UI to draw
  const checkScrollWidth = useCallback(() => {
    if (contentRef.current) {
      setImageWidth(contentRef.current.scrollWidth);
    } else {
      setTimeout(() => checkScrollWidth(), 1);
    }
  }, [setImageWidth]);
  // Start the size checking when the operator changes
  useEffect(() => {
    setImageWidth(0);
    setTimeout(() => checkScrollWidth(), 1);
  }, [checkScrollWidth, operator]);

  // Set the image array for the carousel
  const overrideImages = _.get(operator, "overrides.images", []);
  const images =
    overrideImages.length > 0 ? overrideImages : _.get(operator, "images", []);

  // Open the operator details link in a new tab/window
  const handleOperatorDetailsLinkClick = () => {
    window.open(
      getFormattedOperatorDetailsLink(
        operator! as OperatorWithOverrides,
        operatorDetailsLink!,
      ),
      "_blank",
    );
  };

  return (
    <Modal
      open={open}
      onOpenChange={({ open }: { open: boolean }) => setOpen(open)}
      closeTriggerVisual={"light"}
      {...rest}
      zIndex={"max"}
    >
      {!operator ? (
        <Heading mx={400} my={600}>
          {t("widgets:OPERATOR.ERROR.FETCH")}
        </Heading>
      ) : (
        <Flex flexDir={"column"} h={"100%"} ref={contentRef}>
          <Box position="sticky" top={0} h="400px">
            {images.length > 0 && imageWidth > 0 ? (
              <Carousel
                images={images}
                controlClassName={css({ pb: "0" })}
                tagClassName={css({ pb: "0" })}
                render={(image: Partial<AssetImage>) => (
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    h="400px"
                    style={{
                      backgroundImage: getBlurhashDataUrl(image.blurhash),
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <CloudinaryImage
                      includeBgBlurHash={false}
                      image={image}
                      imageStyleProps={{
                        maxW: "100%",
                        maxH: "100%",
                        h: "400px",
                        aspectRatio: "unset",
                      }}
                      // Images with text need to be to contain so that all of the text is visible
                      style={{
                        objectFit: image.detectedObjects?.hasText
                          ? "contain"
                          : "cover",
                      }}
                      resize={
                        window.innerWidth < 768
                          ? scale().height(400)
                          : isLandscape(image)
                            ? limitFill().height(400).gravity(autoGravity())
                            : scale().height(400)
                      }
                    />
                  </Flex>
                )}
              />
            ) : images.length === 0 ? (
              <CloudinaryImage
                imageStyleProps={{
                  h: "400px",
                  borderStyle: "none",
                  borderColor: "transparent",
                  borderRadius: "0",
                  aspectRatio: `${imageWidth} : 400`,
                  width: "full",
                }}
              />
            ) : (
              <Center h="400px" w="full">
                <Spinner width={"40px"} height={"40px"} />
              </Center>
            )}
          </Box>
          <Flex
            part={"details__main"}
            flexDir={"column"}
            flexGrow={1}
            p="600"
            bg={"bg.lightest"}
            zIndex={1}
            gap="300"
          >
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="400"
              mb="150"
            >
              <Heading size={"h3"}>
                {getMultilingualString(operator.name, lang)}
              </Heading>
              {operatorDetailsLink && (
                <Button
                  part={"details__btn-see-more"}
                  visual="primary"
                  flexShrink={0}
                  py="9px"
                  onClick={handleOperatorDetailsLinkClick}
                >
                  {t("widgets:OPERATOR.DETAILS_SIDE_PANE.SEE_MORE")}
                </Button>
              )}
            </Flex>

            {/* Link to Google Maps + Buttons for website / phone / email */}
            <Flex flexDir="column" gap="200">
              {operator.contact.address && (
                <Flex flexDir="column" gap="50">
                  <Text visual="smallSemiBold">
                    {t("widgets:OPERATOR.DETAILS_SIDE_PANE.ADDRESS.TITLE")}
                  </Text>
                  <Link
                    justifyContent="flex-start"
                    href={createGoogleMapsUrl(operator.contact.address)}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="sm"
                    color={{
                      base: "text.mid",
                      hover: "text.dark",
                      focusVisible: "text.dark",
                    }}
                    textDecoration="underline"
                  >
                    {getAddressString(operator.contact.address)}
                  </Link>
                </Flex>
              )}
              {(operator.contact.links?.website ||
                operator.contact.phone ||
                operator.contact.email) && (
                <Flex flexDir="row" gap={"200"}>
                  {operator.contact.links?.website && (
                    <Button
                      part={"details__btn-contact"}
                      mode={"light"}
                      onClick={() => {
                        if (operator.contact.links?.website)
                          window.open(operator.contact.links.website);
                      }}
                      Icon={SelectWindow}
                    >
                      {t("widgets:OPERATOR.DETAILS_SIDE_PANE.WEBSITE")}
                    </Button>
                  )}
                  {operator.contact.phone && (
                    <Button
                      part={"details__btn-contact"}
                      mode={"light"}
                      onClick={() =>
                        (window.location.href = `tel:${operator.contact.phone}`)
                      }
                      Icon={Dialpad}
                    >
                      {t("widgets:OPERATOR.DETAILS_SIDE_PANE.PHONE")}
                    </Button>
                  )}
                  {operator.contact.email && (
                    <Button
                      part={"details__btn-contact"}
                      mode={"light"}
                      onClick={() =>
                        (window.location.href = `mailto:${operator.contact.email}`)
                      }
                      Icon={SendDiagonal}
                    >
                      {t("widgets:OPERATOR.DETAILS_SIDE_PANE.EMAIL")}
                    </Button>
                  )}
                </Flex>
              )}
            </Flex>

            {(shortDescription || description) && (
              <Flex flexDir="column" gap="150">
                {shortDescription && (
                  <Text visual="bodySemiBold">{shortDescription}</Text>
                )}
                {description && (
                  <Text visual={"bodyRegular"} color={"text.mid"}>
                    {description}
                  </Text>
                )}
              </Flex>
            )}

            {isValidHoursOfOperation && (
              <Flex flexDir="column" gap="50">
                <Text visual={"bodySemiBold"}>
                  {t(
                    "widgets:OPERATOR.DETAILS_SIDE_PANE.OPERATING_HOURS.TITLE",
                  )}
                </Text>
                <HoursOfOperation data={operator.hoursOfOperation} />
              </Flex>
            )}

            {operator.pricing && (
              <Flex flexDir="column" gap="50">
                <Text visual={"bodySemiBold"}>
                  {t("widgets:OPERATOR.DETAILS_SIDE_PANE.PRICING.TITLE")}
                </Text>
                <Text visual={"bodyRegular"}>
                  {t(
                    `widgets:LOOKUPS.OPERATOR_PRICING.${operator.pricing}_LONG`,
                  )}
                </Text>
              </Flex>
            )}

            {isValidCoordinates && (
              <StaticMap
                longitude={coordinates[0]}
                latitude={coordinates[1]}
                style={{
                  minHeight: "190px",
                  transition: "height 0.3s ease",
                }}
              />
            )}

            <Link
              href={"https://www.tourism.tech/"}
              display={"flex"}
              flexDir="column"
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap="75"
              userSelect={"none"}
              rel="noopener noreferrer"
              target="_blank"
              color={"text.dark"}
            >
              <Text visual={"smallTag"} opacity={0.5}>
                {t("widgets:POWERED_BY")}
              </Text>
              <WhereaboutsLogo width={100} height={"auto"} />
            </Link>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};
