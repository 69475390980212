import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { Flex } from "styled-system/jsx";
import { Button, Select, Text } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import {
  RecurrencePatternOptionValues,
  useRecurringEventRepeatRule,
} from "./useRecurringEventRepeatRule";
import { ControlledSelectRoot } from "shared/components";
import {
  EnumEventRecurrencePatternDaysOfWeek,
  EnumEventRecurrencePatternFrequency,
} from "gql/generated";
import { EventDayOfWeek } from "shared/utils";
import { useEventCreateEditContext } from "../../EventCreateEditContext/EventCreateEditContext";

export const RecurringEventRepeatRule = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.RECURRING_EVENT.REPEAT_RULE",
  });

  const { locale } = useEventCreateEditContext();

  const {
    options,
    selectedOption,
    intervalOptions,
    frequencyOptions,
    monthlyOptions,
    selectedFrequency,
    selectedMonthlyOption,
    handleDefaultPatternChange,
    handleFrequencyChange,
    handleMonthlyOptionChange,
    handleDayOfWeekClick,
  } = useRecurringEventRepeatRule({ locale });

  const { control, setValue } = useFormContext<EventCreateEditFormValues>();

  const interval = useWatch({ control, name: "recurrencePattern.interval" });
  const daysOfWeek = useWatch({
    control,
    name: "recurrencePattern.daysOfWeek",
  });

  return (
    <>
      <Flex flexDir="column" gap="50">
        <Text visual="smallMedium" color="text.mid">
          {t("TITLE")}
        </Text>
        <Select.Root
          items={options}
          value={selectedOption ? [selectedOption] : ["N/A"]}
          multiple={false}
          onValueChange={({ value }: { value: string[] }) =>
            handleDefaultPatternChange(
              value[0] as RecurrencePatternOptionValues,
            )
          }
        >
          {options.map((option) => (
            <Select.Item key={option.value} item={option} />
          ))}
        </Select.Root>
      </Flex>
      {/* 
    If the selectedOption is CUSTOM, we need to let them choose their custom details 
    - This is outside of the other Flex because it needs to inherit the spacing from the parent container
    */}
      {selectedOption === RecurrencePatternOptionValues.Custom && (
        <Flex flexDir="column" gap="150">
          {/* Pick the interval and frequency */}
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            gap="150"
          >
            <Text visual="bodyRegular" color="text.mid" flexShrink={0}>
              {t("REPEAT_EVERY")}
            </Text>
            <ControlledSelectRoot
              name="recurrencePattern.interval"
              items={intervalOptions}
              value={[interval?.toString() ?? ""]}
              onValueChange={({ value }: { value: string[] }) =>
                setValue("recurrencePattern.interval", Number(value[0]), {
                  shouldDirty: true,
                })
              }
              multiple={false}
              width="80px"
              minWidth="80px"
            >
              {intervalOptions.map((item) => (
                <Select.Item key={item.value} item={item} />
              ))}
            </ControlledSelectRoot>
            <ControlledSelectRoot
              name="recurrencePattern.frequency"
              items={frequencyOptions}
              value={[selectedFrequency]}
              onValueChange={({ value }: { value: string[] }) =>
                handleFrequencyChange(
                  value[0] as unknown as EnumEventRecurrencePatternFrequency,
                )
              }
              multiple={false}
            >
              {frequencyOptions.map((item) => (
                <Select.Item key={item.value} item={item} />
              ))}
            </ControlledSelectRoot>
          </Flex>

          {/* Weekly events */}
          {selectedFrequency === EnumEventRecurrencePatternFrequency.Weekly && (
            <Flex
              flexDir="row"
              justifyContent="flex-start"
              alignItems="center"
              gap="150"
            >
              <Text visual="bodyMedium" color="text.mid">
                {t("ON")}
              </Text>
              <Flex flexDir="row" gap="100" flexWrap="wrap" width="full">
                {/* For each day of the week, we need to show a checkbox */}
                {Object.values(EventDayOfWeek).map((day) => (
                  <Button
                    key={day}
                    visual="accent.blue"
                    mode={
                      daysOfWeek?.includes(
                        day as unknown as EnumEventRecurrencePatternDaysOfWeek,
                      )
                        ? "dark"
                        : "light"
                    }
                    size="sm"
                    onClick={() => handleDayOfWeekClick(day)}
                    type="button"
                    flexGrow={1}
                  >
                    {t(`${day}`)}
                  </Button>
                ))}
              </Flex>
            </Flex>
          )}

          {/* Monthly events */}
          {selectedFrequency ===
            EnumEventRecurrencePatternFrequency.Monthly && (
            <Select.Root
              items={monthlyOptions}
              value={[selectedMonthlyOption]}
              onValueChange={({ value }: { value: string[] }) =>
                handleMonthlyOptionChange(value[0])
              }
              multiple={false}
            >
              {monthlyOptions.map((option) => (
                <Select.Item key={option.value} item={option} />
              ))}
            </Select.Root>
          )}
        </Flex>
      )}
    </>
  );
};
