import React from "react";
import ReactDOM from "react-dom/client";
import OperatorsWidget, {
  OperatorsWidgetDetailsDisplayMode,
  OperatorsWidgetListDisplayMode,
} from "./OperatorsWidget";
import { i18nLanguage } from "i18n";
import { OperatorWidgetProviders } from "./OperatorWidgetProviders";

const mountApp = (
  mountPoint: HTMLElement,
  widgetId: string,
  accessId: string,
  language: i18nLanguage,
  operatorDetailsLink?: string,
  operatorDetailsDisplayMode?: OperatorsWidgetDetailsDisplayMode,
  fontFamilyOverride?: string,
  listDisplayMode?: OperatorsWidgetListDisplayMode,
  displayWidgetName?: string,
  gridPageSize?: number,
) => {
  ReactDOM.createRoot(mountPoint).render(
    <React.StrictMode>
      <OperatorWidgetProviders
        widgetId={widgetId}
        accessId={accessId}
        language={language}
        operatorDetailsLink={operatorDetailsLink}
        operatorDetailsDisplayMode={operatorDetailsDisplayMode}
        fontFamilyOverride={fontFamilyOverride}
        displayWidgetName={displayWidgetName === "true"}
        gridPageSize={gridPageSize}
      >
        <OperatorsWidget listDisplayMode={listDisplayMode} />
      </OperatorWidgetProviders>
    </React.StrictMode>,
  );
};

class WhereaboutsOperatorsWidget extends HTMLElement {
  constructor() {
    super();
    this.validateOperatorDetailsLink();
  }

  private validateOperatorDetailsLink() {
    const link = this.getAttribute("operator-details-link");
    const mode = this.getAttribute("operator-details-display-mode");

    if (link && !link.includes("{OPERATOR_ID}"))
      this.throwError(
        "Missing {OPERATOR_ID} wildcard in operator-details-link",
      );

    if (!link && mode === "link")
      this.throwError(
        "operator-details-link is required when operator-details-display-mode is set to 'link'",
      );
  }

  private parseAttribute<T = string>(
    attributeName: string,
    required = false,
    permittedValues: string[] = [],
  ) {
    const value = this.getAttribute(attributeName);
    if (required && !value)
      this.throwError(`missing required attribute ${attributeName}`);

    if (value && permittedValues.length && !permittedValues.includes(value)) {
      this.throwError(
        `invalid value ${value} for attribute ${attributeName}. Expected: ${permittedValues}`,
      );
    }
    // If we've gotten this far and `required` is set, we know that `value` is non-null:
    // let's tell the typing system.
    return (required ? value! : value || undefined) as T;
  }

  private throwError(message: string) {
    const docs = "https://ttc.helpscoutdocs.com/article/81-embedding-widgets";
    throw new Error(`${this.tagName}: ${message}. More information: ${docs}`);
  }

  connectedCallback() {
    mountApp(
      this,
      this.parseAttribute("widget-id", true),
      this.parseAttribute("access-id", true),
      this.parseAttribute<i18nLanguage>("lang") ?? "en",
      this.parseAttribute("operator-details-link"),
      this.parseAttribute<OperatorsWidgetDetailsDisplayMode>(
        "operator-details-display-mode",
        false,
        ["link", "slideout"],
      ),
      this.parseAttribute("font-family-override"),
      this.parseAttribute<OperatorsWidgetListDisplayMode>(
        "operator-list-display-mode",
        false,
        ["grid", "carousel"],
      ),
      this.parseAttribute("display-widget-name", false, ["true", "false"]),
      Number(this.parseAttribute("grid-page-size") ?? 25),
    );
  }
}

// Register the custom element
if (!customElements.get("whereabouts-operators-widget")) {
  customElements.define(
    "whereabouts-operators-widget",
    WhereaboutsOperatorsWidget,
  );
}
