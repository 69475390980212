import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { PropsWithChildren } from "react";

interface ApolloProviderPublicProps extends PropsWithChildren {
  ctxHeaders: Record<string, string>;
}

export const ApolloProviderPublic = ({
  ctxHeaders,
  children,
}: ApolloProviderPublicProps) => {
  const apolloLink = setContext(async (_, { headers, ...rest }) => {
    return {
      ...rest,
      headers: {
        ...headers,
        ...ctxHeaders,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_API_SERVER_URL + "/graphql/public",
  });
  const removeTypenameLink = removeTypenameFromVariables();
  const link = from([apolloLink, removeTypenameLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
      },
    },
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
