import { ApolloError } from "@apollo/client";
import { Locale } from "date-fns";
import { es } from "date-fns/locale";
import { fr } from "date-fns/locale";
import {
  EnumEventAccessOperatorStatus,
  EnumOwnerType,
  EventByIdQuery,
} from "gql/generated";
import i18n from "i18n";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { MultilingualLangKey } from "shared/types";
import { useEventTags } from "./useEventTags";
import { useEventOperatorAndOrganizationList } from "./useEventOperatorAndOrganizationList";
import { LocationListData } from "apps/events/types/LocationListData";
import {
  ComboboxData,
  ComboboxGroupData,
} from "apps/events/types/ComboboxData";
import { ModalMode } from "apps/events/types/ModalMode";
import { useEventSteps } from "./useEventSteps";
import { useEventActions } from "./useEventActions";

export type EventCreateEditContextValues = {
  isOpen: boolean;
  mode: ModalMode;
  userEmailAddress: string;
  isOwner: boolean;
  userType: EnumOwnerType | "PUBLIC";
  isOrganizationUser: boolean;
  isOperatorUser: boolean;
  defaultOrganizationId: string;
  defaultOperatorEntityIds: string[];
  limitHostToOperatorEntityId?: string;
  lang: MultilingualLangKey;
  locale: Locale | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  // EVENT
  eventId?: string;
  event: EventByIdQuery["eventById"];
  isFetchingEvent: boolean;
  fetchEventError: ApolloError | undefined;
  status: EnumEventAccessOperatorStatus;
  isSavingStatusChange: boolean;
  isConfirmationModalOpen: boolean;
  confirmationModalMessage: string;
  handleApproveClick: () => void;
  handleConfirmationModalActionClick: (confirmed: boolean) => void;
  handleEditClick: () => void;
  handleMenuAction: ({
    value,
  }: {
    value: "cancel" | "decline" | "delete";
  }) => void;
  updateEvent: (event: EventByIdQuery["eventById"]) => void;
  // MODAL
  onClose: () => void;
  hideMenuButtons?: boolean;
  // EVENT STEP TRACKING
  currentStep: number;
  totalSteps: number;
  handleNextClick: () => void;
  handlePreviousClick: () => void;
  // STEP 1
  globalEventTags: ComboboxGroupData[];
  organizationEventTags: ComboboxData[];
  // STEP 2
  allLocations: LocationListData[];
};

export type EventCreateEditContextProps = {
  initialMode: ModalMode;
  onEventUpdated: (event: EventByIdQuery["eventById"]) => void;
  onEventDeleted: (eventId: string) => void;
  hideMenuButtons?: boolean;
} & Omit<
  EventCreateEditContextValues,
  | "mode"
  | "isOwner"
  | "isOrganizationUser"
  | "isOperatorUser"
  | "lang"
  | "locale"
  | "event"
  | "isFetchingEvent"
  | "fetchEventError"
  | "status"
  | "isSavingStatusChange"
  | "isConfirmationModalOpen"
  | "confirmationModalMessage"
  | "handleApproveClick"
  | "handleConfirmationModalActionClick"
  | "handleEditClick"
  | "handleMenuAction"
  | "updateEvent"
  | "loading"
  | "error"
  | "currentStep"
  | "totalSteps"
  | "handleNextClick"
  | "handlePreviousClick"
  | "globalEventTags"
  | "organizationEventTags"
  | "allLocations"
> &
  PropsWithChildren;

export const eventCreateEditContext =
  createContext<EventCreateEditContextValues | null>(null);

export const EventCreateEditContextProvider = ({
  isOpen,
  initialMode,
  userEmailAddress,
  userType,
  defaultOrganizationId,
  defaultOperatorEntityIds,
  limitHostToOperatorEntityId,
  eventId,
  onClose,
  onEventUpdated,
  onEventDeleted,
  hideMenuButtons,
  children,
}: EventCreateEditContextProps) => {
  const isOrganizationUser = userType === EnumOwnerType.Organization;
  const isOperatorUser = userType === EnumOwnerType.Operator;

  /**
   * Keep track of the event steps
   */
  const {
    currentStep,
    totalSteps,
    handleNextClick,
    handlePreviousClick,
    setCurrentStep,
  } = useEventSteps(isOpen);

  /**
   * When the eventId changes, we'll need to fetch the event details
   */
  const {
    mode,
    event,
    status,
    loading: isFetchingEvent,
    error: fetchEventError,
    isSaving: isSavingStatusChange,
    isConfirmationModalOpen,
    confirmationModalMessage,
    handleApproveClick,
    handleConfirmationModalActionClick,
    handleEditClick,
    handleMenuAction,
    updateEvent,
  } = useEventActions({
    isOpen,
    initialMode,
    onClose,
    onEventUpdated,
    onEventDeleted,
    eventId,
    setCurrentStep,
    isOrganizationUser,
    isOperatorUser,
    defaultOrganizationId,
  });

  /**
   * Fetch the global and organization event tags (used on Step 1)
   * - This will refetch whenever the isOrganizationUser or defaultOrganizationId values change
   */
  const {
    globalEventTags,
    organizationEventTags,
    loading: isLoadingEventTags,
    error: eventTagsError,
  } = useEventTags({
    isOrganization: isOrganizationUser,
    organizationId: defaultOrganizationId,
  });

  /**
   * Fetch all of the operator/organization locations (used on Step 2)
   * - This will refetch whenever the isOpen value changes
   */
  const {
    allLocations,
    loading: isLoadingOperatorAndOrganizationList,
    error: operatorAndOrganizationListError,
  } = useEventOperatorAndOrganizationList({
    isOpen,
    isOrganization: isOrganizationUser,
    isOperator: isOperatorUser,
    defaultOrganizationId,
    defaultOperatorEntityIds,
  });

  const contextValue: EventCreateEditContextValues = useMemo(
    () => ({
      isOpen,
      mode,
      userEmailAddress,
      isOwner: event?.createdByEmail === userEmailAddress,
      userType,
      isOrganizationUser,
      isOperatorUser,
      defaultOrganizationId,
      defaultOperatorEntityIds,
      limitHostToOperatorEntityId,
      eventId,
      lang: i18n.language as MultilingualLangKey,
      locale:
        i18n.language === "fr" ? fr : i18n.language === "es" ? es : undefined,
      event,
      isFetchingEvent,
      fetchEventError,
      isSavingStatusChange,
      isConfirmationModalOpen,
      confirmationModalMessage,
      handleApproveClick,
      handleConfirmationModalActionClick,
      handleEditClick,
      handleMenuAction,
      updateEvent,
      status,
      loading: isLoadingEventTags || isLoadingOperatorAndOrganizationList,
      error: eventTagsError || operatorAndOrganizationListError,
      onClose,
      hideMenuButtons,
      currentStep,
      totalSteps,
      handleNextClick,
      handlePreviousClick,
      globalEventTags,
      organizationEventTags,
      allLocations,
    }),
    [
      isOpen,
      mode,
      userEmailAddress,
      userType,
      isOrganizationUser,
      isOperatorUser,
      defaultOrganizationId,
      defaultOperatorEntityIds,
      limitHostToOperatorEntityId,
      eventId,
      event,
      isFetchingEvent,
      fetchEventError,
      status,
      isSavingStatusChange,
      isConfirmationModalOpen,
      confirmationModalMessage,
      handleApproveClick,
      handleConfirmationModalActionClick,
      handleEditClick,
      handleMenuAction,
      updateEvent,
      isLoadingEventTags,
      eventTagsError,
      onClose,
      hideMenuButtons,
      currentStep,
      totalSteps,
      handleNextClick,
      handlePreviousClick,
      globalEventTags,
      organizationEventTags,
      allLocations,
      isLoadingOperatorAndOrganizationList,
      operatorAndOrganizationListError,
    ],
  );

  return (
    <eventCreateEditContext.Provider value={contextValue}>
      {children}
    </eventCreateEditContext.Provider>
  );
};

export const useEventCreateEditContext = () => {
  const context = useContext(eventCreateEditContext);
  if (!context) {
    throw new Error(
      "useEventCreateEditContext must be used within a EventCreateEditContextProvider",
    );
  }
  return context;
};
