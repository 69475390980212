import { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import {
  ComboboxData,
  ComboboxGroupData,
} from "apps/events/types/ComboboxData";

type EventKeywordsProps = {
  globalEventTags: ComboboxGroupData[];
  organizationEventTags: ComboboxData[];
};

export const useEventKeywords = ({
  globalEventTags,
  organizationEventTags,
}: EventKeywordsProps) => {
  // Save the original filtered tags
  const [filteredGlobalTags, setFilteredGlobalTags] = useState<
    ComboboxGroupData[]
  >([...globalEventTags]);
  const [filteredOrganizationTags, setFilteredOrganizationTags] = useState<
    ComboboxData[]
  >([...organizationEventTags]);

  // Make sure we have all of the tags in the filtered tags initially
  useEffect(() => {
    setFilteredGlobalTags([...globalEventTags]);
    setFilteredOrganizationTags([...organizationEventTags]);
  }, [globalEventTags, organizationEventTags]);

  const { control, setValue } = useFormContext<EventCreateEditFormValues>();
  const selectedGlobalTags = useWatch({
    control,
    name: "tags.global",
  });

  const selectedOrganizationTags = useWatch({
    control,
    name: "tags.organizationSpecific",
  });

  const handleFilterGlobalTags = useCallback(
    (search: string) => {
      const inputValue = (search.toLowerCase().split(",").pop() ?? "").trim();
      const filteredTags = globalEventTags
        .map((group) => ({
          ...group,
          tags: group.tags.filter((tag) =>
            tag.label.toLowerCase().includes(inputValue),
          ),
        }))
        .filter((group) => group.tags.length > 0);
      setFilteredGlobalTags(
        filteredTags.length > 0 ? filteredTags : globalEventTags,
      );
    },
    [globalEventTags],
  );

  const handleFilterOrganizationTags = useCallback(
    (search: string) => {
      const inputValue = (search.toLowerCase().split(",").pop() ?? "").trim();
      const filteredTags = organizationEventTags.filter((tag) =>
        tag.label.toLowerCase().includes(inputValue),
      );
      setFilteredOrganizationTags(
        filteredTags.length > 0 ? filteredTags : organizationEventTags,
      );
    },
    [organizationEventTags],
  );

  const handleComboboxOpenChange = useCallback(() => {
    setFilteredGlobalTags([...globalEventTags]);
    setFilteredOrganizationTags([...organizationEventTags]);
  }, [globalEventTags, organizationEventTags]);

  return {
    filteredGlobalTags,
    filteredOrganizationTags,
    selectedGlobalTags,
    selectedOrganizationTags,
    handleFilterGlobalTags,
    handleFilterOrganizationTags,
    handleComboboxOpenChange,
    setValue,
  };
};
