import { FormEvent, useMemo, useState } from "react";
import {
  UseFormReturn,
  useForm,
  FieldValues,
  FormState,
  Mode,
  DefaultValues,
  UseFormProps,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorResponse, formatErrorResponse } from "../utils";
import { useDeepEffect } from "../hooks";

export type FormHandler<T extends FieldValues> = {
  form: UseFormReturn<T>;
  error?: ErrorResponse;
  onSubmit: (e?: FormEvent) => Promise<void>;
  defaultValues: DefaultValues<T> | undefined;
};

export type FormOptions<T> = Partial<{
  onSuccess: (values: DefaultValues<T> | undefined) => void;
  onError?: (err: Error) => void;
  schema: z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>;
  mode: Mode;
}>;

export function useFormHandler<T extends FieldValues = Record<string, unknown>>(
  handleSubmit: (
    values: DefaultValues<T> | undefined,
    state: FormState<T>,
  ) => void,
  defaultValues: DefaultValues<T> | undefined,
  opt: FormOptions<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is the react-hook-form type
  formOptions?: UseFormProps<T, any>,
): FormHandler<T> {
  const form = useForm<T>({
    defaultValues,
    resolver: opt.schema ? zodResolver(opt.schema) : undefined,
    mode: opt.mode,
    ...formOptions,
  });

  const [error, setError] = useState<ErrorResponse | undefined>();

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      await handleSubmit(values as DefaultValues<T>, form.formState);

      form.clearErrors();
      setError(undefined);

      // Trigger the success handle after the errors have been cleared. This allows for
      // screen navigation to occur after the submit handler has succeeded but after
      // any errors have cleared. In doing so, we can avoid a console error from occurring
      // an complaining about a potential memory leak.
      if (opt.onSuccess) {
        opt.onSuccess(values as DefaultValues<T>);
      }
    } catch (err) {
      console.error("Error updating", err);
      console.error(values);
      setError(formatErrorResponse(err));
      if (opt.onError && err instanceof Error) {
        opt.onError(err);
      }
    }
  });

  useDeepEffect(() => {
    // Reset the form anytime the default values change
    form.reset(defaultValues);
  }, [defaultValues]);

  const returnValues = useMemo(
    () => ({
      onSubmit,
      form,
      error,
      defaultValues,
    }),
    [defaultValues, error, form, onSubmit],
  );

  return returnValues;
}
