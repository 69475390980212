import { useTranslation } from "react-i18next";
import { useSkipDates } from "./useSkipDates";
import { Combobox } from "@ttc3k/trekker";

export const SkipDates = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.RECURRING_EVENT.SKIP_DATES",
  });
  const {
    skipDates,
    filteredSkippableDates,
    handleComboboxOpenChange,
    handleComboboxValueChange,
    handleComboboxInputValueChange,
  } = useSkipDates();

  return (
    <Combobox.Root
      value={skipDates}
      items={filteredSkippableDates}
      multiple
      label={t("TITLE")}
      hint={t("DESCRIPTION")}
      tagVisualVariant={"accent.blue"}
      onOpenChange={handleComboboxOpenChange}
      onValueChange={(d) => handleComboboxValueChange(d.value)}
      onInputValueChange={(d) => handleComboboxInputValueChange(d.inputValue)}
    >
      {filteredSkippableDates.map((date) => (
        <Combobox.Item key={date.value} item={date} {...date} />
      ))}
    </Combobox.Root>
  );
};
