import { useTranslation } from "react-i18next";
import { Flex } from "styled-system/jsx";
import { Button, Text } from "@ttc3k/trekker";
import { SingleDateSelection } from "../../SingleDateSelection/SingleDateSelection";
import { Plus, Trash } from "iconoir-react";
import { useMultiEventSchedule } from "./useMultiEventSchedule";
import { useCallback, useState } from "react";
import { TimeSlots } from "../TimeSlots/TimeSlots";

export const MultiEventSchedule = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.MULTI_EVENT",
  });

  const {
    dates,
    handleDateChange,
    handleAddDate,
    handleRemoveDate,
    addOneToDate,
    subtractOneFromDate,
  } = useMultiEventSchedule();

  // We need to reduce the z-index of the other date selections when one is open
  // to avoid the trigger button from remaining active
  const [reducedZIndex, setReducedZIndex] = useState<{
    isOpen: boolean;
    index: number;
  }>({ isOpen: false, index: -1 });
  const handleDisableDates = useCallback(
    (isOpen: boolean, index: number) => setReducedZIndex({ isOpen, index }),
    [],
  );

  return (
    <Flex flexDir="column" gap="250">
      <Flex flexDir="column" gap="100">
        <Text visual="bodySemiBold">{t("TITLE")}</Text>
        <Text visual="bodyRegular" color="text.mid">
          {t("DESCRIPTION")}
        </Text>
      </Flex>

      {dates.map((date, i) => (
        <Flex
          key={`${i}-${date}`}
          flexDir="column"
          gap="250"
          p="250"
          border="1px solid"
          borderColor="border.light"
          borderRadius="100"
        >
          <Flex flexDir="column" gap="50">
            <Text visual="smallMedium" color="text.mid">
              {`${t("DATE")} ${i + 1}`}
            </Text>
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              gap="150"
            >
              <SingleDateSelection
                index={i}
                value={date}
                onValueChange={(value) => handleDateChange(value, i)}
                onOpenChange={handleDisableDates}
                reduceZIndex={reducedZIndex.isOpen && reducedZIndex.index !== i}
                min={i > 0 ? addOneToDate(dates[i - 1]) : undefined}
                max={
                  i < dates.length - 1
                    ? subtractOneFromDate(dates[i + 1])
                    : undefined
                }
              />
              <Button
                visual="primary"
                mode="light"
                Icon={Trash}
                disabled={i === 0}
                onClick={() => handleRemoveDate(i)}
                type="button"
              />
            </Flex>
          </Flex>
          <TimeSlots scheduleIndex={i} allowMultipleTimeSlots={false} />
        </Flex>
      ))}

      <Button
        visual="unstyled"
        color={{
          base: "text.accent.blue.mid",
          hover: "text.accent.blue.dark",
          focusVisible: "text.accent.blue.dark",
        }}
        Icon={Plus}
        pl={0}
        width="fit-content"
        alignItems="center"
        fontSize="16px"
        rowGap="50"
        columnGap="50"
        gap="50"
        borderRadius="25"
        onClick={handleAddDate}
        type="button"
      >
        {t("ADD_DATE")}
      </Button>
    </Flex>
  );
};
