import { Button, Combobox, LinkButton, Text } from "@ttc3k/trekker";
import {
  Collapsible,
  ControlledComboboxRoot,
  ControlledInput,
  MapboxMap,
} from "shared/components";
import { Flex } from "styled-system/jsx";
import { MarkerDragEvent } from "react-map-gl";
import { useTranslation } from "react-i18next";
import {
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { GeocodingFeature } from "@mapbox/search-js-core";
import { defaultCoordinatesCA, useGeocoding } from "shared/hooks";
import { useState } from "react";
import { getAddressString } from "shared/utils";

export interface ControlledAddressAutofillProps<T extends FieldValues> {
  addressLabel?: string;
  addressInputName: Path<T>;
  displayAddressOnly?: boolean;
  addressObjInputName: Path<T>;
  coordsInputName: Path<T>;
  lngInputName: Path<T>;
  latInputName: Path<T>;
}

export const ControlledAddressAutofill = <T extends FieldValues>({
  addressLabel,
  addressInputName,
  displayAddressOnly = false,
  addressObjInputName,
  coordsInputName,
  lngInputName,
  latInputName,
}: ControlledAddressAutofillProps<T>) => {
  const [isManualAddressOpen, setIsManualAddressOpen] = useState(false);
  const { t } = useTranslation("core", { keyPrefix: "SELECT_ADDRESS" });
  const {
    control,
    setValue,
    trigger,
    getFieldState,
    formState: { errors },
  } = useFormContext<T>();
  const fullAddress = useWatch({ control, name: addressInputName });
  const coordinates = useWatch({ control, name: coordsInputName });
  const addressObj = useWatch({ control, name: addressObjInputName });

  const handleAddressSelect = (foundSuggestion: GeocodingFeature) => {
    setValue(
      addressObjInputName,
      {
        line1: foundSuggestion.properties.name_preferred,
        line2: "",
        city: foundSuggestion.properties.context.place?.name ?? "",
        subdivision: foundSuggestion.properties.context.region?.name ?? "",
        postalCode: foundSuggestion.properties.context.postcode?.name ?? "",
        country: foundSuggestion.properties.context.country?.name ?? "",
        location: {
          type: foundSuggestion.geometry.type,
          coordinates: {
            lng: foundSuggestion.geometry.coordinates[0],
            lat: foundSuggestion.geometry.coordinates[1],
          },
        },
      } as PathValue<T, Path<T>>,
      { shouldDirty: true },
    );
  };

  const handleCoordinateChange = (e: MarkerDragEvent) =>
    setValue(
      coordsInputName,
      {
        lng: e.lngLat.lng,
        lat: e.lngLat.lat,
      } as PathValue<T, Path<T>>,
      { shouldDirty: true },
    );

  const {
    suggestionsMappedToCombobox,
    mapRef,
    marker,
    isMarkerPlaced,
    handleMarkerDragEnd,
    handleSelectAddress,
    handleFlyToAddress,
    getMapCoordinates,
    setMarker,
  } = useGeocoding(
    fullAddress,
    coordinates,
    handleAddressSelect,
    handleCoordinateChange,
  );

  const showConfirmLocation =
    (getFieldState(lngInputName).isDirty ||
      getFieldState(latInputName).isDirty) &&
    !(marker.lng === coordinates.lng && marker.lat === coordinates.lat);

  const handleResetInput = (fieldName: Path<T>) => {
    trigger(fieldName).then(() => {
      if (getFieldState(fieldName).invalid) {
        setValue(fieldName, 0 as PathValue<T, Path<T>>, {
          shouldDirty: true,
        });
      }
    });
  };

  const handleUpdateManualAddress = () => {
    setValue(
      addressInputName,
      getAddressString(addressObj) as PathValue<T, Path<T>>,
      { shouldDirty: true },
    );
  };

  const addressObjFieldsInError = errors["fullAddressObj"] ?? [];
  const isHiddenAddressFieldsInError = [
    "line1",
    "postalCode",
    "city",
    "country",
  ].some((val) => Object.keys(addressObjFieldsInError).includes(val));

  const handleToggleManualAddress = () => {
    setIsManualAddressOpen((prev) => !prev);

    const isClosingManualAddress = isManualAddressOpen;
    /**
     * Adjust the marker to appear on the map when the manual address fields
     * are opened so the user can select their coordinates. Then reset the
     * marker to  either 0 or the selected coordinates if the user closes
     * the manual address
     */
    const markerPosition = isClosingManualAddress
      ? {
          lat: addressObj.location.coordinates.lat,
          lng: addressObj.location.coordinates.lng,
        }
      : {
          lng: defaultCoordinatesCA[0],
          lat: defaultCoordinatesCA[1],
        };
    setMarker(markerPosition);
  };

  return (
    <Flex flexDir={"column"} gap={"150"}>
      <Flex flexDir={"column"} gap={"25"}>
        <ControlledComboboxRoot
          name={addressInputName}
          items={suggestionsMappedToCombobox}
          onInputValueChange={handleSelectAddress}
          placeholder={"Start typing in your address..."}
          label={addressLabel ?? t("BUSINESS_ADDRESS")}
        >
          <Combobox.ItemGroup>
            {suggestionsMappedToCombobox.length === 0 && (
              <Combobox.ItemGroupLabel>
                {t("AUTOCOMPLETE_HINT")}
              </Combobox.ItemGroupLabel>
            )}
            {suggestionsMappedToCombobox.map((item) => (
              <Combobox.Item key={item.value} item={item} {...item} />
            ))}
          </Combobox.ItemGroup>
        </ControlledComboboxRoot>
        <LinkButton
          width={"max"}
          fontSize={"xs"}
          type={"button"}
          onClick={handleToggleManualAddress}
        >
          {isManualAddressOpen
            ? t("MANUAL_ADDRESS.CLOSE")
            : t("MANUAL_ADDRESS.LABEL")}
        </LinkButton>
      </Flex>

      <Collapsible open={isManualAddressOpen}>
        <Flex flexDir={"column"} gap={"150"}>
          <ControlledInput
            name={`${addressObjInputName}.line1`}
            label={t("MANUAL_ADDRESS.LINE_1")}
            onBlurCapture={handleUpdateManualAddress}
          />
          <ControlledInput
            name={`${addressObjInputName}.line2`}
            label={t("MANUAL_ADDRESS.LINE_2")}
            onBlurCapture={handleUpdateManualAddress}
          />
          <Flex gap={"150"}>
            <ControlledInput
              name={`${addressObjInputName}.city`}
              label={t("MANUAL_ADDRESS.CITY")}
              onBlurCapture={handleUpdateManualAddress}
            />
            <ControlledInput
              name={`${addressObjInputName}.subdivision`}
              label={t("MANUAL_ADDRESS.SUBDIVISION")}
              onBlurCapture={handleUpdateManualAddress}
            />
          </Flex>
          <Flex gap={"150"}>
            <ControlledInput
              name={`${addressObjInputName}.postalCode`}
              label={t("MANUAL_ADDRESS.POSTAL_CODE")}
              onBlurCapture={handleUpdateManualAddress}
            />
            <ControlledInput
              name={`${addressObjInputName}.country`}
              label={t("MANUAL_ADDRESS.COUNTRY")}
              onBlurCapture={handleUpdateManualAddress}
            />
          </Flex>
        </Flex>
      </Collapsible>

      {isHiddenAddressFieldsInError && (
        <Text visual={"smallSemiBold"} color={"text.error.dark"}>
          {t("MANUAL_ADDRESS.MISSING_FIELDS")}
        </Text>
      )}

      {!displayAddressOnly && (
        <>
          <Flex gap={"150"}>
            <ControlledInput
              name={lngInputName}
              label={t("LONGITUDE")}
              placeholder={t("LONGITUDE")}
              registerOptions={{ valueAsNumber: true }}
              onBlurCapture={() => handleResetInput(lngInputName)}
            />
            <ControlledInput
              name={latInputName}
              label={t("LATITUDE")}
              placeholder={t("LATITUDE")}
              registerOptions={{ valueAsNumber: true }}
              onBlurCapture={() => handleResetInput(latInputName)}
            />
          </Flex>
          <Collapsible open={isMarkerPlaced}>
            <Text visual={"bodyRegular"} color={"text.mid"}>
              {t("MAP_HELPER_TEXT")}
            </Text>
          </Collapsible>
          <Collapsible open={showConfirmLocation}>
            <Button
              type={"button"}
              visual={"unstyled"}
              color={"text.accent.blue.mid"}
              onClick={() =>
                handleFlyToAddress([coordinates.lng, coordinates.lat])
              }
            >
              {t("CONFIRM_LOCATION")}
            </Button>
          </Collapsible>
          <MapboxMap
            mapRef={mapRef}
            mapCoordinates={getMapCoordinates()}
            markerLng={marker.lng}
            markerLat={marker.lat}
            markerOnDragEnd={handleMarkerDragEnd}
            markerDraggable
          />
        </>
      )}
    </Flex>
  );
};
