import { MediaImage } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { WhereaboutsModal } from "shared/components/root";
import { useUploadMediaModal } from "./useUploadMediaModal";
import { FileUpload, ProgressBar } from "@ttc3k/trekker";
import { AssetFile, AssetImage } from "gql/generated";
import { Flex } from "styled-system/jsx";
import { Collapsible } from "shared/components/molecules";

type UploadMediaModalProps = {
  accept?: string;
  isOpen: boolean;
  isPublicWidget: boolean;
  onClose: () => void;
  onUploadComplete: (assets: Partial<AssetFile | AssetImage>[]) => void;
  maxFiles?: number;
  maxFileSize?: number;
};

export const UploadMediaModal = ({
  accept = "image/*",
  isOpen,
  isPublicWidget = false,
  onClose,
  onUploadComplete,
  maxFiles = Infinity,
  maxFileSize = 20000000, // 20MB
}: UploadMediaModalProps) => {
  const { t } = useTranslation();

  const {
    filesToUpload,
    isUploading,
    uploadProgress,
    uploadingFileName,
    handleCloseClick,
    handleFileChange,
    handleFileSelectionError,
    handleUploadFiles,
  } = useUploadMediaModal({
    maxFiles,
    isPublicWidget,
    onClose,
    onUploadComplete,
  });

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      title={t("core:IMAGE.UPLOAD.TITLE")}
      HeaderIcon={MediaImage}
      hideCloseTrigger={isUploading}
      onClose={handleCloseClick}
      cancelButtonProps={{
        onClick: handleCloseClick,
        disabled: isUploading,
      }}
      saveButtonCopy={t("core:IMAGE.UPLOAD.CTA_BUTTON")}
      saveButtonProps={{
        visual: "accent.blue",
        onClick: handleUploadFiles,
        isLoading: isUploading,
        disabled: isUploading || filesToUpload.length === 0,
      }}
      size="lg"
      portal={false}
    >
      <Flex flexDir={"column"} gap="100">
        <FileUpload
          buttonLabel={t("core:IMAGE.UPLOAD.BROWSE_FILES")}
          label={t("core:IMAGE.UPLOAD.DRAG_HERE")}
          maxFiles={maxFiles}
          maxFilesCopy={
            maxFiles < Infinity && t("core:IMAGE.UPLOAD.MSG_FILE_MAX")
          }
          maxFileSize={maxFileSize}
          maxFileSizeCopy={
            maxFileSize < Infinity && t("core:IMAGE.UPLOAD.MSG_FILE_SIZE")
          }
          visual="row"
          onFileAccept={handleFileChange}
          borderStyle="dashed"
          accept={accept}
          onFileSelectionError={handleFileSelectionError}
        />
        <Collapsible open={isUploading}>
          <ProgressBar
            label={t("core:IMAGE.UPLOAD.UPLOADING", {
              fileName: uploadingFileName,
            })}
            value={uploadProgress}
            showProgressValueText={true}
            className={{
              marginBottom: "-20px",
            }}
          />
        </Collapsible>
      </Flex>
    </WhereaboutsModal>
  );
};
