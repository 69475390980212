import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useTranslation } from "react-i18next";
import { EventStepHeader } from "../EventStepHeader";
import { Flex } from "styled-system/jsx";
import { ControlledInput, ControlledTextArea } from "shared/components";
import { Radio, RadioGroup, Text } from "@ttc3k/trekker";
import { EnumEventLocationType } from "gql/generated";
import { EventKeywords } from "./EventKeywords/EventKeywords";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export const EventDetails = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_DETAILS",
  });

  const { control, setValue } = useFormContext<EventCreateEditFormValues>();
  const locationType = useWatch({ control: control, name: "locationType" });

  const { userType } = useEventCreateEditContext();

  return (
    <Flex flexDir="column" gap="400">
      <EventStepHeader
        step={1}
        title={t("TITLE")}
        description={t("DESCRIPTION")}
      />

      {/* User email address ... ONLY for PUBLIC users */}
      {userType === "PUBLIC" && (
        <ControlledInput
          name="createdByEmail"
          label={t("LABELS.USER_EMAIL_ADDRESS")}
          hint={t("LABELS.USER_EMAIL_ADDRESS_HINT")}
        />
      )}

      {/* Event Type */}
      <Flex flexDir="column" gap="200">
        <Text visual="bodySemiBold">{t("EVENT_TYPE.TITLE")}</Text>
        <RadioGroup
          value={locationType}
          onValueChange={({ value }: { value: string }) => {
            setValue(
              "locationType",
              value as EventCreateEditFormValues["locationType"],
              // Since this isn't a controlled form item, we need to manually update dirty value as well
              { shouldDirty: true },
            );
          }}
          gap={{
            _vertical: "150",
          }}
        >
          <Radio value={EnumEventLocationType.InPerson}>
            {t("EVENT_TYPE.IN_PERSON")}
            <Text visual="bodyRegular" color="text.light">
              {t("EVENT_TYPE.IN_PERSON_DESCRIPTION")}
            </Text>
          </Radio>

          <Radio value={EnumEventLocationType.Virtual}>
            {t("EVENT_TYPE.VIRTUAL")}
            <Text visual="bodyRegular" color="text.light">
              {t("EVENT_TYPE.VIRTUAL_DESCRIPTION")}
            </Text>
          </Radio>
        </RadioGroup>
      </Flex>

      <Flex flexDir="column" gap="250">
        {/* Event Name */}
        <ControlledInput name="title" label={t("LABELS.EVENT_NAME")} />

        {/* Event Description */}
        <ControlledTextArea
          name="description"
          label={t("LABELS.EVENT_DESCRIPTION")}
          rows={7}
          display="flex"
          flexDir="column"
        />

        {/* Event Pricing details */}
        <ControlledTextArea
          name="price"
          label={t("LABELS.PRICING_DETAILS")}
          hint={t("LABELS.PRICING_DETAILS_HINT")}
          rows={3}
          display="flex"
          flexDir="column"
        />

        {/* Ticket URL */}
        <ControlledInput
          name="ticketUrl"
          label={t("LABELS.TICKET_URL")}
          hint={t("LABELS.TICKET_URL_HINT")}
        />
      </Flex>

      {/* Event Keywords */}
      <EventKeywords />
    </Flex>
  );
};
