import { Icon, Text } from "@ttc3k/trekker";
import { EnumEventType, EventManyQuery } from "gql/generated";
import { Flex, styled } from "styled-system/jsx";
import { format, Locale, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  EVENT_DATE_FORMAT,
  getRecurrencePatternDescription,
} from "shared/utils";
import { Repeat } from "iconoir-react";
import { eventLineRecipe } from "./recipe";

const formatDateString = (
  date: string,
  locale: Locale | undefined,
  firstEvent?: string,
) =>
  firstEvent && firstEvent.substring(0, 4) === date.substring(0, 4)
    ? format(parse(date, EVENT_DATE_FORMAT, new Date()), "E, MMM d", {
        locale,
      })
    : format(parse(date, EVENT_DATE_FORMAT, new Date()), "E, MMM d, yyyy", {
        locale,
      });

const formatTimeString = (
  time: string, // HH:mm (24 hour format)
  locale: Locale | undefined,
) =>
  format(parse(time, "HH:mm", new Date()), "h:mma", {
    locale,
  }).toLowerCase();

type EventLineProps = {
  isFirst: boolean;
  dateString: string;
  locale?: Locale | undefined;
  schedule?: EventManyQuery["eventMany"][0]["schedules"][0];
};

/**
 * EventLine
 * - Returns the event date + time
 * @param param0
 * @returns
 */
const EventLine = ({
  isFirst,
  dateString,
  locale,
  schedule,
}: EventLineProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });
  const classes = eventLineRecipe({ isFirst });

  return (
    <Flex className={classes}>
      <Text part="details__schedule-date" visual="bodyMedium">
        {dateString}
      </Text>
      {schedule && (
        <Flex flexDir="column" gap="50">
          {schedule.allDay ? (
            <Text part="details__schedule-time" visual="bodyRegular">
              {t("WORDS.ALL_DAY")}
            </Text>
          ) : (
            schedule.timeSlots?.map((timeSlot, i) => (
              <Text
                part="details__schedule-time"
                visual="bodyRegular"
                display="flex"
                flexDir="column"
                alignItems="flex-end"
                gap="0"
                borderTop={i > 0 ? "1px solid rgba(0, 0, 0, .1)" : ""}
              >
                <span>
                  {timeSlot?.from && formatTimeString(timeSlot.from, locale)}
                  {timeSlot?.to &&
                    ` - ${formatTimeString(timeSlot.to, locale)}`}
                  {timeSlot?.endsNextDay && (
                    <styled.span color="text.light" fontSize="12px">
                      &nbsp;({t("WORDS.NEXT_DAY")})
                    </styled.span>
                  )}
                </span>
              </Text>
            ))
          )}
        </Flex>
      )}
    </Flex>
  );
};

type EventProps = {
  event: EventManyQuery["eventMany"][0];
  locale: Locale | undefined;
};

/**
 * SingleEvent
 * - Returns the schedule for a single event
 * @param param0
 * @returns
 */
const SingleEvent = ({ event, locale }: EventProps) => {
  return (
    <EventLine
      isFirst={true}
      dateString={formatDateString(event.startDate, locale)}
      locale={locale}
      schedule={event.schedules?.[0]}
    />
  );
};

/**
 * MultiEvent
 * - Returns the schedule for a single multi-day event (such as concerts)
 * @param param0
 * @returns
 */
const MultiEvent = ({ event, locale }: EventProps) => {
  return (
    <Flex flexDir="column" gap="0">
      {event.occurrences?.map((date, i) => (
        <EventLine
          key={date}
          isFirst={i === 0}
          dateString={formatDateString(date as string, locale)}
          locale={locale}
          // If there is no schedule for the given date, use the first schedule
          schedule={event.schedules?.[i] ?? event.schedules?.[0]}
        />
      ))}
    </Flex>
  );
};

/**
 * RecurringEventPatternSummary
 * - Returns the pattern description for a recurring event
 * @param param0
 * @returns
 */
const RecurringEventPatternSummary = ({ event, locale }: EventProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });
  const frequencyDescription = getRecurrencePatternDescription(
    t,
    locale,
    event,
  );

  return (
    <Flex
      part="details__schedule-pattern-summary"
      flexDir="row"
      alignItems={"center"}
      gap="100"
      backgroundColor={"bg.accent.blue.lightest"}
      borderRadius={"100"}
      border={"1px solid"}
      borderColor={"border.accent.blue"}
      px={"200"}
      py={"150"}
    >
      <Icon
        Element={Repeat}
        color={"icon.accent.blue.mid"}
        w="20px"
        h="20px"
        flexShrink="0"
      />

      <Text
        part="details__schedule-pattern"
        visual="bodyMedium"
        color={"text.accent.blue.mid"}
      >
        {frequencyDescription}
      </Text>
    </Flex>
  );
};

/**
 * RecurringEvents are the most complicated
 * - For DAILY events, we can just show Daily or Daily + the weekdays if not all days
 * - For WEEKLY events, we need to show the days of the week and recurrence rule
 * - For MONTHLY events, we need to show either the days of the month OR days of week + weeks and recurrence rule
 * - For YEARLY events, we need to show the day of the year
 * @param param0
 * @returns
 */
const RecurringEvent = ({ event, locale }: EventProps) => {
  const firstEvent = event.occurrences?.[0] ?? event.startDate;

  return (
    <Flex flexDir="column" gap="0">
      {event.occurrences?.map((date, i) => (
        <EventLine
          key={date}
          isFirst={i === 0}
          dateString={formatDateString(date as string, locale, firstEvent)}
          locale={locale}
          schedule={event.schedules?.[0]}
        />
      ))}
    </Flex>
  );
};

/**
 * ScheduleNote
 * - Returns any special scheduling notes for the event
 * @param param0
 * @returns
 */
const ScheduleNote = ({ event }: EventProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });

  const notes = event.schedules
    ?.flatMap((schedule) =>
      schedule?.timeSlots?.map((timeSlot) => timeSlot?.note),
    )
    .filter((note) => !!note);
  if (!notes || notes.length === 0) return null;

  const distinctNotes = [...new Set(notes)];

  return (
    <Text
      part="details__schedule-note"
      visual="smallMedium"
      color="text.mid"
      mt="-12px"
    >
      {t("WORDS.NOTE", { count: distinctNotes.length })}
      {": "}
      {distinctNotes.map((note, i) => (
        <span key={note}>
          {note}
          {i < distinctNotes.length - 1 && ", "}
        </span>
      ))}
    </Text>
  );
};

type EventScheduleProps = {
  event: EventManyQuery["eventMany"][0];
  locale: Locale | undefined;
};

export const EventSchedule = ({ event, locale }: EventScheduleProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });

  return (
    <Flex flexDir="column" gap="300">
      {event.type === EnumEventType.Single ? (
        <>
          <Text part="details__schedule-title" visual="smallLabel">
            {t("LABELS.DATE_AND_TIME")}
          </Text>
          <SingleEvent event={event} locale={locale} />
        </>
      ) : event.type === EnumEventType.Multi ? (
        <>
          <Text part="details__schedule-title" visual="smallLabel">
            {t("LABELS.SCHEDULE")}
          </Text>
          <MultiEvent event={event} locale={locale} />
        </>
      ) : (
        <>
          <Text part="details__schedule-title" visual="smallLabel">
            {t("LABELS.SCHEDULE")}
          </Text>
          <RecurringEventPatternSummary event={event} locale={locale} />
          <Text part="details__schedule-subtitle" visual="smallSemiBold">
            {t("LABELS.UPCOMING_OCCURRENCES")}
          </Text>
          <RecurringEvent event={event} locale={locale} />
        </>
      )}
      <ScheduleNote event={event} locale={locale} />
    </Flex>
  );
};
