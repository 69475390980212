import { useTranslation } from "react-i18next";
import { WhereaboutsModal } from "shared/components/root";
import { useImageCaptionModalForm } from "./useImageCaptionModalForm";
import { MediaImage } from "iconoir-react";
import { ControlledTextArea } from "shared/components/formControl";
import { FormProvider } from "react-hook-form";
import {
  EnumDKeyAssetType,
  type AssetFile,
  type AssetImage,
} from "gql/generated";
import { useToastContext } from "@ttc3k/trekker";

type ImageMediaCaptionModalProps = {
  isOpen: boolean;
  assetId: string;
  assetType: EnumDKeyAssetType.AssetFile | EnumDKeyAssetType.AssetImage;
  caption: string;
  onClose: () => void;
  onCaptionUpdated: (asset: Partial<AssetFile> | Partial<AssetImage>) => void;
};

export const ImageMediaCaptionModal = ({
  isOpen,
  assetId,
  assetType,
  caption,
  onClose,
  onCaptionUpdated,
}: ImageMediaCaptionModalProps) => {
  const { t } = useTranslation();
  const { toastFactory } = useToastContext();

  const { form, onSubmit, defaultValues } = useImageCaptionModalForm({
    assetId,
    assetType,
    defaultCaption: caption,
    onSuccess: (asset: Partial<AssetFile> | Partial<AssetImage>) => {
      toastFactory.create({
        title: t("core:SUCCESS"),
        description: t("core:IMAGE.CAPTION.SAVED"),
      });
      form.reset(defaultValues);
      onCaptionUpdated(asset);
      onClose();
    },
    onError: (error: string) =>
      toastFactory.create({
        title: t("core:ERROR.TITLE"),
        description: error,
      }),
  });

  const formId = "image-caption-form";

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      title={t("core:IMAGE.CAPTION.TITLE")}
      description={t("core:IMAGE.CAPTION.DESCRIPTION")}
      HeaderIcon={MediaImage}
      onClose={onClose}
      cancelButtonProps={{
        onClick: onClose,
      }}
      saveButtonCopy={t("core:IMAGE.CAPTION.CTA_BUTTON")}
      saveButtonProps={{
        visual: "accent.blue",
        form: formId,
        type: "submit",
        isLoading: form.formState.isSubmitting,
        disabled: !form.formState.isDirty,
      }}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formId}>
          <ControlledTextArea
            label={t("core:IMAGE.CAPTION.LABEL")}
            name="caption"
          ></ControlledTextArea>
        </form>
      </FormProvider>
    </WhereaboutsModal>
  );
};
