import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode, useEffect, useState } from "react";
import { type AssetImage } from "gql/generated";
import { Flex, Grid } from "styled-system/jsx";
import { useSortableRecipe } from "./recipe";

type SortableItemProps = {
  children: ReactNode;
  id: string;
};

const SortableItem = ({ children, id }: SortableItemProps) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <Flex
      key={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      flexDir="column"
      alignItems="center"
      justifyContent="flex-end"
      gap="200"
    >
      {children}
    </Flex>
  );
};

type LocalValues = {
  id: string;
} & Partial<AssetImage>;

export type SortableProps = {
  disabled: boolean;
  value: Partial<AssetImage>[];
  onChange?: (value: Partial<AssetImage>[]) => Promise<void>;
  sortableComponent?: (item: Partial<AssetImage>, index: number) => ReactNode;
  selectorID: keyof Partial<AssetImage>;
  size?: "screen" | "modal";
};

export const Sortable = ({
  disabled,
  value = [],
  onChange,
  sortableComponent,
  selectorID = "_id",
  size = "screen",
}: SortableProps) => {
  const classes = useSortableRecipe({ size });

  /**
   * Set the intial localValues array and update when the incoming values change
   * - Note that localValues adds an id property because the sorting methods return
   *   variables with an id property
   */
  const [localValues, setLocalValues] = useState<LocalValues[]>([]);
  useEffect(() => {
    setLocalValues(
      value.map((item) => ({
        ...item,
        id: String(item[selectorID]),
      })),
    );
  }, [selectorID, value, setLocalValues]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id === over?.id) return;
    const oldIndex = localValues.findIndex((item) => item._id === active.id);
    const newIndex = localValues.findIndex((item) => item._id === over?.id);
    const newArray = arrayMove<LocalValues>(localValues, oldIndex, newIndex);
    setLocalValues(newArray);
    onChange?.(newArray);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <SortableContext
        items={localValues}
        strategy={rectSortingStrategy}
        disabled={disabled}
      >
        <Grid className={classes}>
          {localValues.map((item, i) => {
            const { id, ...rest } = item;
            return (
              <SortableItem key={id} id={id}>
                {sortableComponent?.(rest, i)}
              </SortableItem>
            );
          })}
        </Grid>
      </SortableContext>
    </DndContext>
  );
};
