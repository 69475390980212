import { useFormContext, useWatch } from "react-hook-form";
import { EventStepHeader } from "../EventStepHeader";
import { Flex } from "styled-system/jsx";
import { useTranslation } from "react-i18next";
import { SegmentGroup, Text } from "@ttc3k/trekker";
import { EnumEventType } from "gql/generated";
import { MultiEventSchedule } from "./MultiEventSchedule/MultiEventSchedule";
import { RecurringEventSchedule } from "./RecurringEventSchedule/RecurringEventSchedule";
import { SingleEventSchedule } from "./SingleEventSchedule/SingleEventSchedule";
import { useEventSchedule } from "./useEventSchedule";
import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";

const eventTypes = [
  EnumEventType.Single,
  EnumEventType.Multi,
  EnumEventType.Recurring,
];

export const EventSchedule = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE",
  });

  const { handleEventTypeChange } = useEventSchedule();

  const { control } = useFormContext<EventCreateEditFormValues>();
  const selectedEventType = useWatch({ control, name: "type" });

  const getScheduleContent = (eventType: EnumEventType) => {
    switch (eventType) {
      case EnumEventType.Single:
        return <SingleEventSchedule />;
      case EnumEventType.Multi:
        return <MultiEventSchedule />;
      case EnumEventType.Recurring:
        return <RecurringEventSchedule />;
    }
  };

  return (
    <Flex flexDir="column" gap="400">
      <EventStepHeader
        step={4}
        title={t("TITLE")}
        description={t("DESCRIPTION")}
      />
      <Flex flexDir="column" gap="150">
        <Text visual="bodySemiBold">{t("SCHEDULE_TYPE.TITLE")}</Text>
        <SegmentGroup.Root
          segmentStyle="buttons"
          orientation="horizontal"
          value={selectedEventType}
          onValueChange={(value) =>
            handleEventTypeChange(value.value as EnumEventType)
          }
        >
          {eventTypes.map((eventType) => (
            <SegmentGroup.Item key={eventType} value={eventType}>
              {t(`SCHEDULE_TYPE.${eventType}`)}
            </SegmentGroup.Item>
          ))}
        </SegmentGroup.Root>
        <Text visual="smallRegular" color="text.mid">
          {t(`SCHEDULE_TYPE.${selectedEventType}_DESCRIPTION`)}
        </Text>
      </Flex>
      {getScheduleContent(selectedEventType)}
    </Flex>
  );
};
