import { Icon } from "@ttc3k/trekker";
import { Button } from "@ttc3k/trekker";
import { CalendarPopover } from "@ttc3k/trekker";
import { addMonths } from "date-fns";
import { format, parse } from "date-fns";
import { Calendar } from "iconoir-react";
import { useEffect, useMemo, useState } from "react";
import { EVENT_DATE_FORMAT } from "shared/utils";
import { Box } from "styled-system/jsx";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

type SingleDateSelectionProps = {
  value: string | null;
  onValueChange: (value: string) => void;
  onOpenChange?: (isOpen: boolean, index: number) => void;
  min?: string;
  max?: string;
  reduceZIndex?: boolean;
  index?: number;
  onClearDatesClick?: () => void;
  clearDatesLabel?: string;
  selectionMode?: "single" | "range";
};

export const SingleDateSelection = ({
  value,
  onValueChange,
  onOpenChange,
  min,
  max,
  reduceZIndex = false,
  index = 0,
  onClearDatesClick,
  clearDatesLabel,
}: SingleDateSelectionProps) => {
  const { locale } = useEventCreateEditContext();

  const formattedValue = useMemo(
    () =>
      value
        ? format(parse(value, EVENT_DATE_FORMAT, new Date()), "MMMM d, yyyy", {
            locale,
          })
        : "",
    [locale, value],
  );

  const [isOpen, setIsOpen] = useState(false);

  // Set min and max dates if not provided
  // Min date is today (no events in the past)
  min = min ?? format(new Date(), EVENT_DATE_FORMAT);
  // Max date is 14 months from today (no events more than 14 months in the future)
  max = max ?? format(addMonths(new Date(), 14), EVENT_DATE_FORMAT);

  // Let parent components know when the open state changes
  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(isOpen, index);
    }
  }, [index, isOpen, onOpenChange]);

  // Start the calendar in the min month
  const [focusedValue, setFocusedValue] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    setFocusedValue(min);
    setTimeout(() => setFocusedValue(undefined), 100);
  }, [min]);

  return (
    <Box width="full" position="relative">
      {isOpen && (
        <Box
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          top="0"
          zIndex="10"
          onClick={() => setIsOpen(false)}
        />
      )}
      <CalendarPopover
        selectionMode={"single"}
        tabIndex={0}
        value={value ? [value] : []}
        onValueChange={({ valueAsString }: { valueAsString: string[] }) => {
          onValueChange(valueAsString[0]);
          setIsOpen(false);
        }}
        focusedValue={focusedValue}
        min={min}
        max={max}
        onClearDatesClick={onClearDatesClick}
        clearDatesLabel={clearDatesLabel}
        ctaClearDatesProps={
          onClearDatesClick
            ? {
                type: "button",
              }
            : undefined
        }
        Trigger={
          <Button
            visual="primary"
            mode="light"
            onClick={() => setIsOpen((prev) => !prev)}
            type="button"
            width="full"
            borderRadius="100"
            px="150"
            py="100"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span style={{ minHeight: "22px" }}>{formattedValue}</span>
            <Icon
              Element={Calendar}
              color="icon.light"
              width="16px"
              height="16px"
            />
          </Button>
        }
        disablePastDates={false}
        open={isOpen}
        outline="none"
        noPortal
        width="full"
        zIndex={reduceZIndex ? 0 : 11}
      />
    </Box>
  );
};
