import { useGeocodingCore } from "@mapbox/search-js-react";
import { GeocodingFeature } from "@mapbox/search-js-core";
import { useEffect, useState, useCallback, useRef } from "react";
import { ArkCombobox } from "@ttc3k/trekker";
import { LngLatLike } from "mapbox-gl";
import { Position } from "geojson";
import { MapRef, MarkerDragEvent } from "react-map-gl";

export const defaultCoordinatesCA: Position = [-79.38355, 43.647938];

export const useGeocoding = (
  input: string,
  defaultCoordinates?: LngLatLike,
  onAddressSelect?: (foundSuggestion: GeocodingFeature) => void,
  onMarkerDrag?: (e: MarkerDragEvent) => void,
) => {
  /**
   * Boolean to check if `defaultCoordinates` is valid
   * Coordinates are valid if they exist and do not equal
   * the form's default values [0, 0]
   */
  const isDefaultCoordinatesValid =
    defaultCoordinates &&
    "lng" in defaultCoordinates &&
    "lat" in defaultCoordinates &&
    !(defaultCoordinates.lng === 0 && defaultCoordinates.lat === 0);

  // Map ref to bind to `Map` component
  const mapRef = useRef<MapRef | null>(null);

  /**
   *  Marker state for Marker component inside `Map`
   *  If `defaultCoordinates` are valid, this will default
   *  to coordinates that were already selected in the inputs
   *  Otherwise, set to [0, 0]
   */
  const [marker, setMarker] = useState<{ lng: number; lat: number }>({
    lng: isDefaultCoordinatesValid ? defaultCoordinates.lng : 0,
    lat: isDefaultCoordinatesValid ? defaultCoordinates.lat : 0,
  });

  // Boolean to check if Marker is placed on the map
  const isMarkerPlaced = !(marker.lng === 0 && marker.lat === 0);

  // Mapbox Geocoding API suggestions list
  const [suggestions, setSuggestions] = useState<GeocodingFeature[]>([]);

  // Loading state for the Mapbox Geocoding API suggestions call
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);

  // "Cleaned" suggestions as mapped values to pass into the `trekker` Combobox
  const [suggestionsMappedToCombobox, setSuggestionsMappedToCombobox] =
    useState<{ value: string; label: string }[]>([]);

  const geocodingCore = useGeocodingCore({
    accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    /* TODO: default location to current user's IP address
       and use `proximity` option instead of country
     */
    country: "CA,US",
  });

  /**
   * Handler that gets called on input change
   * Call Mapbox Geocoding API through `geocodingCore` hook to retrieve suggestions
   * Set `suggestions`, `suggestionsMappedToCombobox` and `suggestionsLoading`
   * Default to empty array when input is empty
   */
  const handleInputChange = useCallback(async () => {
    if (input?.length > 0) {
      setSuggestionsLoading(true);
      const suggestions = await geocodingCore.forward(input);
      setSuggestions(suggestions.features);
      setSuggestionsMappedToCombobox(
        suggestions.features.map((suggestion) => {
          return {
            value: suggestion.id,
            label: `${suggestion.properties.full_address}`,
          };
        }),
      );
    } else {
      setSuggestions([]);
    }
  }, [geocodingCore, input]);

  /**
   * Handler that gets called within `handleSelectAddress`
   * Fly to selected suggestion's coordinates on the Mapbox Map
   * Also move the Map marker to the suggestion's coordinates
   * @param coordinates
   */
  const handleFlyToAddress = (coordinates: Position) => {
    mapRef.current?.flyTo({
      center: coordinates as LngLatLike,
      duration: 2500,
    });
    setMarker({ lng: coordinates[0], lat: coordinates[1] });
  };

  /**
   * Handler that gets called when a suggestion is selected
   * From the Combobox's selected option, map the found address back to our `suggestions`
   * Extract data from `foundSuggestion` and set our form values to the suggestion
   * Call `handleFlyToAddress` to move map to proper coordinates on map
   * @param e
   */
  const handleSelectAddress = (e: ArkCombobox.InputValueChangeDetails) => {
    const foundAddress = e.inputValue;
    const foundSuggestion = suggestions.find(
      (suggestion) => suggestion.properties.full_address === foundAddress,
    );
    if (foundSuggestion) {
      const coordinates = foundSuggestion.geometry.coordinates;
      onAddressSelect?.(foundSuggestion);
      handleFlyToAddress(coordinates);
    }
  };

  /**
   * Handler that gets called on Map's Marker drag end
   * Sets the Marker to the new coordinates and will run optional `onMarkerDrag` function
   */
  const handleMarkerDragEnd = (e: MarkerDragEvent) => {
    setMarker({
      lng: e.lngLat.lng,
      lat: e.lngLat.lat,
    });
    onMarkerDrag?.(e);
  };

  /**
   * Function to get Map coordinates that are set on the Mapbox Map
   * If coordinates are valid, they will set to the `marker` `lng` and `lat`
   * otherwise, go back to default view
   */
  const getMapCoordinates = () => {
    if (!isDefaultCoordinatesValid) {
      return {
        longitude: defaultCoordinatesCA[0],
        latitude: defaultCoordinatesCA[1],
      };
    } else {
      return {
        longitude: marker.lng,
        latitude: marker.lat,
      };
    }
  };

  /**
   * useEffect to set `suggestionsLoading` to false when call to
   * Mapbox Geocoding API to fetch suggestions is complete
   */
  useEffect(() => {
    handleInputChange().then(() => setSuggestionsLoading(false));
  }, [handleInputChange]);

  return {
    suggestions,
    suggestionsLoading,
    suggestionsMappedToCombobox,
    mapRef,
    marker,
    isMarkerPlaced,
    handleSelectAddress,
    handleMarkerDragEnd,
    handleFlyToAddress,
    getMapCoordinates,
    setMarker,
  };
};
