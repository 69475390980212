import { ModalMode } from "apps/events/types/ModalMode";
import { EnumOwnerType, EventByIdQuery } from "gql/generated";
import { EventCreateEditContextProvider } from "../EventCreateEditContext/EventCreateEditContext";
import { EventCreateEditModalContent } from "./EventCreateEditModalContent";

type EventCreateEditModalProps = {
  isOpen: boolean;
  userEmailAddress: string;
  userType: EnumOwnerType | "PUBLIC";
  initialMode: ModalMode;
  // All users will have this. For OPERATOR users, this will be the first organization in their access list
  defaultOrganizationId: string;
  // Only OPERATOR users will have this. This will be the list of ALL operators they have access to
  defaultOperatorEntityIds: string[];
  eventId?: string;
  onClose: () => void;
  onEventUpdated: (event: EventByIdQuery["eventById"]) => void;
  onEventDeleted: (eventId: string) => void;
  hideMenuButtons?: boolean;
};

export const EventCreateEditModal = ({
  isOpen,
  userEmailAddress,
  userType,
  initialMode,
  defaultOrganizationId,
  defaultOperatorEntityIds,
  eventId,
  onClose,
  onEventUpdated,
  onEventDeleted,
  hideMenuButtons,
}: EventCreateEditModalProps) => {
  return (
    <EventCreateEditContextProvider
      isOpen={isOpen}
      initialMode={initialMode}
      userEmailAddress={userEmailAddress}
      userType={userType}
      defaultOrganizationId={defaultOrganizationId}
      defaultOperatorEntityIds={defaultOperatorEntityIds}
      eventId={eventId}
      onClose={onClose}
      onEventUpdated={onEventUpdated}
      onEventDeleted={onEventDeleted}
      hideMenuButtons={hideMenuButtons}
    >
      <EventCreateEditModalContent />
    </EventCreateEditContextProvider>
  );
};
