import { type AssetImage } from "gql/generated";
import { getMultilingualString } from "shared/utils";
import { cloudinary } from "shared/cloudinary";
import { useCallback, useState } from "react";

const getFileName = (text?: string) => {
  return text?.match(/([^/]+)\.\w+$/)?.[0];
};

type UseImageMediaProps = {
  image: Partial<AssetImage>;
};

export const useImageMedia = ({ image }: UseImageMediaProps) => {
  const cloudinaryImage = cloudinary.image(image._id);
  const imageUrl = cloudinaryImage.toURL();

  const caption = getMultilingualString(image.caption ?? {});

  const [isCaptionOpen, setIsCaptionOpen] = useState(false);
  const handleCaptionClick = () => {
    setIsCaptionOpen((prev) => !prev);
  };

  const handleDownload = useCallback(async () => {
    try {
      if (!imageUrl) return;

      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = getFileName(url) || "download";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error", error);
    }
  }, [imageUrl]);

  return {
    caption,
    isCaptionOpen,
    handleCaptionClick,
    handleDownload,
  };
};
