import {
  useRef,
  useState,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";
import { motion, MotionStyle } from "framer-motion";

type AnimatedHeightProps = PropsWithChildren & {
  style?: MotionStyle | undefined;
};

/**
 * This component animates it's child's height
 * This component would be best wrapped around a component whose height will
 * change & you want it to smoothly scroll
 */
export const AnimatedHeight = ({ children, style }: AnimatedHeightProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | string>(0);

  const resize = useCallback(() => {
    if (containerRef.current) {
      // Reset the height from auto to the current offsetHeight
      // to allow animating back to auto
      setHeight(containerRef.current.offsetHeight);
      setTimeout(() => setHeight("auto"), 0);
    }
  }, []);

  useEffect(() => {
    resize();
  }, [children, resize]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [resize]);

  return (
    <motion.div
      ref={containerRef}
      style={{ overflow: "hidden", ...style }}
      animate={{ height }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};
