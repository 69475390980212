import {
  EventLocationFormValues,
  getEventLocationFormValues,
} from "../EventCreateEditModal/useEventCreateEditForm";

import { Path, PathValue, useWatch } from "react-hook-form";

import { useFormContext } from "react-hook-form";

import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useState } from "react";
import { createMultilingual } from "shared/utils";

export const useInPerson = () => {
  const { control, getValues, register, setValue, trigger } =
    useFormContext<EventCreateEditFormValues>();
  const eventLocation = useWatch({ control, name: "eventLocation" });
  const otherLocations = useWatch({ control, name: "otherLocations" });

  // We register this so deletions from the array are tracked correctly
  register("otherLocations");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [isHost, setIsHost] = useState(true);
  const [index, setIndex] = useState(0);
  const [formFieldRoot, setFormFieldRoot] =
    useState<Path<EventCreateEditFormValues>>("eventLocation");
  const [locationCopy, setLocationCopy] =
    useState<EventLocationFormValues | null>(null);
  const [modalMode, setModalMode] = useState<"add" | "edit">("add");

  /**
   * Handles the click event for adding a new location.
   * - When adding a new location, we need to set an empty copy of the location form values.
   * @param isHost - Whether the new location is the main host location.
   * @param index - The index of the new location.
   */
  const handleAddLocationClick = (isHost: boolean, index: number) => {
    setLocationCopy(
      getEventLocationFormValues({ venue: createMultilingual("") }),
    );
    setModalMode("add");
    setIsHost(isHost);
    setIndex(index);
    setFormFieldRoot(isHost ? "eventLocation" : `otherLocations.${index}`);
    setIsEditModalOpen(true);
  };

  /**
   * Handles the click event for editing a location.
   * @param isHost - Whether the location is the main host location.
   * @param index - The index of the location to edit.
   * @param location - The location to edit.
   * @param formFieldRoot - The path to the location in the form.
   */
  const handleEditLocationClick = (
    isHost: boolean,
    index: number,
    location: EventLocationFormValues,
    formFieldRoot: Path<EventCreateEditFormValues>,
  ) => {
    setLocationCopy(structuredClone(location));
    setModalMode("edit");
    setIsHost(isHost);
    setIndex(index);
    setFormFieldRoot(formFieldRoot);
    setIsEditModalOpen(true);
  };

  /**
   * Handles the click event for deleting a location.
   * - Show a confirmation modal prior to deleting the location.
   * @param index - The index of the location to delete.
   */
  const handleDeleteLocationClick = (index: number) => {
    setIndex(index);
    setIsDeleteConfirmationModalOpen(true);
  };

  /**
   * Handles the action click event for the delete confirmation modal.
   * - If confirmed, delete the location from the otherLocations array.
   * @param isConfirmed - Whether the user confirmed the deletion.
   */
  const handleDeleteConfirmationModalActionClick = (isConfirmed: boolean) => {
    if (isConfirmed) {
      setValue(
        "otherLocations",
        getValues("otherLocations").filter((_, i) => i !== index),
        { shouldDirty: true },
      );
    }
    setIsDeleteConfirmationModalOpen(false);
  };

  /**
   * Handles the close event for the edit modal.
   * - Closing will revert the location model to the state when the modal was opened
   * - In the case of adding a new other location, closing should slice from the otherLocations array
   */
  const handleModalClose = () => {
    if (modalMode === "add" && !isHost) {
      setValue("otherLocations", getValues("otherLocations").slice(0, index), {
        shouldDirty: true,
      });
    } else {
      setValue(
        formFieldRoot,
        locationCopy as PathValue<
          EventCreateEditFormValues,
          Path<EventCreateEditFormValues>
        >,
        { shouldDirty: true },
      );
    }
    setIsEditModalOpen(false);
  };

  /**
   * Handles the done event for the edit modal.
   * - Since the modal is attached to the form, we can just close it and the
   *   values will be updated automatically.
   */
  const handleModalDone = async () => {
    const isValid = await trigger();
    if (isValid) {
      setIsEditModalOpen(false);
    }
  };

  return {
    eventLocation,
    otherLocations,
    isEditModalOpen,
    isDeleteConfirmationModalOpen,
    isHost,
    index,
    formFieldRoot,
    locationCopy,
    handleAddLocationClick,
    handleEditLocationClick,
    handleDeleteLocationClick,
    handleDeleteConfirmationModalActionClick,
    handleModalClose,
    handleModalDone,
  };
};
