import { scale } from "@cloudinary/url-gen/actions/resize";
import { Button, Carousel, Heading, Link, Spinner, Text } from "@ttc3k/trekker";
import {
  AssetImage,
  EventEventLocation,
  EventWithOccurrences,
} from "gql/generated";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloudinaryImage } from "shared/components/atoms/CloudinaryImage";
import { getBlurhashDataUrl } from "shared/utils/getBlurhashDataUrl";
import { getMultilingualString } from "shared/utils/getMultilingualString";
import { isLandscape } from "shared/utils/isLandscape";
import { css } from "styled-system/css";
import { Box, BoxProps, Center, FlexProps } from "styled-system/jsx";
import { Flex } from "styled-system/jsx";
import { EventSchedule } from "widgets/events/components/EventSchedule";
import { WhereaboutsLogo } from "shared/components/atoms";
import _ from "lodash";
import { MultilingualLangKey } from "shared/types";
import { Locale } from "date-fns";
import { EventLocationDetails } from "./EventLocationDetails";
import { StaticMap } from "shared/components";
import { getFormattedEventDetailsLink } from "shared/utils/getFormattedEventDetailsLink";
import { PostHogCustomEvent, usePostHog } from "shared/hooks/usePostHog";
import { useEventsWidgetContext } from "widgets/events/components/EventsContext";

type EventWidgetEventDetailsProps = {
  event: EventWithOccurrences;
  lang: MultilingualLangKey;
  locale: Locale | undefined;
  contentBoxProps?: FlexProps;
  carouselBoxProps?: BoxProps;
  eventDetailsLink?: string;
};

export const EventWidgetEventDetails = ({
  event,
  lang,
  locale,
  contentBoxProps,
  carouselBoxProps,
  eventDetailsLink,
}: EventWidgetEventDetailsProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });
  const { organizationId, widgetId } = useEventsWidgetContext();
  const { trackPostHogEvent } = usePostHog();

  /**
   * When the screen is < 768px in width, the image is full width
   * - In these cases, we need to account for a possible scrollbar (e.g. mobile vs desktop)
   *   and use the scrollWidth of the main flex contianer as the image width to fetch
   *   from Cloudinary
   * - Unfortunately, that main flex container isn't drawn until the operator details are
   *   found, so need to run this code in a timeout
   */
  const [imageWidth, setImageWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  // Recursive function to wait for the UI to draw
  const checkScrollWidth = useCallback(() => {
    if (contentRef.current) {
      setImageWidth(contentRef.current.scrollWidth);
    } else {
      setTimeout(() => checkScrollWidth(), 1);
    }
  }, [setImageWidth]);

  // Start the size checking when the operator changes
  useEffect(() => {
    setImageWidth(0);
    setTimeout(() => checkScrollWidth(), 1);
  }, [checkScrollWidth, event]);

  const description = getMultilingualString(event?.description ?? {});
  const images = _.get(event, "images", []);

  const coordinates: [number, number] = [
    event.eventLocation.contact?.address?.location?.coordinates[0] ?? 0,
    event.eventLocation.contact?.address?.location?.coordinates[1] ?? 0,
  ];
  const isValidCoordinates = coordinates[0] !== 0 && coordinates[1] !== 0;

  const handleEventDetailsLinkClick = () => {
    trackPostHogEvent(PostHogCustomEvent.ExpandedDetailsClick, {
      organizationId: organizationId,
      widgetId: widgetId,
      eventId: event._id,
    });
    window.open(
      getFormattedEventDetailsLink(event, eventDetailsLink!),
      "_blank",
    );
  };

  return (
    <Flex flexDir={"column"} h={"100%"} ref={contentRef} {...contentBoxProps}>
      <Box position="sticky" top={0} h="400px" {...carouselBoxProps}>
        {images.length > 0 && imageWidth > 0 ? (
          <Carousel
            images={images}
            controlClassName={css({ pb: "0" })}
            tagClassName={css({ pb: "0" })}
            render={(image: Partial<AssetImage>) => (
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                px="250"
                py="300"
                h="400px"
                style={{
                  backgroundImage: getBlurhashDataUrl(image.blurhash),
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <CloudinaryImage
                  includeBgBlurHash={false}
                  image={image}
                  imageStyleProps={{
                    maxW: "100%",
                    maxH: "100%",
                    borderRadius: "8px",
                    aspectRatio: "unset",
                  }}
                  resize={
                    window.innerWidth < 768
                      ? scale().height(360)
                      : isLandscape(image)
                        ? scale().width(465)
                        : scale().height(360)
                  }
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </Flex>
            )}
          />
        ) : images.length === 0 ? (
          <CloudinaryImage
            imageStyleProps={{
              h: "400px",
              borderStyle: "none",
              borderColor: "transparent",
              borderRadius: "0",
              aspectRatio: `${imageWidth} : 400`,
              width: "full",
            }}
          />
        ) : (
          <Center h="400px" w="full">
            <Spinner width={"40px"} height={"40px"} />
          </Center>
        )}
      </Box>
      <Flex
        part={"details__main"}
        flexDir={"column"}
        flexGrow={1}
        px={"500"}
        py="600"
        bg={"bg.lightest"}
        zIndex={1}
        gap={"400"}
      >
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="400"
          mb="150"
        >
          <Heading part="details__title" size={"h3"}>
            {getMultilingualString(event.title, lang)}
          </Heading>
          {eventDetailsLink && (
            <Button
              part={"details__btn-see-more"}
              visual="primary"
              flexShrink={0}
              py="9px"
              onClick={handleEventDetailsLinkClick}
            >
              {t("BUTTONS.SEE_MORE")}
            </Button>
          )}
        </Flex>

        {/* Link to Google Maps + Buttons for website / phone / email */}
        {event.eventLocation &&
          getMultilingualString(event.eventLocation?.venue, lang) && (
            <EventLocationDetails
              eventLocation={event.eventLocation}
              lang={lang}
            />
          )}

        {description && (
          <Text
            part="details__description"
            visual={"bodyRegular"}
            color={"text.mid"}
            whiteSpace="pre-wrap"
          >
            {description}
          </Text>
        )}

        {event?.price && (
          <Flex flexDir="column" gap="100">
            <Text part="details__price-title" visual="smallLabel">
              {t("LABELS.PRICE")}
            </Text>
            <Text
              part="details__price"
              visual={"bodyRegular"}
              color={"text.mid"}
              whiteSpace="pre-wrap"
            >
              {event.price}
            </Text>
          </Flex>
        )}

        {/* Ticket URL */}
        {event?.ticketUrl && (
          <Flex flexDir="column" gap="100">
            <Text part="details__ticket-url-title" visual="smallLabel">
              {t("LABELS.TICKETS")}
            </Text>
            <Link
              part="details__ticket-url"
              href={event.ticketUrl as string}
              justifyContent="flex-start"
              target="_blank"
              rel="noopener noreferrer"
              size="sm"
              color={{
                base: "text.mid",
                hover: "text.dark",
                focusVisible: "text.dark",
              }}
              textDecoration="underline"
              wordBreak="break-all"
              wordWrap="break-word"
            >
              {event.ticketUrl}
            </Link>
          </Flex>
        )}

        {/* Schedule */}
        <EventSchedule event={event} locale={locale} />

        {isValidCoordinates && (
          <StaticMap
            longitude={
              event.eventLocation.contact?.address?.location?.coordinates[0] ??
              0
            }
            latitude={
              event.eventLocation.contact?.address?.location?.coordinates[1] ??
              0
            }
            style={{
              minHeight: "190px",
              transition: "height 0.3s ease",
            }}
          />
        )}

        {/* Other locations */}
        {event.otherLocations.length > 0 && (
          <Flex flexDir="column" gap="0">
            <Text
              part="details__title-other-locations"
              visual="smallLabel"
              mb="300"
            >
              {t("LABELS.ADDITIONAL_LOCATIONS")}
            </Text>

            {event.otherLocations.map((location, index) => (
              <EventLocationDetails
                key={`${location?.venue.en}-${index}`}
                eventLocation={location as EventEventLocation}
                lang={lang}
                isOtherLocation={true}
                index={index}
              />
            ))}
          </Flex>
        )}
        <Link
          href={"https://www.tourism.tech/"}
          display={"flex"}
          flexDir="column"
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          gap="75"
          userSelect={"none"}
          rel="noopener noreferrer"
          target="_blank"
          color={"text.dark"}
        >
          <Text visual={"smallTag"} opacity={0.5}>
            {t("WORDS.POWERED_BY")}
          </Text>
          <WhereaboutsLogo width={100} height={"auto"} />
        </Link>
      </Flex>
    </Flex>
  );
};
