import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Flex } from "styled-system/jsx";
import { EventStepHeader } from "../EventStepHeader/EventStepHeader";
import { useTranslation } from "react-i18next";
import { UploadMediaModal } from "shared/components/organisms/UploadMediaModal/UploadMediaModal";
import { AssetImage, EnumEntityReferenceType } from "gql/generated";
import { ImageMedia } from "shared/components/molecules/ImageMedia/ImageMedia";
import { EmptyState, Sortable } from "shared/components";
import { useMedia } from "shared/components/organisms/Media/useMedia";
import _ from "lodash";
import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export const EventPhotos = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_PHOTOS",
  });

  const { event } = useEventCreateEditContext();

  const {
    imagesToKeep,
    isUploadModalOpen,
    originalImagesRef,
    handleUploadClick,
    handleUploadComplete,
    handleImageDeleted,
    handleCaptionUpdated,
    handleSortableChange,
  } = useMedia({
    images: event?.images ?? [],
    setSaveButtonProps: () => {},
    setPrimaryButtonProps: () => {},
    _entityId: event?._id,
    entityType: EnumEntityReferenceType.Event,
  });

  const { setValue, resetField } = useFormContext<EventCreateEditFormValues>();

  // NOTE: We don't actually delete the images from Cloudinary OR the Asset collection anymore,
  // so we only really care about the imagesToKeep array
  // Update the imagesToKeep field when the imagesToKeep array changes
  useEffect(() => {
    const originalImageIds =
      originalImagesRef.current?.map((image) => image._id as string) ?? [];
    const keepImageIds = imagesToKeep.map((image) => image._id as string);
    const isEqual = _.isEqual(keepImageIds, originalImageIds);

    if (!isEqual) {
      setValue("imagesToKeep", keepImageIds, { shouldDirty: true });
    } else {
      resetField("imagesToKeep", { keepDirty: false });
      setValue("imagesToKeep", originalImageIds, { shouldDirty: false });
    }
  }, [imagesToKeep, originalImagesRef, setValue, resetField]);

  const [isSortableDisabled, setIsSortableDisabled] = useState(false);

  return (
    <>
      <Flex flexDir="column" gap="400">
        <EventStepHeader
          step={3}
          title={t("TITLE")}
          description={t("DESCRIPTION")}
          ctaButtonProps={{
            children: t("CTA_BUTTON"),
            onClick: () => handleUploadClick(),
          }}
        />
        {imagesToKeep.length === 0 ? (
          <EmptyState
            ctaButtonProps={{
              children: t("EMPTY_STATE.CTA_BUTTON"),
              type: "button",
              onClick: () => handleUploadClick(),
            }}
          />
        ) : (
          <Sortable
            size="modal"
            disabled={isSortableDisabled}
            value={imagesToKeep}
            selectorID="_id"
            sortableComponent={(image: Partial<AssetImage>, i: number) => (
              <ImageMedia
                image={image}
                onDelete={handleImageDeleted}
                onCaptionUpdated={(image) =>
                  handleCaptionUpdated(image as Partial<AssetImage>)
                }
                showTag={i === 0}
                onModalToggle={setIsSortableDisabled}
              />
            )}
            onChange={handleSortableChange}
          />
        )}
      </Flex>
      <UploadMediaModal
        isOpen={isUploadModalOpen}
        isPublicWidget={true}
        onClose={handleUploadClick}
        onUploadComplete={handleUploadComplete}
      />
    </>
  );
};
