import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { MultilingualLangKey } from "shared/types";
import { WidgetOperatorsWithOverrides } from "widgets/operators/shared/types";
import { OperatorsWidgetDetailsDisplayMode } from "widgets/operators/OperatorsWidget.tsx";
import {
  useWidgetByIdWithEntitiesQuery,
  WidgetByIdWithEntitiesQuery,
} from "gql/generated";
import { getMultilingualString } from "shared/utils";
import _ from "lodash";
import { useI18NContext } from "widgets/shared/I18NContextProvider";
import { ApolloError } from "@apollo/client";

export type OperatorsWidgetContextValues = {
  operators: WidgetOperatorsWithOverrides;
  lang?: MultilingualLangKey;
  operatorDetailsLink?: string;
  operatorDetailsDisplayMode?: OperatorsWidgetDetailsDisplayMode;
  appError: ApolloError | undefined;
  appLoading: boolean;
  tags: WidgetByIdWithEntitiesQuery["widgetById"]["filterableTags"];
  widgetName: string;
  displayWidgetName: boolean;
  gridPageSize: number;
  widgetId: string;
  organizationId: string;
} & PropsWithChildren;

export type OperatorsWidgetContextProps = Pick<
  OperatorsWidgetContextValues,
  | "lang"
  | "operatorDetailsLink"
  | "operatorDetailsDisplayMode"
  | "displayWidgetName"
  | "gridPageSize" 
  | "widgetId"
  | "organizationId"
> &
  PropsWithChildren;

const context = createContext<OperatorsWidgetContextValues | null>(null);

export const OperatorsWidgetContextProvider = ({
  lang = "en",
  operatorDetailsLink,
  operatorDetailsDisplayMode = "slideout",
  children,
  widgetId,
  organizationId,
  displayWidgetName = false,
  gridPageSize = 25,
}: OperatorsWidgetContextProps) => {
  /* State to store operators and tags from the widget's list ref that contains entities */
  const [operators, setOperators] = useState<
    OperatorsWidgetContextValues["operators"]
  >([]);
  const [tags, setTags] = useState<OperatorsWidgetContextValues["tags"]>([]);
  const [widgetName, setWidgetName] = useState("");

  /* Fetch the widget data and `setOperators` on complete to pass into the app */
  const { loading, error } = useWidgetByIdWithEntitiesQuery({
    variables: { id: widgetId },
    onCompleted: (data) => {
      setWidgetName(getMultilingualString(data.widgetById.name, lang));
      setOperators(_.shuffle(data.widgetById.list.entities));
      setTags(data.widgetById.filterableTags);
    },
  });

  /* Loading state for the app which depends on widget fetch and loading languages */
  const { loading: loadingLanguages } = useI18NContext();
  const appLoading = loading || loadingLanguages;

  const contextValue: OperatorsWidgetContextValues = useMemo(
    () => ({
      operatorDetailsDisplayMode,
      operators,
      lang,
      operatorDetailsLink,
      appError: error,
      appLoading,
      tags,
      widgetName,
      displayWidgetName,
      gridPageSize,
      widgetId,
      organizationId,
    }),
    [
      operatorDetailsDisplayMode,
      operators,
      lang,
      operatorDetailsLink,
      error,
      appLoading,
      tags,
      widgetName,
      displayWidgetName,
      gridPageSize,
      widgetId,
      organizationId,
    ],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export function useOperatorsWidgetContext() {
  const operatorsContext = useContext(context);

  if (!operatorsContext) {
    throw new Error(
      "useOperatorWidgetContext must be used within a OperatorsWidgetContextProvider",
    );
  }

  return operatorsContext;
}
