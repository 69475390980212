import { es, fr, Locale } from "date-fns/locale";
import { EventManyQuery } from "gql/generated";
import { i18nLanguage } from "i18n";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { MultilingualLangKey } from "shared/types";
import { EventWidgetSize } from "widgets/events/types";
import {
  EventsWidgetDetailsDisplayMode,
  EventsWidgetListDisplayMode,
} from "widgets/events/EventsWidget";

export type EventsWidgetContextValues = {
  organizationId: string;
  widgetId?: string; // For widgets created from the widget builder in Whereaabouts
  lang: MultilingualLangKey;
  locale: Locale | undefined;
  widgetDisplayMode?: EventsWidgetListDisplayMode;
  hideFilters?: boolean;
  applyFilters?: string[];
  nextNMonths?: number;
  eventDetailsLink?: string;
  eventDetailsDisplayMode?: EventsWidgetDetailsDisplayMode;
  moreEventsLink?: string;
  hideCarouselScrollbar?: boolean;
  widgetSize: EventWidgetSize;
  widgetHeaderSize: EventWidgetSize;
  selectedEvent: EventManyQuery["eventMany"][0] | null;
  setSelectedEvent: Dispatch<
    SetStateAction<EventManyQuery["eventMany"][0] | null>
  >;
};

export type EventsWidgetContextProps = Omit<
  EventsWidgetContextValues,
  "locale" | "selectedEvent" | "setSelectedEvent"
> &
  PropsWithChildren;

const context = createContext<EventsWidgetContextValues | null>(null);

export const EventsWidgetContextProvider = ({
  organizationId,
  lang,
  widgetDisplayMode = "grid",
  hideFilters = false,
  applyFilters = [],
  nextNMonths = 0,
  eventDetailsLink,
  eventDetailsDisplayMode = "link",
  moreEventsLink,
  hideCarouselScrollbar = false,
  widgetSize,
  widgetHeaderSize,
  children,
}: EventsWidgetContextProps) => {
  // Store the selected Event
  const [selectedEvent, setSelectedEvent] = useState<
    EventManyQuery["eventMany"][0] | null
  >(null);

  // Store the datefns locale
  const locale =
    lang === i18nLanguage.fr ? fr : lang === i18nLanguage.es ? es : undefined;

  const contextValue: EventsWidgetContextValues = useMemo(
    () => ({
      organizationId,
      lang,
      locale,
      widgetDisplayMode,
      hideFilters,
      applyFilters,
      nextNMonths,
      eventDetailsLink,
      eventDetailsDisplayMode,
      moreEventsLink,
      hideCarouselScrollbar,
      widgetSize,
      widgetHeaderSize,
      selectedEvent,
      setSelectedEvent,
    }),
    [
      organizationId,
      lang,
      locale,
      widgetDisplayMode,
      applyFilters,
      nextNMonths,
      hideFilters,
      eventDetailsLink,
      eventDetailsDisplayMode,
      moreEventsLink,
      hideCarouselScrollbar,
      widgetSize,
      widgetHeaderSize,
      selectedEvent,
      setSelectedEvent,
    ],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const useEventsWidgetContext = () => {
  const eventsContext = useContext(context);

  if (!eventsContext) {
    throw new Error(
      "useEventsWidgetContext must be used within a EventsWidgetContextProvider",
    );
  }

  return eventsContext;
};
