import { Input } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";

export type ControlledInputProps = ComponentProps<typeof Input> & {
  name: string;
  registerOptions?: RegisterOptions;
};

export const ControlledInput = ({
  name,
  hint,
  registerOptions,
  ...rest
}: ControlledInputProps) => {
  const { register, control } = useFormContext();
  const { fieldState } = useController({ name, control });

  const props = {
    ...rest,
    bg: "white",
    ...register(name, registerOptions),
    hint: fieldState.error?.message ?? hint,
    ...(fieldState.error ? { "data-invalid": true, "aria-invalid": true } : {}),
  };

  return <Input {...props} />;
};
