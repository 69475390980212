import { Address } from "gql/generated";
import _ from "lodash";

function createGoogleMapsUrl(address: Address): string {
  if (address.location && address.location.coordinates.length === 2) {
    const [longitude, latitude] = address.location.coordinates;
    if (longitude !== null && latitude !== null) {
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    }
  }

  const addressParts: string[] = _.compact([
    address.line1 && encodeURIComponent(address.line1),
    address.line2 && encodeURIComponent(address.line2),
    address.city && encodeURIComponent(address.city),
    address.subdivision && encodeURIComponent(address.subdivision),
    address.postalCode && encodeURIComponent(address.postalCode),
    address.country && encodeURIComponent(address.country),
  ]);

  const addressString = addressParts.join(",");
  return `https://www.google.com/maps?q=${addressString}`;
}

export { createGoogleMapsUrl };
