import { Box, Flex, Grid } from "styled-system/jsx";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, ButtonProps, Heading, Modal } from "@ttc3k/trekker";
import { Xmark } from "iconoir-react";
import { useWindowResize } from "shared/hooks";

type FlyupEditingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  cancelButtonProps?: ButtonProps;
  saveButtonProps?: ButtonProps;
  headerOverride?: React.ReactNode;
  footerOverride?: React.ReactNode;
} & PropsWithChildren;

export const FlyupEditingModal = ({
  isOpen,
  onClose,
  title,
  cancelButtonProps,
  saveButtonProps,
  children,
  headerOverride,
  footerOverride,
}: FlyupEditingModalProps) => {
  const { width: windowWidth } = useWindowResize();
  const [width, setWidth] = useState<string>("0px");

  const gridRef = useRef<HTMLDivElement>(null);

  /**
   * This function waits for the grid element to exist and then finds the correct width for the modal.
   * This is necessary because the modal is being opened from another modal and the width needs to be
   * set based on the scroll container of the parent modal.
   */
  const findCorrectWidth = useCallback(() => {
    if (!gridRef.current) {
      setTimeout(() => findCorrectWidth(), 100);
      return;
    }

    if (windowWidth < 768) {
      setWidth("100%");
    } else {
      // getRootNode() will return either the root of the element's shadow DOM
      // or the document (if the element is not in the shadow DOM)
      const root = gridRef.current.getRootNode() as ShadowRoot | Document;
      // Since this modal is being opened from another modal, the first #trekker-scroll-container
      // will be the scroll container of the parent modal. We use the offsetWidth of that element
      // to set the width of this modal.
      const scrollContainer = root?.querySelector(
        "#trekker-scroll-container",
      ) as HTMLElement;
      setWidth(
        scrollContainer?.offsetWidth
          ? scrollContainer.offsetWidth + "px"
          : "100%",
      );
    }
  }, [gridRef, windowWidth]);

  // Start waiting for the grid element to exist to find the correct width
  useEffect(() => {
    if (isOpen) {
      findCorrectWidth();
    }
  }, [isOpen, windowWidth, findCorrectWidth]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      hideCloseTrigger={true}
      placement="fixed"
      dialogContentPositioningProps={{
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
        bottom: 0,
        right: 0,
        width,
        height: "85vh",
      }}
    >
      <Grid
        ref={gridRef}
        bg="white"
        gridTemplateRows="auto 1fr auto"
        gridRowGap="0"
        gridGap="0"
        height="full"
      >
        <Flex flexDir="column" gap="250" px="500" pt="600">
          {headerOverride || (
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              gap="200"
              pb="250"
              borderBottom="1px solid"
              borderColor="border.light"
            >
              <Heading size="h4">{title}</Heading>
              <Button
                onClick={onClose}
                Icon={Xmark}
                visual="unstyled"
                color={{
                  base: "icon.light",
                  hover: "icon.mid",
                  focusVisible: "icon.mid",
                }}
                type="button"
              />
            </Flex>
          )}
        </Flex>

        <Box overflowY="auto" px="500" pb="600" pt="400">
          {children}
        </Box>

        {footerOverride || (
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            gap="200"
            px="500"
            py="300"
            borderTop="1px solid"
            borderColor="border.light"
            boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.10)"
          >
            <Button {...cancelButtonProps} type="button" />
            <Button {...saveButtonProps} type="button" />
          </Flex>
        )}
      </Grid>
    </Modal>
  );
};
