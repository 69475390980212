import { Button, Tag, Text } from "@ttc3k/trekker";
import { EditPencil, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Flex } from "styled-system/jsx";

type EventLocationCardProps = {
  isMain: boolean;
  title: string;
  address: string;
  onEditClick: () => void;
  onDeleteClick?: () => void;
};

export const EventLocationCard = ({
  isMain,
  title,
  address,
  onEditClick,
  onDeleteClick,
}: EventLocationCardProps) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_HOST.CARD",
  });

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      alignItems="center"
      gap="100"
      borderWidth="1px"
      borderColor="border.light"
      borderRadius="100"
      px="300"
      py="250"
    >
      <Flex flexDir="column" gap="50">
        {isMain && (
          <Tag visual="accent.magenta" mode="light" mb="100">
            {t("MAIN_HOST")}
          </Tag>
        )}
        <Text visual="bodySemiBold">{title}</Text>
        {address && (
          <Text visual="bodyRegular" color="text.light">
            {address}
          </Text>
        )}
      </Flex>
      <Flex flexDir="row" gap="100">
        <Button
          visual="primary"
          mode="light"
          Icon={EditPencil}
          onClick={onEditClick}
          type="button"
          aria-label={t("EDIT")}
        />
        {onDeleteClick && (
          <Button
            visual="primary"
            mode="light"
            Icon={Trash}
            onClick={onDeleteClick}
            type="button"
            aria-label={t("DELETE")}
          />
        )}
      </Flex>
    </Flex>
  );
};
