import { ComboboxData } from "apps/events/types/ComboboxData";
import { ComboboxGroupData } from "apps/events/types/ComboboxData";
import { EnumOwnerType } from "gql/generated";
import { EnumTagEntityTypes, useTagManyLazyQuery } from "gql/generated";
import { EnumTagGroupType, useTagManyQuery } from "gql/generated";
import { useTagGroupManyQuery } from "gql/generated";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getMultilingualString } from "shared/utils";

type UseEventTagsProps = {
  isOrganization: boolean;
  organizationId: string;
};

export const useEventTags = ({
  isOrganization,
  organizationId,
}: UseEventTagsProps) => {
  /* Get all `GLOBAL` tag groups */
  const { data: tagGroupData } = useTagGroupManyQuery({
    variables: {
      filter: {
        type: [EnumTagGroupType.GlobalEvent],
      },
    },
  });

  // Load the global event tags (All users can access these tags)
  const [globalEventTags, setGlobalEventTags] = useState<ComboboxGroupData[]>(
    [],
  );
  const { loading: isLoadingGlobalEventTags, error: globalEventTagsError } =
    useTagManyQuery({
      variables: {
        filter: {
          entityTypes: [EnumTagEntityTypes.Event],
          tagGroup: tagGroupData?.tagGroupMany?.map((tagGroup) => tagGroup._id),
        },
        limit: 10000,
      },
      onCompleted: (data) => {
        setGlobalEventTags(
          data?.tagMany
            ? _(data.tagMany)
                .groupBy("tagGroup.key")
                .map(function (tags, key) {
                  return {
                    group: key,
                    tags: tags.map((tag) => ({
                      value: tag._id,
                      label: getMultilingualString(tag.name),
                    })),
                  };
                })
                .sortBy("group")
                .value()
            : [],
        );
      },
      notifyOnNetworkStatusChange: true,
    });

  // Load organization specific event tags IF the user is an organizatino user
  const [organizationEventTags, setOrganizationEventTags] = useState<
    ComboboxData[]
  >([]);
  const [
    loadOrganizationEventTags,
    {
      loading: isLoadingOrganizationEventTags,
      error: organizationEventTagsError,
    },
  ] = useTagManyLazyQuery();
  useEffect(() => {
    if (isOrganization && organizationId) {
      loadOrganizationEventTags({
        variables: {
          filter: {
            entityTypes: [EnumTagEntityTypes.Event],
            owner: {
              entityId: organizationId,
              type: EnumOwnerType.Organization,
            },
          },
          limit: 10000,
        },
        onCompleted: (data) => {
          setOrganizationEventTags(
            data?.tagMany
              ? _.sortBy(
                  data.tagMany.map((tag) => ({
                    value: tag._id,
                    label: getMultilingualString(tag.name),
                  })),
                  "label",
                )
              : [],
          );
        },
      });
    }
  }, [isOrganization, organizationId, loadOrganizationEventTags]);

  return {
    globalEventTags,
    organizationEventTags,
    loading: isLoadingGlobalEventTags || isLoadingOrganizationEventTags,
    error: globalEventTagsError || organizationEventTagsError,
  };
};
