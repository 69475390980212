import { styled } from "styled-system/jsx";

interface StaticMapProps {
  latitude: number;
  longitude: number;
  zoom?: number;
  width?: number;
  height?: number;
  marker?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const StyledStaticMap = styled("div", {
  base: {
    pointerEvents: "none",
    userSelect: "none",
    width: "full",
    height: "auto",
    borderRadius: "100",
    border: "1px solid token(colors.border.light)",
    overflow: "hidden",
    bg: "bg.lighter",
    minH: "250px",
  },
});

export const StaticMap = ({
  latitude,
  longitude,
  zoom = 14,
  width = 600,
  height = 400,
  marker = true,
  className,
  style,
}: StaticMapProps) => {
  const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
  const mapStyle = import.meta.env.VITE_MAPBOX_STYLESHEET;
  const mapStyleId = mapStyle.startsWith("mapbox://styles/")
    ? mapStyle.replace("mapbox://styles/", "")
    : mapStyle;

  const markerQuery = marker ? `pin-l+8270DB(${longitude},${latitude})/` : "";
  // Mapbox Static Images API only supports a @2x scale factor for requests;
  // we can't use window.devicePixelRatio directly.
  const scaleFactor = window.devicePixelRatio > 1 ? "@2x" : "";

  const staticMapUrl = `https://api.mapbox.com/styles/v1/${mapStyleId}/static/${markerQuery}${longitude},${latitude},${zoom},0/${width}x${height}${scaleFactor}?access_token=${accessToken}`;

  return (
    <StyledStaticMap className={className} style={style}>
      <styled.img
        src={staticMapUrl}
        alt="Static map"
        width="100%"
        height="auto"
      />
    </StyledStaticMap>
  );
};
