import { cva } from "styled-system/css";

export const useSortableRecipe = cva({
  base: {
    gridTemplateColumns: {
      base: "1fr 1fr",
      lg: "1fr 1fr 1fr",
    },
    gridColumnGap: "300",
    gridRowGap: "300",
    gap: "300",
  },
  variants: {
    size: {
      screen: {},
      modal: {
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "200",
        gridRowGap: "200",
        gap: "200",
      },
    },
  },
  defaultVariants: {
    size: "screen",
  },
});
