import { useEffect, useState } from "react";

export const useWindowResize = () => {
  const [width, setWidth] = useState(window?.innerWidth ?? 0);
  const [height, setHeight] = useState(window?.innerHeight ?? 0);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return { width, height };
};
