import { Box, Flex } from "styled-system/jsx";
import { EventStatusIndicator } from "shared/components";
import { Heading } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";
import { EventByIdQuery } from "gql/generated";
import { useMemo } from "react";

type EventModalHeaderProps = {
  event: EventByIdQuery["eventById"];
};

export const EventModalHeader = ({ event }: EventModalHeaderProps) => {
  const { t } = useTranslation("events", { keyPrefix: "APP.MODAL.HEADER" });

  const { mode, status } = useEventCreateEditContext();
  // The status state returned by useEventCreateEditContext does not update when the event is updated
  // from the create/update calls. This is a workaround to get the latest status.
  const eventStatus = useMemo(() => {
    if (event) {
      return event.access.organizations[0]?.status ?? status;
    }
    return status;
  }, [event, status]);

  if (mode === "approve") return null;

  return (
    <Box pb="250" mb="300" borderBottom="1px solid" borderColor="border.light">
      <Flex flexDir={"column"} gap={"150"}>
        <EventStatusIndicator status={eventStatus} />
        <Heading size="h4">{t(`TITLE_${mode.toUpperCase()}`)}</Heading>
      </Flex>
    </Box>
  );
};
