import { format } from "date-fns/format";
import { parseDate } from "./parseDate";
import { differenceInCalendarDays } from "date-fns";
import i18n from "i18n";

export const DATE_PATTERN_STANDARD = "LLL d, yyyy";
export const DATE_MONTH_YEAR = "LLL yyyy";
export const DATE_PATTERN_STANDARD_WEEKDAY = "EEE, LLLL d, yyyy";
export const DATE_PATTERN_TIME = "h:mmaaa";
export const DATE_RANGE = "dateRange";

export function formatDate(
  date: string | Date | undefined,
  pattern = DATE_PATTERN_STANDARD,
) {
  if (!date) return "";
  const dateObj = typeof date === "string" ? parseDate(date) : date;
  if (pattern !== DATE_RANGE) {
    return format(dateObj, pattern, { weekStartsOn: 1 });
  }
  const dayDiff = differenceInCalendarDays(new Date(), dateObj);
  return dayDiff < 1
    ? i18n.t("core:DATE_RANGES.TODAY")
    : dayDiff < 7
      ? i18n.t("core:DATE_RANGES.THIS_WEEK")
      : dayDiff < 30
        ? i18n.t("core:DATE_RANGES.THIS_MONTH")
        : format(date, DATE_PATTERN_STANDARD, { weekStartsOn: 1 });
}
