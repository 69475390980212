
import { Multilingual } from "gql/generated.ts";
import { MultilingualLangKey } from "shared/types";

export const getMultilingualString = (
  multilingual: Multilingual,
  defaultLang?: MultilingualLangKey,
): string => {
  const lang = defaultLang ?? "en";

  return (
    (multilingual[lang] && multilingual[lang].length > 0
      ? multilingual[lang]
      : null) ??
    (multilingual.en && multilingual.en.length > 0 ? multilingual.en : null) ??
    (multilingual.fr && multilingual.fr.length > 0 ? multilingual.fr : null) ??
    (multilingual.es && multilingual.es.length > 0 ? multilingual.es : null) ??
    ""
  );
};
