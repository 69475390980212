import { Box, Center, Flex } from "styled-system/jsx";
import {
  progressIndicatorDotRecipe,
  progressIndicatorLineRecipe,
} from "./recipe";

type ProgressIndicatorLineProps = {
  currentStep: number;
  totalSteps: number;
  dotSize: number;
  dotSpacing: number;
};

const ProgressIndicatorLine = ({
  currentStep,
  totalSteps,
  dotSize,
  dotSpacing,
}: ProgressIndicatorLineProps) => {
  const classes = progressIndicatorLineRecipe({
    color: currentStep === totalSteps ? "complete" : "incomplete",
  });
  const width = dotSize * currentStep + dotSpacing * (currentStep - 1);

  return <Box className={classes} style={{ width: `${width}px` }} />;
};

type ProgressIndicatorDotProps = {
  key: number;
  index: number;
  currentStep: number;
  totalSteps: number;
  dotSize: number;
};

const ProgressIndicatorDot = ({
  index,
  currentStep,
  totalSteps,
  dotSize,
}: ProgressIndicatorDotProps) => {
  const classes = progressIndicatorDotRecipe({
    isPastStep: currentStep - 1 > index,
    isComplete: currentStep === totalSteps,
    isThisStep: currentStep - 1 === index,
  });

  return (
    <Box
      key={index}
      className={classes}
      style={{
        width: `${dotSize}px`,
        height: `${dotSize}px`,
      }}
    />
  );
};

type ProgressIndicatorDotsToLineProps = {
  currentStep: number;
  totalSteps: number;
  dotSize?: number;
  dotSpacing?: number;
};

export const ProgressIndicatorDotsToLine = ({
  currentStep,
  totalSteps,
  dotSize = 8,
  dotSpacing = 4,
}: ProgressIndicatorDotsToLineProps) => {
  return (
    <Center>
      <Flex
        flexDir="row"
        gap={`${dotSpacing}px`}
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <ProgressIndicatorLine
          currentStep={currentStep}
          totalSteps={totalSteps}
          dotSize={dotSize}
          dotSpacing={dotSpacing}
        />
        {Array.from({ length: totalSteps }).map((_, index) => (
          <ProgressIndicatorDot
            key={index}
            index={index}
            currentStep={currentStep}
            totalSteps={totalSteps}
            dotSize={dotSize}
          />
        ))}
      </Flex>
    </Center>
  );
};
