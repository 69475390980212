import { Textarea } from "@ttc3k/trekker";
import { ComponentProps } from "react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";

export type ControlledTextAreaProps = ComponentProps<typeof Textarea> & {
  name: string;
  registerOptions?: RegisterOptions;
};

export const ControlledTextArea = ({
  name,
  hint,
  registerOptions,
  ...rest
}: ControlledTextAreaProps) => {
  const { register, control } = useFormContext();
  const { fieldState } = useController({ name, control });

  const props = {
    ...rest,
    bg: "white",
    ...register(name, registerOptions),
    hint: fieldState.error?.message ?? hint,
    ...(fieldState.error ? { "data-invalid": true, "aria-invalid": true } : {}),
  };

  return <Textarea {...props} />;
};
