import { Event, EventWithOccurrences } from "gql/generated";
import { useTranslation } from "react-i18next";
import { CloudinaryImage, EventWidgetEventDetails } from "shared/components";
import { Center, Flex } from "styled-system/jsx";
import { EventStepHeader } from "../EventStepHeader/EventStepHeader";
import { Card, Text, Spinner } from "@ttc3k/trekker";
import { useEventPreview } from "./useEventPreview";
import { limitFill } from "@cloudinary/url-gen/actions/resize";
import { isLandscape } from "shared/utils";
import { getMultilingualString } from "shared/utils";
import { autoGravity } from "@cloudinary/url-gen/qualifiers/gravity";
import { css } from "styled-system/css";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export const EventPreview = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_PREVIEW",
  });

  const { event, lang, locale, mode, defaultOrganizationId } =
    useEventCreateEditContext();

  const {
    eventWithOccurrences,
    organizations,
    organizationsLoading,
    contentBoxProps,
    carouselBoxProps,
  } = useEventPreview({ defaultOrganizationId, event: event as Event, mode });

  return (
    <Flex flexDir="column" gap="300">
      {mode !== "approve" && (
        <EventStepHeader
          step={5}
          title={t("TITLE")}
          description={t("DESCRIPTION")}
        />
      )}

      {!event ? (
        <Center minH="132px">
          <Spinner width={"20px"} height={"20px"} />
        </Center>
      ) : (
        <>
          {/* List of organizations */}
          {mode !== "approve" && (
            <Flex flexDir="column" gap="200">
              <Text visual="bodyMedium">{t("SUBMIT_TO")}</Text>
              {organizationsLoading ? (
                <Center
                  bg={"white"}
                  borderColor={"border.light"}
                  borderWidth={"2px"}
                  py="300"
                  px="400"
                  borderRadius="100"
                  minH="132px"
                >
                  <Spinner width={"20px"} height={"20px"} />
                </Center>
              ) : !organizationsLoading && organizations.length === 0 ? (
                <Center
                  bg={"white"}
                  borderColor={"border.light"}
                  borderWidth={"2px"}
                  py="300"
                  px="400"
                  borderRadius="100"
                  minH="132px"
                >
                  <Text visual="bodyMedium" color="text.destructive.dark">
                    {t("NO_ORGANIZATIONS_ERROR")}
                  </Text>
                </Center>
              ) : (
                !organizationsLoading &&
                organizations?.map(organization => {
                  const logo = organization.logo;
                  return (
                    <Card.Surface
                      key={organization._id}
                      bg={"white"}
                      borderColor={"border.light"}
                      borderWidth={"2px"}
                      minH="132px"
                    >
                      <Card.Body pl="300" pr="400" py={"300"}>
                        <Card.BodyContent gap={"500"} alignItems={"center"}>
                          <Flex gap={"300"} alignItems={"center"}>
                            {logo && (
                              <CloudinaryImage
                                image={logo}
                                imageStyleProps={{
                                  borderRadius: "100",
                                  objectFit: "scale-down",
                                  aspectRatio: "square",
                                  className: css({ maxW: "80px", h: "80px" }),
                                }}
                                resize={
                                  isLandscape(logo)
                                    ? limitFill()
                                        .height(100)
                                        .gravity(autoGravity())
                                    : limitFill()
                                        .width(100)
                                        .gravity(autoGravity())
                                }
                              />
                            )}
                            <Flex
                              flexDir={"column"}
                              gap={"25"}
                              pl={logo ? "300" : undefined}
                              borderLeft={logo ? "2px" : undefined}
                              borderColor={"border.light"}
                              borderStyle={"solid"}
                            >
                              <Text visual={"bodySemiBold"} color={"text.dark"}>
                                {getMultilingualString(organization.name)}
                              </Text>
                              {organization.contact?.links?.website && (
                                <Text
                                  visual={"smallMedium"}
                                  color={"text.light"}
                                >
                                  {organization.contact?.links?.website}
                                </Text>
                              )}
                            </Flex>
                          </Flex>
                        </Card.BodyContent>
                      </Card.Body>
                    </Card.Surface>
                  );
                })
              )}
            </Flex>
          )}

          {eventWithOccurrences && eventWithOccurrences._id && (
            <Flex flexDir="column" gap="250">
              {mode !== "approve" && (
                <Flex flexDir="column" gap="100">
                  <Text visual="bodySemiBold">{t("PREVIEW.TITLE")}</Text>
                  <Text visual="bodyRegular" color="text.mid">
                    {t("PREVIEW.DESCRIPTION")}
                  </Text>
                </Flex>
              )}
              <EventWidgetEventDetails
                event={eventWithOccurrences as EventWithOccurrences}
                lang={lang}
                locale={locale}
                contentBoxProps={contentBoxProps}
                carouselBoxProps={carouselBoxProps}
                organizationId={defaultOrganizationId}
                viewOnly={true}
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
