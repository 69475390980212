// import i18next from "i18next";
import { Multilingual } from "gql/generated.ts";

export const createMultilingual = (input: string) => {
  const multilingual: Multilingual = { en: "", es: "", fr: "" };
  // const lang = (
  //   i18next.language && i18next.language in multilingual
  //     ? i18next.language
  //     : "en"
  // ) as keyof typeof multilingual;
  const lang = "en" // Currently only using "en" as the lang
  multilingual[lang] = input as "Multilingual"; // Because TypeScript wanted it this way
  return multilingual;
};
