import { cloudinary } from "shared/cloudinary";
import { ResizeSimpleAction } from "@cloudinary/transformation-builder-sdk/actions/resize/ResizeSimpleAction";
import { type AssetImage } from "gql/generated.ts";
import { styled } from "styled-system/jsx";
import { JsxStyleProps } from "styled-system/types";
import { getMultilingualString } from "shared/utils/getMultilingualString.ts";
import { Icon } from "@ttc3k/trekker";
import { MediaImage, MediaImageXmark } from "iconoir-react";
import {
  CSSProperties,
  ForwardRefExoticComponent,
  RefAttributes,
  SVGProps,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, SkeletonProps } from "../Skeleton";
import { Resize } from "@cloudinary/transformation-builder-sdk/actions";
import { Blurhash } from "react-blurhash";
import { ErrorBoundary } from "@sentry/react";
import { sentryErrorsToIgnore } from "shared/constants/sentry";
import { getBlurhashDataUrl } from "shared/utils";

type ImageNotFoundProps = {
  Icon: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & RefAttributes<SVGSVGElement>
  >;
  isErrorIcon: boolean;
  imageStyleProps?: JsxStyleProps & { className?: string };
  alt?: string;
  errorBoundaryProps?: SkeletonProps;
  parts?: CloudinaryImageProps["parts"];
};

/**
 * When an image isn't found or Cloudinary throws an error,
 * we'll fall back to this image placeholder
 * - Use the Skeleton with the MediaImage icon in the center
 */
const ImageNotFound = ({
  Icon: IconElement,
  isErrorIcon,
  imageStyleProps,
  alt,
  errorBoundaryProps,
  parts,
}: ImageNotFoundProps) => {
  const { t } = useTranslation();

  // Remove image hover effects directly assigned
  const strippedStyleProps = imageStyleProps?._hover
    ? { ...imageStyleProps, _hover: {} }
    : imageStyleProps;

  // Remove image hover effects assigned via className
  if (strippedStyleProps?.className) {
    strippedStyleProps.className = strippedStyleProps.className
      .replace(/\bhover:[\w.-]+/g, "")
      .replace(/\s+/g, " ")
      .trim();
  }

  const caption = alt ? alt : t("core:IMAGE.NOT_FOUND");

  return (
    <Skeleton
      width="auto"
      height="full"
      {...strippedStyleProps}
      justifyItems={"center"}
      alignContent={"center"}
      p="0"
      {...errorBoundaryProps}
      title={caption}
      minH="0px"
      part={parts?.container}
    >
      <Icon
        Element={IconElement}
        size="xl"
        alt={caption}
        color={isErrorIcon ? "icon.error.mid" : "icon.dark"}
        part={parts?.image}
      />
    </Skeleton>
  );
};

export interface CloudinaryImageProps {
  // Image is possibly undefined or null as we will display a placeholder image
  // in those cases
  image?: Partial<AssetImage> | null;
  imageStyleProps?: JsxStyleProps & { className?: string };
  resize?: ResizeSimpleAction;
  alt?: string;
  blurhash?: string;
  errorBoundaryProps?: SkeletonProps;
  includeBgBlurHash?: boolean;
  style?: CSSProperties;
  parts?: { image?: string; container?: string };
}

/**
 * This is the "real" component. It needs to be wrapped by the ErrorBoundary,
 * so is this is not exported externally
 * @param param0
 * @returns
 */
const CloudinaryImageInner = ({
  image,
  imageStyleProps,
  resize,
  alt,
  blurhash,
  errorBoundaryProps,
  includeBgBlurHash,
  style,
  parts,
}: CloudinaryImageProps) => {
  const { t } = useTranslation();

  const [imageNotFound, setImageNotFound] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!image || !image._id) {
    throw new Error(sentryErrorsToIgnore.NO_IMAGE);
  }

  const imageBlurhash = image.blurhash ?? blurhash ?? "";
  const cldImage = cloudinary.image(image._id).addTransformation("f_auto");
  /**
   * Take the device's pixel ratio into account
   * e.g. Retina displays have a pixel density of 2,
   *      so we can load in a larger image so that it
   *      looks better when displayed
   */
  const cldResize = resize
    ? resize.addQualifier(`dpr_${window.devicePixelRatio}`)
    : Resize.scale().addQualifier(`dpr_${window.devicePixelRatio}`);
  cldImage.resize(cldResize);

  /**
   * Load the image into memory
   * - While the image is loading, the blurhash will be displayed
   * - If loading fails, display <ImageNotFound />
   */
  const imageUrl = cldImage.toURL();
  useEffect(() => {
    setImageLoaded(false);
    setImageNotFound(false);

    const img = new Image();
    img.onload = () => setImageLoaded(true);
    img.onerror = () => setImageNotFound(true);
    if (imageUrl) {
      img.src = imageUrl; // `${imageUrl}?_=${(new Date()).getTime()}`; // No caching
    }
  }, [imageUrl]);

  // Set the caption ... If both image.caption & alt are empty, <ImageNotFound> will use a default NOT_FOUND caption
  const caption = alt
    ? alt
    : (getMultilingualString(image.caption ?? {}) ??
      t("core:IMAGE.NO_CAPTION"));

  // Convert the blurhash to a dataUrl
  const [blurDataUrl, setBlurDataUrl] = useState("");
  useEffect(() => {
    if (!imageBlurhash || !includeBgBlurHash) return;

    const dataUrl = getBlurhashDataUrl(imageBlurhash);
    if (dataUrl) {
      setBlurDataUrl(dataUrl);
    }
  }, [imageBlurhash, includeBgBlurHash]);

  // When loading with new Image or styled.img, the "onerror" event doesn't trigger the ErrorBoundary
  // when throwing a new Error. So, instead, we call setImageNotFound(false) to show
  // the fallback image state
  if (imageNotFound) {
    return (
      <ImageNotFound
        Icon={MediaImageXmark}
        isErrorIcon={true}
        imageStyleProps={imageStyleProps}
        alt={t("core:IMAGE.NOT_FOUND")}
        errorBoundaryProps={errorBoundaryProps}
      />
    );
  }

  /**
   * Try to determine the size for the blurhash image
   * 1. Check the Cloudinary resize options and if not found
   * 2. Check the imageStyleProps
   */
  let blurWidth =
    cldResize.qualifiers.get("w")?.qualifierValue.values[0] ??
    imageStyleProps?.w ??
    imageStyleProps?.width ??
    imageStyleProps?.maxW ??
    imageStyleProps?.maxWidth ??
    imageStyleProps?.minW ??
    imageStyleProps?.minWidth;

  let blurHeight =
    cldResize.qualifiers.get("h")?.qualifierValue.values[0] ??
    imageStyleProps?.h ??
    imageStyleProps?.height ??
    imageStyleProps?.maxH ??
    imageStyleProps?.maxHeight ??
    imageStyleProps?.minH ??
    imageStyleProps?.minHeight;

  // Make sure the values are strings and end in px or %
  blurWidth = (
    typeof blurWidth === "number" ? blurWidth.toString() : (blurWidth ?? "auto")
  ).replace(/(\d)$/, "$1px");

  blurHeight = (
    typeof blurHeight === "number"
      ? blurHeight.toString()
      : (blurHeight ?? "auto")
  ).replace(/(\d)$/, "$1px");

  style = {
    ...style,
    backgroundImage: blurDataUrl && includeBgBlurHash ? blurDataUrl : undefined,
    backgroundPosition: blurDataUrl && includeBgBlurHash ? "center" : undefined,
    backgroundSize: blurDataUrl && includeBgBlurHash ? "cover" : undefined,
  };

  return (
    <>
      <styled.div
        style={{
          display: imageLoaded ? "none" : "inline",
        }}
        aspectRatio={imageStyleProps!.aspectRatio}
        position="relative"
        overflow="hidden"
        maxWidth={blurWidth}
        maxHeight={blurHeight}
        rounded="100"
        bg={imageLoaded ? "transparent" : "bg.lighter"}
        part={parts?.container}
      >
        {imageBlurhash && imageBlurhash.length > 0 && (
          <Blurhash
            hash={imageBlurhash}
            width={blurWidth}
            height={blurHeight}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        )}
      </styled.div>
      <styled.img
        src={imageUrl}
        alt={caption}
        {...imageStyleProps}
        display={imageLoaded ? "inline" : "none"}
        onError={() => setImageNotFound(true)}
        style={style}
        part={parts?.image}
      />
    </>
  );
};

/**
 * The CloudinaryImage serves several purposes
 * - It loads the image, displaying a blurhash while loading
 * - It takes the user's devicePixelRatio into account when requesting the image size
 * - It replaces the blurhash with the actual image after it loads
 *
 * The CloudinaryImageInner is wrapped in an ErrorBoundary so that the error
 * does flow up to the main ErrorBoundary in RootLayout. If there are errors
 * thrown, they are still caught by Sentry.
 *
 * Sentry now has a mechanism to skip sending certain errors. For example, if null/undefined
 * is passed as the image property, we don't want to send an error to Sentry and instead will
 * just show an image placeholder empty state.
 *
 * Errors that occur loading the image are trapped internally by the onerror handler of Image/img,
 * so to handle those, we set a state in CloudinaryImageInner and show the empty state with a
 * broken image placeholder.
 *
 * NOTE:
 * The default aspectRatio is "square -> 1 / 1" as most images on the site are square or very close
 * to it. If you need a different aspectRatio (e.g. OperatorWidgetCard), you can pass in "auto".
 * - To calcuate image aspect ratios from the Figma images, use https://andrew.hedges.name/experiments/aspect_ratio/
 */
export const CloudinaryImage = ({
  image,
  imageStyleProps,
  resize,
  alt,
  blurhash,
  errorBoundaryProps = {
    visual: "default",
    mode: "lightest",
  },
  includeBgBlurHash = false,
  style,
  parts,
}: CloudinaryImageProps) => {
  const { t } = useTranslation();
  // Add some default styling to avoid stretching
  if (!imageStyleProps) {
    imageStyleProps = {} as JsxStyleProps & { className?: string };
  }
  // If the image aspect ratio is in the className, move it from there into the imageStyleProps
  if (
    imageStyleProps.className &&
    imageStyleProps.className.indexOf("aspect") > -1
  ) {
    const aspectRatio = imageStyleProps.className?.substring(
      imageStyleProps.className.indexOf("aspect") + 7,
      imageStyleProps.className.indexOf(
        " ",
        imageStyleProps.className.indexOf("aspect"),
      ),
    );
    imageStyleProps.aspectRatio = aspectRatio;
    imageStyleProps.className = imageStyleProps.className.replace(
      `aspect_${aspectRatio}`,
      "",
    );
  }

  imageStyleProps.aspectRatio = imageStyleProps.aspectRatio ?? "square";
  imageStyleProps.objectFit = imageStyleProps.objectFit ?? "cover";

  return (
    <ErrorBoundary
      fallback={
        <ImageNotFound
          Icon={MediaImage}
          isErrorIcon={false}
          imageStyleProps={imageStyleProps}
          alt={t("core:IMAGE.NOT_UPLOADED")}
          errorBoundaryProps={errorBoundaryProps}
          parts={parts}
        />
      }
    >
      <CloudinaryImageInner
        image={image}
        imageStyleProps={imageStyleProps}
        resize={resize}
        alt={alt}
        blurhash={blurhash}
        errorBoundaryProps={errorBoundaryProps}
        includeBgBlurHash={includeBgBlurHash}
        style={style}
        parts={parts}
      />
    </ErrorBoundary>
  );
};
