import { Grid, GridItem } from "styled-system/jsx";
import { AnimatedHeight, OperatorWidgetCard } from "shared/components";
import { OperatorWithOverrides } from "gql/generated";
import { WidgetOperatorsWithOverrides } from "widgets/operators/shared/types";
import { Button } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { useOperatorsWidgetContext } from "../OperatorsContext";
import { useDisplayOperators } from "widgets/operators/hooks";
import { useMediaQuery } from "react-responsive";
import { OperatorCarousel } from "../OperatorCarousel";
import { RenderDetailsSidePane } from "shared/components/organisms/RenderDetailsSidePane";

export interface OperatorGridProps {
  filteredOperators: WidgetOperatorsWithOverrides;
  viewOnly: boolean;
}

type GridFooterProps = {
  isEndReached: boolean;
  onLoadMoreClick: () => void;
  isLoading: boolean;
};

const GridFooter = ({
  isEndReached,
  onLoadMoreClick,
  isLoading,
}: GridFooterProps) => {
  const { t } = useTranslation("widgets", { keyPrefix: "OPERATORS_WIDGET" });

  if (isEndReached) return null;
  return (
    <GridItem
      gridColumn={{ base: "1", md: "1 / -1" }}
      w={"full"}
      display={"flex"}
      p={"400"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Button
        mode={"light"}
        size={"lg"}
        w={"max"}
        onClick={onLoadMoreClick}
        isLoading={isLoading}
        part={"widget__btn-load-more"}
      >
        {t("LOAD_MORE")}
      </Button>
    </GridItem>
  );
};

export const OperatorGrid = ({
  filteredOperators,
  viewOnly,
}: OperatorGridProps) => {
  const { lang, operatorDetailsDisplayMode, operatorDetailsLink } =
    useOperatorsWidgetContext();

  const {
    displayedOperators,
    onSeeDetails,
    isLoadingMore,
    onLoadMoreOperators,
    selectedOperatorId,
    onExitSeeDetails,
  } = useDisplayOperators({
    filteredOperators,
  });

  const isSm = useMediaQuery({ maxWidth: "39.9975rem" });

  if (isSm) {
    return <OperatorCarousel filteredOperators={filteredOperators} />;
  }

  return (
    <>
      <AnimatedHeight style={{ width: "100%" }}>
        <Grid
          width={"full"}
          gridTemplateColumns={"repeat(auto-fill, minmax(260px, 1fr))"}
          gridGap={"20px"}
        >
          {displayedOperators.map((operator) => {
            return (
              <OperatorWidgetCard
                key={operator._entityId}
                operator={operator as Partial<OperatorWithOverrides>}
                viewOnly={viewOnly}
                onClick={onSeeDetails}
                lang={lang}
                operatorDetailsDisplayMode={operatorDetailsDisplayMode}
                operatorDetailsLink={operatorDetailsLink}
                orientation="portrait"
              />
            );
          })}
          <GridFooter
            isEndReached={
              filteredOperators.length === displayedOperators.length
            }
            isLoading={isLoadingMore}
            onLoadMoreClick={onLoadMoreOperators}
          />
        </Grid>
      </AnimatedHeight>

      <RenderDetailsSidePane
        selectedOperatorId={selectedOperatorId}
        onExitComplete={onExitSeeDetails}
        sidePaneType="operators"
      />
    </>
  );
};
