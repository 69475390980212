import { EventWithOccurrences } from "gql/generated";
import { getMultilingualString } from "./getMultilingualString";

export const getFormattedEventDetailsLink = (
  event: Partial<EventWithOccurrences>,
  eventDetailsLink: string,
) => {
  const name = getMultilingualString(event.title ?? {});
  const sanitizedName = name
    .trim()
    .normalize("NFD") // Decompose accented characters (e.g. è -> e`)
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-"); // Replace all non-alphanumeric characters with hyphens

  return eventDetailsLink
    .replace("{EVENT_ID}", event._id)
    .replace("{EVENT_NAME}", sanitizedName);
};
