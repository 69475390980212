import {
  EnumEventEventLocationLocationInfoType,
  PublicationStatusType,
} from "gql/generated";
import {
  OperatorManyUseCaseEnum,
  useOperatorsByIdsEventCreationLazyQuery,
} from "gql/generated";
import { useEffect } from "react";
import { useOrganizationByIdLazyQuery } from "gql/generated";
import { useRef } from "react";
import { useOperatorsManyEventCreationLazyQuery } from "gql/generated";
import { getMultilingualString } from "shared/utils";
import _ from "lodash";
import { LocationListData } from "apps/events/types/LocationListData";

type UseEventOperatorAndOrganizationListProps = {
  isOpen: boolean;
  isOrganization: boolean;
  isOperator: boolean;
  defaultOrganizationId: string;
  defaultOperatorEntityIds: string[];
};

export const useEventOperatorAndOrganizationList = ({
  isOpen,
  isOrganization,
  isOperator,
  defaultOrganizationId,
  defaultOperatorEntityIds,
}: UseEventOperatorAndOrganizationListProps) => {
  const allLocations = useRef<LocationListData[]>([]);

  const [getOperators, { loading: isLoadingOperators, error: operatorsError }] =
    useOperatorsManyEventCreationLazyQuery();

  const [
    getOperatorsByIds,
    { loading: isLoadingOperatorsByIds, error: operatorsByIdsError },
  ] = useOperatorsByIdsEventCreationLazyQuery();

  const [
    getOrganization,
    { loading: isLoadingOrganization, error: organizationError },
  ] = useOrganizationByIdLazyQuery();

  /**
   * We need to create a list to be used in the location dropdown
   * - For organization users, we need to get ALL operators + their organization and combine them into a single list
   * - For operator users, we need to get ALL operators they have access to
   * - For public users (NOT DONE YET), we'll get a list of all operators in the organization
   */
  useEffect(() => {
    if (isOpen) {
      if (isOrganization || (!isOrganization && !isOperator)) {
        // For organization or public users, we need to get ALL operators + their organization and combine them into a single list
        getOperators({
          variables: {
            useCase: OperatorManyUseCaseEnum.Internal,
            includeInvitedOrAcceptedStaffCounts: false,
            filter: {
              organizations: [defaultOrganizationId],
            },
            limit: 10000,
          },
          onCompleted: async (data) => {
            if (data.operatorMany) {
              const operators = data.operatorMany
                .filter((operator) => !!operator._entityId)
                .map((operator) => ({
                  label: getMultilingualString(operator.name),
                  value: operator._entityId,
                  data: {
                    locationInfo: {
                      _entityId: operator._entityId,
                      type: EnumEventEventLocationLocationInfoType.Operator,
                    },
                    venue: operator.name,
                    contact: operator.contact,
                    enrollments: operator.enrollments.map(
                      (enrollment) => enrollment?.organization,
                    ),
                  },
                }));

              // We'll ONLY get the organization if we're an organization user
              if (isOrganization) {
                getOrganization({
                  variables: {
                    id: defaultOrganizationId,
                  },
                  onCompleted: (organizationData) => {
                    const organization = organizationData.organizationById;
                    const locations = [...operators];
                    if (organization) {
                      locations.push({
                        label: getMultilingualString(organization.name),
                        value: organization._id,
                        data: {
                          locationInfo: {
                            _entityId: organization._id,
                            type: EnumEventEventLocationLocationInfoType.Organization,
                          },
                          venue: organization.name,
                          contact: organization.contact ?? {},
                          enrollments: organizationData.organizationById?._id
                            ? [organizationData.organizationById._id]
                            : [],
                        },
                      });
                    }

                    allLocations.current = _.sortBy(locations, "label");
                  },
                });
              } else {
                allLocations.current = _.sortBy(operators, "label");
              }
            }
          },
        });
      } else if (isOperator) {
        // For operator users, we need to get ALL operators they have access to
        getOperatorsByIds({
          variables: {
            operatorEntityIds: defaultOperatorEntityIds,
            status: PublicationStatusType.Approved,
          },
          onCompleted: (data) => {
            const operators = data.operatorsByIds;
            const locations = operators
              .filter((operator) => !!operator)
              .map((operator) => ({
                label: getMultilingualString(operator.name),
                value: operator._entityId,
                data: {
                  locationInfo: {
                    _entityId: operator._entityId,
                    type: EnumEventEventLocationLocationInfoType.Operator,
                  },
                  venue: operator.name,
                  contact: operator.contact,
                  enrollments: operator.enrollments.map(
                    (enrollment) => enrollment?.organization,
                  ),
                },
              }));

            allLocations.current = _.sortBy(locations, "label");
          },
        });
      }
    }
  }, [
    isOpen,
    isOperator,
    isOrganization,
    defaultOrganizationId,
    defaultOperatorEntityIds,
    getOperators,
    getOrganization,
    getOperatorsByIds,
  ]);

  return {
    allLocations: allLocations.current,
    loading:
      isLoadingOperators || isLoadingOperatorsByIds || isLoadingOrganization,
    error: operatorsError || operatorsByIdsError || organizationError,
  };
};
