import { useTranslation } from "react-i18next";
import { HostLocationSelection } from "./HostLocationSelection";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export const VirtualEvent = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_HOST.LOCATION_SELECTION",
  });

  const { allLocations, userType } = useEventCreateEditContext();

  const formFieldRoot = "eventLocation";

  return (
    <HostLocationSelection
      isHost={true}
      index={0}
      allowCustom={userType !== "PUBLIC"}
      allLocations={allLocations}
      formFieldRoot={formFieldRoot}
      eventLinkControlledInputProps={{
        name: `${formFieldRoot}.contact.links.website`,
        label: t("EVENT_LINK"),
        hint: t("EVENT_LINK_HINT_VIRTUAL"),
      }}
      emailControlledInputProps={{
        name: `${formFieldRoot}.contact.email`,
        label: t("EMAIL"),
      }}
      phoneControlledInputProps={{
        name: `${formFieldRoot}.contact.phone`,
        label: t("PHONE"),
      }}
    />
  );
};
