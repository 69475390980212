import { useCallback, useEffect, useRef, useState } from "react";
import { PostHogCustomEvent, usePostHog } from "./usePostHog";

const SCROLL_STEP_SIZE_PX = 150;

type UseScrollProps = {
  mode: "horizontal" | "vertical";
  postHogEvent?: {
    leftOrUp: PostHogCustomEvent;
    rightOrDown: PostHogCustomEvent;
  };
};

export const useScroll = ({ mode, postHogEvent }: UseScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // Track the cleanup function to remove all of the scroll listeners
  // We need to do this because the scroll events are set in a useCallback,
  // but the initiator is a useEffect. Since useCallback doesn't return a cleanup
  // function, we need to track the cleanup function and call it in the useEffect's
  // return statement.
  const [cleanUp, setCleanUp] = useState<(() => void) | null>(null);

  const { trackPostHogEvent } = usePostHog();

  const setScrollObserver = useCallback(() => {
    if (scrollRef.current) {
      const element = scrollRef.current;
      const isHorizontal = mode === "horizontal";

      const checkOverflow = () => {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        } = element;

        setIsOverflowing(
          isHorizontal
            ? scrollWidth > clientWidth
            : scrollHeight > clientHeight,
        );
        setAtStart(isHorizontal ? scrollLeft <= 0 : scrollTop <= 0);
        setAtEnd(
          isHorizontal
            ? scrollLeft + clientWidth >= scrollWidth
            : scrollTop + clientHeight >= scrollHeight,
        );
      };

      const onScroll = () => {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        } = element;

        setAtStart(isHorizontal ? scrollLeft <= 0 : scrollTop <= 0);
        setAtEnd(
          isHorizontal
            ? scrollLeft + clientWidth >= scrollWidth
            : scrollTop + clientHeight >= scrollHeight,
        );
      };

      // Add scroll listener
      element.addEventListener("scroll", onScroll);

      // Watch for resize events and DOM changes on the contianer element
      // These are in a setTimeout to account for framer motion animations
      // This is a bit of a hack, but it works ... no animation is > 500ms
      const resizeObserver = new ResizeObserver(() =>
        setTimeout(() => checkOverflow(), 500),
      );
      const mutationObserver = new MutationObserver(() =>
        setTimeout(() => checkOverflow(), 500),
      );

      resizeObserver.observe(scrollRef.current);
      mutationObserver.observe(element, { childList: true });

      // Do an initial check for containers that don't use framer motion
      checkOverflow();

      // Set the cleanup function to remove all of the scroll listeners
      setCleanUp(() => () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
        element.removeEventListener("scroll", onScroll);
      });
    } else {
      setTimeout(() => setScrollObserver(), 100);
    }
  }, [mode, scrollRef]);

  /**
   * This useEffect is used to initiate the setScrollObserver once (when the cleanUp function is null)
   * and to return the cleanup function to remove all of the scroll listeners.
   */
  useEffect(() => {
    if (!cleanUp) {
      setScrollObserver();
    }

    return () => cleanUp?.();
  }, [cleanUp, setScrollObserver]);

  const scrollLeftOrUp = (scrollStepPixels: number = SCROLL_STEP_SIZE_PX) => {
    if (scrollRef.current) {
      if (postHogEvent) {
        trackPostHogEvent(postHogEvent.leftOrUp);
      }
      scrollRef.current.scrollLeft -= scrollStepPixels;
    }
  };

  const scrollRightOrDown = (
    scrollStepPixels: number = SCROLL_STEP_SIZE_PX,
  ) => {
    if (scrollRef.current) {
      if (postHogEvent) {
        trackPostHogEvent(postHogEvent.rightOrDown);
      }
      scrollRef.current.scrollLeft += scrollStepPixels;
    }
  };

  return {
    scrollRef,
    atStart,
    atEnd,
    scrollLeftOrUp,
    scrollRightOrDown,
    isOverflowing,
  };
};
