import { Button } from "@ttc3k/trekker";
import { Dialpad, SendDiagonal } from "iconoir-react";
import { Heading, Link } from "@ttc3k/trekker";
import { EventEventLocation } from "gql/generated";
import { MultilingualLangKey } from "shared/types";
import { createGoogleMapsUrl } from "shared/utils/createGoogleMapsUrl";
import { getAddressString } from "shared/utils/getAddressString";
import { getMultilingualString } from "shared/utils/getMultilingualString";
import { Flex } from "styled-system/jsx";
import { useTranslation } from "react-i18next";
import { SelectWindow } from "iconoir-react";
import { formatPhoneNumber } from "shared/utils/formatPhoneNumber";
import { PostHogCustomEvent, usePostHog } from "shared/hooks/usePostHog";
import { useEventsWidgetContext } from "widgets/events/components/EventsContext";

type EventLocationDetailsProps = {
  eventLocation: EventEventLocation;
  lang: MultilingualLangKey;
  isOtherLocation?: boolean;
  index?: number;
};

export const EventLocationDetails = ({
  eventLocation,
  lang,
  isOtherLocation = false,
  index = 0,
}: EventLocationDetailsProps) => {
  const { t } = useTranslation("events", { keyPrefix: "WIDGET" });
  const { organizationId, widgetId } = useEventsWidgetContext();
  const { trackPostHogEvent } = usePostHog();

  return (
    <Flex
      flexDir="column"
      gap={isOtherLocation ? "50" : "200"}
      py={isOtherLocation ? "150" : "0"}
      borderTop={isOtherLocation && index === 0 ? "1px solid" : "none"}
      borderTopColor={
        isOtherLocation && index === 0 ? "border.mid" : "transparent"
      }
      borderBottom={isOtherLocation ? "1px solid" : "none"}
      borderBottomColor={isOtherLocation ? "border.light" : "transparent"}
    >
      <Flex flexDir="column" gap="50">
        <Heading part="details__venue" size={"h5"}>
          {getMultilingualString(eventLocation.venue, lang)}
        </Heading>
        {eventLocation.contact?.address && (
          <Link
            part="details__venue-link-address"
            justifyContent="flex-start"
            href={createGoogleMapsUrl(eventLocation.contact?.address)}
            target="_blank"
            rel="noopener noreferrer"
            size="sm"
            color={{
              base: "text.mid",
              hover: "text.dark",
              focusVisible: "text.dark",
            }}
            textDecoration="underline"
          >
            {getAddressString(eventLocation.contact?.address)}
          </Link>
        )}
      </Flex>

      {(eventLocation.contact?.links?.website ||
        eventLocation.contact?.phone ||
        eventLocation.contact?.email) && (
        <Flex flexDir="row" gap={isOtherLocation ? "100" : "200"}>
          {eventLocation.contact?.links?.website && (
            <Button
              part={
                isOtherLocation
                  ? "details__btn-contact-other-location"
                  : "details__btn-contact"
              }
              visual={"primary"}
              mode={"light"}
              borderRadius={isOtherLocation ? "full" : "100"}
              size={isOtherLocation ? "sm" : "md"}
              onClick={() => {
                if (eventLocation.contact?.links?.website) {
                  trackPostHogEvent(PostHogCustomEvent.OutboundClick, {
                    organizationId: organizationId,
                    widgetId: widgetId,
                  });
                  window.open(eventLocation.contact?.links.website);
                }
              }}
              Icon={SelectWindow}
              aria-label={t("BUTTONS.WEBSITE")}
            >
              {!isOtherLocation && t("BUTTONS.WEBSITE")}
            </Button>
          )}
          {eventLocation.contact?.phone && (
            <Button
              part={
                isOtherLocation
                  ? "details__btn-contact-other-location"
                  : "details__btn-contact"
              }
              visual={"primary"}
              mode={"light"}
              borderRadius={isOtherLocation ? "full" : "100"}
              size={isOtherLocation ? "sm" : "md"}
              onClick={() =>
                (window.location.href = `tel:${formatPhoneNumber(
                  eventLocation.contact?.phone ?? "",
                )}`)
              }
              Icon={Dialpad}
              aria-label={t("BUTTONS.PHONE")}
            >
              {!isOtherLocation && t("BUTTONS.PHONE")}
            </Button>
          )}
          {eventLocation.contact?.email && (
            <Button
              part={
                isOtherLocation
                  ? "details__btn-contact-other-location"
                  : "details__btn-contact"
              }
              visual={"primary"}
              mode={"light"}
              borderRadius={isOtherLocation ? "full" : "100"}
              size={isOtherLocation ? "sm" : "md"}
              onClick={() =>
                (window.location.href = `mailto:${eventLocation.contact?.email}`)
              }
              Icon={SendDiagonal}
              aria-label={t("BUTTONS.EMAIL")}
            >
              {!isOtherLocation && t("BUTTONS.EMAIL")}
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
};
