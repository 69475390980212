import { ModalProps } from "@ttc3k/trekker";
import { useEventSubmitWidgetContext } from "../EventSubmitContext/EventSubmitContext";
import { EventCreateEditModal } from "shared/components/organisms/EventCreationAndEditing/EventCreateEditModal";

export type DetailsSidePaneProps = ModalProps;

export const DetailsSidePane = () => {
  const { organizationId, isCreateFlowOpen, setIsCreateFlowOpen } =
    useEventSubmitWidgetContext();

  return (
    <EventCreateEditModal
      isOpen={isCreateFlowOpen}
      userEmailAddress={""}
      userType={"PUBLIC"}
      initialMode={"create"}
      defaultOrganizationId={organizationId}
      defaultOperatorEntityIds={[]}
      eventId={undefined}
      onClose={() => setIsCreateFlowOpen(false)}
      onEventUpdated={() => {}}
      onEventDeleted={() => {}}
    />
  );
};
