import {
  Button,
  Heading,
  Icon,
  Modal,
  Text,
  ButtonProps,
} from "@ttc3k/trekker";
import { Box, Divider, Flex, styled } from "styled-system/jsx";
import {
  ForwardRefExoticComponent,
  PropsWithChildren,
  RefAttributes,
  SVGProps,
} from "react";
import { t } from "i18next";

export type WhereaboutsModalProps = PropsWithChildren & {
  isOpen: boolean;
  onClose: () => void;
  hideCloseTrigger?: boolean;
  HeaderIcon?: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, "ref"> & RefAttributes<SVGSVGElement>
  >;
  title?: string;
  description?: string;
  saveButtonProps?: ButtonProps;
  saveButtonCopy?: string;
  cancelButtonProps?: ButtonProps;
  cancelButtonCopy?: string;
  size?: "sm" | "md" | "lg";
  customFooter?: JSX.Element;
  disableBodyPadding?: boolean;
  portal?: boolean;
};

const ModalContent = styled(Box, {
  base: {
    w: "full",
    bg: "bg.wa.light",
    h: "full",
    minH: "300px",
  },
  variants: {
    size: {
      sm: {
        w: {
          base: "full",
          md: "400px",
        },
      },
      md: {
        w: {
          base: "full",
          md: "600px",
        },
      },
      lg: {
        w: {
          base: "full",
          md: "800px",
          lg: "1000px",
        },
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export const WhereaboutsModal = ({
  isOpen,
  onClose,
  hideCloseTrigger,
  HeaderIcon,
  title,
  description,
  saveButtonProps,
  saveButtonCopy,
  cancelButtonProps,
  cancelButtonCopy,
  size = "md",
  customFooter,
  disableBodyPadding,
  children,
  portal = true,
}: WhereaboutsModalProps) => {
  return (
    <Modal
      open={isOpen}
      placement={"centerPane"}
      onOpenChange={onClose}
      hideCloseTrigger={hideCloseTrigger}
      closeTriggerVisual={"subtle"}
      closeTriggerPlacement="whereabouts"
      noMaxH
      portal={portal}
      userSelect="none"
      zIndex={"medium"}
    >
      <ModalContent size={size} userSelect={"none"}>
        {/* Header */}
        <Flex
          bg={"white"}
          p={"500"}
          flexDir={"column"}
          gap={"200"}
          borderBottomWidth={"1px"}
          borderBottomColor={"border.light"}
          borderBottomStyle={"solid"}
        >
          <Flex alignItems={"center"} gap={"150"}>
            {HeaderIcon && <Icon Element={HeaderIcon} />}
            <Heading size={"h5"} color={"text.dark"}>
              {title}
            </Heading>
          </Flex>

          {description && <Text color={"text.mid"}>{description}</Text>}
        </Flex>

        {/* Body */}
        <Box
          padding={disableBodyPadding ? 0 : "500"}
          maxH={"59vh"}
          overflowY={"auto"}
          overflowX={"hidden"}
          id={"whereabouts-modal-content-wrapper"}
        >
          {children}
        </Box>

        {/* Footer */}
        <Divider borderColor={"border.light"} />
        {customFooter ? (
          customFooter
        ) : (
          <Flex
            px={"500"}
            py={"400"}
            flex={1}
            gap={"150"}
            justifyContent={"space-between"}
          >
            <Button
              flex={1}
              size={"lg"}
              type={"button"}
              visual={"primary"}
              mode={"light"}
              {...cancelButtonProps}
            >
              {cancelButtonCopy ?? t("core:CANCEL")}
            </Button>
            <Button flex={1} size={"lg"} {...saveButtonProps}>
              {saveButtonCopy ?? t("core:MODAL_BUTTON_COPY.SAVE")}
            </Button>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};
