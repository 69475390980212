import { useEffect, useState } from "react";

export const useEventSteps = (isOpen: boolean) => {
  // Keep track of the current step (there are 5 steps in total)
  // When the modal is opened/closed, we want to reset the step to 1
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => setCurrentStep(1), [isOpen]);

  const handleNextClick = () =>
    setCurrentStep(Math.min(currentStep + 1, totalSteps));

  const handlePreviousClick = () =>
    setCurrentStep(Math.max(currentStep - 1, 1));

  return {
    currentStep,
    totalSteps,
    handleNextClick,
    handlePreviousClick,
    setCurrentStep,
  };
};
