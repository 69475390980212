import { useToastContext } from "@ttc3k/trekker";
import {
  AssetFile,
  AssetImage,
  FileCreateOneMutationFn,
  FileDeleteFromCloudinaryOnlyMutationFn,
  ImageCreateOneMutationFn,
  ImageDeleteFromCloudinaryOnlyMutationFn,
  useFileCreateOneMutation,
  useFileDeleteFromCloudinaryOnlyMutation,
  useImageCreateOneMutation,
  useImageDeleteFromCloudinaryOnlyMutation,
} from "gql/generated";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCloudinaryUploadSignature } from "shared/hooks/useCloudinaryUploadSignature";
import { UploadApiResponse } from "cloudinary";
import { TFunction } from "i18next";
import { uploadWithProgress } from "shared/utils/uploadWithProgress";

const CLOUDINARY_PRESET =
  import.meta.env.VITE_ENVIRONMENT === "production" ? "prod" : "dev";
const CLOUDINARY_UPLOAD_URL = `https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}/image/upload`;

/**
 * Convert all of the files that need to be uploaded into FormData objects
 * and calculate the total bytes that need to be sent to the server
 * @param filesToUpload
 * @param signature
 * @returns
 */
const generateFormDataAndFindTotalBytes = async (
  filesToUpload: File[],
  signature: {
    timestamp?: number;
    signature?: string;
  },
) => {
  const formDataToUpload: FormData[] = [];
  let totalBytes = 0;
  for (const file of filesToUpload) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", CLOUDINARY_PRESET);
    formData.append(
      "timestamp",
      signature.timestamp?.toString() ??
        Math.round(new Date().getTime() / 1000).toString(),
    );
    formData.append("api_key", import.meta.env.VITE_CLOUDINARY_API_KEY);
    formData.append("signature", signature.signature as string);
    formDataToUpload.push(formData);

    const blob = await new Response(formData).blob();
    totalBytes += blob.size;
  }
  return { formDataToUpload, totalBytes };
};

type UseUploadMediaModalProps = {
  maxFiles: number;
  isPublicWidget: boolean;
  onUploadComplete: (assets: Partial<AssetFile | AssetImage>[]) => void;
  onClose: () => void;
};

export const useUploadMediaModal = ({
  maxFiles,
  isPublicWidget = false,
  onClose,
  onUploadComplete,
}: UseUploadMediaModalProps) => {
  const { t } = useTranslation();
  const { toastFactory } = useToastContext();
  const { getCloudinaryUploadSignature } =
    useCloudinaryUploadSignature(isPublicWidget);

  const handleCloseClick = () => {
    onClose();
  };

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const handleFileChange = ({ files }: { files: File[] }) =>
    setFilesToUpload(files);

  /**
   * Handle uploading and saving of the files
   * - Files are uploaded to Cloudinary, which returns JSON objects for each
   * - JSON objects are used to add new Assets to the Asset collection
   * - These Assets are then add to the end of the selectedImages array
   */
  const [fileCreateOneMutation] = useFileCreateOneMutation();
  const [imageCreateOneMutation] = useImageCreateOneMutation();

  /**
   * Delete resolvers in case the upload to Mongo fails AFTER the upload to cloudinary
   */
  const [fileDeleteFromCloudinaryOnlyMutation] =
    useFileDeleteFromCloudinaryOnlyMutation();
  const [imageDeleteFromCloudinaryOnlyMutation] =
    useImageDeleteFromCloudinaryOnlyMutation();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUploadFiles = async () => {
    setIsUploading(true);
    setUploadProgress(0);

    // Get the cloudinary signing token
    const signature = await getCloudinaryUploadSignature();
    if (!signature || !signature.timestamp) {
      setIsUploading(false);
      toastFactory.create({
        title: t("core:ERROR.TITLE"),
        description: t("core:IMAGE.UPLOAD.ERROR", { fileName: "" }),
      });
      return;
    }
    const createdAssets: Partial<AssetFile | AssetImage>[] = [];

    // Determine the total bytes to upload
    const { formDataToUpload, totalBytes } =
      await generateFormDataAndFindTotalBytes(filesToUpload, signature);

    let totalUploadedBytes = 0;

    for (const formData of formDataToUpload) {
      const file = formData.get("file") as File;
      const fileName = file.name;
      const uploadedBytesAtStartOfFile = totalUploadedBytes;
      setUploadingFileName(fileName);

      // Upload to cloudinary
      // We need to use XHR directly so that we can listen to the progress even
      await uploadWithProgress({
        url: CLOUDINARY_UPLOAD_URL,
        formData,
        onProgress: (bytesUploaded: number) => {
          totalUploadedBytes = uploadedBytesAtStartOfFile + bytesUploaded;
          setUploadProgress(
            Math.min(100, Math.floor((totalUploadedBytes / totalBytes) * 100)),
          );
        },
      })
        .then(async (json) => {
          if (json.public_id) {
            let asset: Partial<AssetFile> | Partial<AssetImage> | null = null;
            if (json.resource_type === "image") {
              asset = await createAssetImage(
                t,
                toastFactory,
                json,
                fileName,
                imageCreateOneMutation,
                imageDeleteFromCloudinaryOnlyMutation,
              );
            } else {
              asset = await createAssetFile(
                t,
                toastFactory,
                json,
                fileName,
                fileCreateOneMutation,
                fileDeleteFromCloudinaryOnlyMutation,
              );
            }

            if (asset) {
              createdAssets.push(asset);
            }
          }
        })
        .catch(() => {
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("core:IMAGE.UPLOAD.ERROR", { fileName }),
          });
        });
    }

    setFilesToUpload([]);
    setIsUploading(false);
    onUploadComplete(createdAssets);
  };

  const handleFileSelectionError = (error: {
    fileName: string;
    errorCode: string;
  }) => {
    const key = `core:IMAGE.UPLOAD.${error.errorCode}`;
    toastFactory.create({
      title: t("core:ERROR.TITLE"),
      description:
        error.errorCode === "FILE_TOO_LARGE"
          ? t(key, { fileName: error.fileName })
          : t(key, { numFiles: maxFiles }),
    });
  };

  return {
    filesToUpload,
    isUploading,
    uploadProgress,
    uploadingFileName,
    handleCloseClick,
    handleFileChange,
    handleFileSelectionError,
    handleUploadFiles,
  };
};

/**
 * Save the metadata from Cloudinary to Mongo
 * - If the save fails, delete the image from Cloudinary
 * @param t
 * @param data
 * @param fileName
 * @param fileCreateOneMutation
 * @param fileDeleteFromCloudinaryOnlyMutation
 * @returns
 */
const createAssetFile = (
  t: TFunction<"translation", undefined>,
  toastFactory: ReturnType<typeof useToastContext>["toastFactory"],
  data: UploadApiResponse,
  fileName: string,
  fileCreateOneMutation: FileCreateOneMutationFn,
  fileDeleteFromCloudinaryOnlyMutation: FileDeleteFromCloudinaryOnlyMutationFn,
): Promise<Partial<AssetFile> | null> =>
  new Promise((resolve) => {
    fileCreateOneMutation({ variables: { data } })
      .then((response) => {
        if (response.data) {
          resolve(response.data?.fileCreateOne as Partial<AssetFile>);
        } else {
          // Attempt to delete the image from Cloudinary
          // We don't need to wait for this response
          fileDeleteFromCloudinaryOnlyMutation({
            variables: {
              cloudinaryPublicId: data.public_id,
              resourceType: data.resource_type,
            },
          });
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("core:IMAGE.UPLOAD.ERROR", { fileName }),
          });
          resolve(null);
        }
      })
      .catch(() => {
        // Attempt to delete the image from Cloudinary
        // We don't need to wait for this response
        fileDeleteFromCloudinaryOnlyMutation({
          variables: {
            cloudinaryPublicId: data.public_id,
            resourceType: data.resource_type,
          },
        });
        toastFactory.create({
          title: t("core:ERROR.TITLE"),
          description: t("core:IMAGE.UPLOAD.ERROR", { fileName }),
        });
        resolve(null);
      });
  });

/**
 * Save the metadata from Cloudinary to Mongo
 * - If the save fails, delete the image from Cloudinary
 * @param t
 * @param data
 * @param fileName
 * @param imageCreateOneMutation
 * @param imageDeleteFromCloudinaryOnlyMutation
 * @returns
 */
const createAssetImage = (
  t: TFunction<"translation", undefined>,
  toastFactory: ReturnType<typeof useToastContext>["toastFactory"],
  data: UploadApiResponse,
  fileName: string,
  imageCreateOneMutation: ImageCreateOneMutationFn,
  imageDeleteFromCloudinaryOnlyMutation: ImageDeleteFromCloudinaryOnlyMutationFn,
): Promise<Partial<AssetImage> | null> =>
  new Promise((resolve) => {
    imageCreateOneMutation({ variables: { data } })
      .then((response) => {
        if (response.data) {
          resolve(response.data?.imageCreateOne as Partial<AssetImage>);
        } else {
          // Attempt to delete the image from Cloudinary
          // We don't need to wait for this response
          imageDeleteFromCloudinaryOnlyMutation({
            variables: {
              cloudinaryPublicId: data.public_id,
              resourceType: data.resource_type,
            },
          });
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("core:IMAGE.UPLOAD.ERROR", { fileName }),
          });
          resolve(null);
        }
      })
      .catch(() => {
        // Attempt to delete the image from Cloudinary
        // We don't need to wait for this response
        imageDeleteFromCloudinaryOnlyMutation({
          variables: {
            cloudinaryPublicId: data.public_id,
            resourceType: data.resource_type,
          },
        });
        toastFactory.create({
          title: t("core:ERROR.TITLE"),
          description: t("core:IMAGE.UPLOAD.ERROR", { fileName }),
        });
        resolve(null);
      });
  });
