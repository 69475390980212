import { RecipeVariantProps, sva } from "styled-system/css";

export const operatorCardRecipe = sva({
  slots: ["surface", "hero", "body", "textContent"],
  base: {
    surface: {
      height: "full",
      w: "full"
    },
    hero: {
      height: "auto",
    },
    textContent: {
      display: "flex",
      flexDir: "column",
      height: "104px",
    },
    body: { gap: "250" },
  },
  variants: {
    orientation: {
      landscape: {
        surface: {
          flexDir: "row",
          minWidth: "560px",
          height: "262px",
        },
        hero: {
          w: "240px",
          height: "262px",
        },
        body: { w: "320px", flexGrow: "initial" },
        textContent: { gap: "150" },
      },
      portrait: {
        surface: {
          minW: "260px",
          maxW: "400px",
          maxH: "468px",
        },
        hero: {
          minW: "260px",
          maxW: "full",
          maxH: "200px",
        },
        textContent: { gap: "50" },
      },
    },
  },
  defaultVariants: {
    orientation: "portrait",
  },
});

export type OperatorCardRecipeProps = RecipeVariantProps<
  typeof operatorCardRecipe
>;
