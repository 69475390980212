import { Text } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { Flex } from "styled-system/jsx";
import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { SingleDateSelection } from "../../SingleDateSelection/SingleDateSelection";
import { TimeSlots } from "../TimeSlots/TimeSlots";

export const SingleEventSchedule = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.SINGLE_EVENT",
  });

  const { control, setValue, register } =
    useFormContext<EventCreateEditFormValues>();
  const startDate = useWatch({ control, name: "startDate" });
  register("endDate");

  return (
    <Flex flexDir="column" gap="250">
      <Text visual="bodySemiBold">{t("TITLE")}</Text>

      <SingleDateSelection
        value={startDate}
        onValueChange={(value) => {
          setValue("startDate", value, {
            shouldDirty: true,
          });
          setValue("endDate", value, {
            shouldDirty: true,
          });
        }}
      />

      <TimeSlots scheduleIndex={0} allowMultipleTimeSlots={true} />
    </Flex>
  );
};
