import {
  addDays,
  addMonths,
  addWeeks,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  lastDayOfMonth,
  format,
  getDay,
  Locale,
  parse,
  setDate,
  setDay,
  subDays,
  eachYearOfInterval,
  set,
} from "date-fns";
import {
  EnumEventRecurrencePatternFrequency,
  EventManyQuery,
  EventRecurrencePattern,
} from "gql/generated";
import { TFunction } from "i18next";

export const EVENT_DATE_FORMAT = "yyyy-MM-dd";
export const MAX_DATE = "9999-12-31";

export enum EventDayOfWeek {
  Sunday = "SU",
  Monday = "MO",
  Tuesday = "TU",
  Wednesday = "WE",
  Thursday = "TH",
  Friday = "FR",
  Saturday = "SA",
}

/**
 * convertDaysOfWeekToNumbers
 * - Converts the ["SU", "MO", etc] array into [0, 1, etc...]
 * @param daysOfWeek
 * @returns
 */
export const convertDaysOfWeekToNumbers = (daysOfWeek: string[]) => {
  return Object.values(EventDayOfWeek)
    .map((abbrv: string, i: number) => (daysOfWeek.includes(abbrv) ? i : -1))
    .filter((val) => val > -1);
};

/**
 * getRecurrencePatternDescription
 * - Return the UI description of the recurrence pattern
 * @param t
 * @param locale
 * @param event
 */
export const getRecurrencePatternDescription = (
  t: TFunction<"events", "WIDGET">,
  locale: Locale | undefined,
  event: EventManyQuery["eventMany"][0],
) => {
  let frequencyDescription = `${t("WORDS.HAPPENING")} `;
  const pattern = event.recurrencePattern;
  const frequency = pattern.frequency;
  const repeatInterval = pattern.interval ?? 1;

  // Convert the daysOfWeek array (e.g. [MO,WE,FR]) into numbers (e.g. [1,3,5])
  // And then get the locale name for that weekday and concatenate with commas and ampersand
  const daysOfWeek = convertDaysOfWeekToNumbers(
    (pattern.daysOfWeek ?? []) as string[],
  );
  const weekdays = daysOfWeek.map((day) =>
    format(setDay(new Date(), day), "EEEE", { locale }),
  );
  const weekdaysString = [
    weekdays.slice(0, weekdays.length - 1).join(", "),
    weekdays.slice(-1),
  ]
    .filter((weekday) => !!weekday) // handle arrays of only 1 element
    .join(", & ");

  switch (frequency) {
    case EnumEventRecurrencePatternFrequency.Daily: {
      frequencyDescription +=
        repeatInterval === 1
          ? t("WORDS.DAILY").toLowerCase()
          : `${t("WORDS.EVERY").toLowerCase()} ${repeatInterval} ${t("WORDS.DAYS").toLowerCase()}`;
      break;
    }
    case EnumEventRecurrencePatternFrequency.Weekly: {
      if (repeatInterval === 1) {
        frequencyDescription += `${t("WORDS.WEEKLY").toLowerCase()} ${t("WORDS.ON")} ${weekdaysString}`;
      } else {
        frequencyDescription += `${t("WORDS.EVERY").toLowerCase()} ${t("WORDS.WEEK", { count: repeatInterval })} ${t("WORDS.ON")} ${weekdaysString}`;
      }
      break;
    }
    case EnumEventRecurrencePatternFrequency.Monthly: {
      const dateOfMonth = pattern.dateOfMonth;
      const dayOfMonth = convertDaysOfWeekToNumbers([
        pattern.dayOfMonth as string,
      ]);
      const dayOfMonthOccurrence = pattern.dayOfMonthOccurrence;

      // Note that we aren't using the i18n pluralization for weeks here because we don't want the count to show
      const repeatPattern = dateOfMonth
        ? `${t("WORDS.ON")} ${t("WORDS.DAY")} ${dateOfMonth}`
        : `${t("WORDS.ON")} ${t(`WORDS.${dayOfMonthOccurrence}_ORDINAL`)} ${format(setDay(new Date(), dayOfMonth[0]), "EEEE", { locale })}`;

      if (repeatInterval === 1) {
        frequencyDescription += `${t("WORDS.MONTHLY").toLowerCase()} ${repeatPattern}`;
      } else {
        frequencyDescription += `${t("WORDS.EVERY").toLowerCase()} ${t("WORDS.MONTH", { count: repeatInterval })} ${t("WORDS.ON")} ${repeatPattern}`;
      }
      break;
    }
    case EnumEventRecurrencePatternFrequency.Yearly: {
      const monthAndDateOfYear = pattern.monthAndDateOfYear ?? "12-31";
      const date = format(
        parse(monthAndDateOfYear, "MM-dd", new Date()),
        "LLL d",
        { locale },
      );
      frequencyDescription += `${t("WORDS.YEARLY").toLowerCase()} ${t("WORDS.ON")} ${date}`;
      break;
    }
  }

  return frequencyDescription;
};

/**
 * getRecurrencePatternDates
 * - Get the dates for a recurrence pattern within the start and end dates
 * - Also constrains the endDate to 14 months from now
 * @param frequency
 * @param repeatInterval
 * @param startDate
 * @param endDate
 * @param recurrencePattern
 */
export const getRecurrencePatternDates = (
  recurrencePattern: EventRecurrencePattern,
  startDate: string,
) => {
  const frequency = recurrencePattern.frequency;
  const repeatInterval = recurrencePattern.interval ?? 1;

  // Determine the end date to use
  // Either the event's endDate OR fourteen months from now (whichever is smaller)
  const fourteenMonthsFromNow = format(
    addMonths(new Date(), 14),
    EVENT_DATE_FORMAT,
  );
  const endDate = recurrencePattern.endDate || MAX_DATE;
  const eventEndDate =
    endDate > fourteenMonthsFromNow ? fourteenMonthsFromNow : endDate;

  const start = parse(startDate, EVENT_DATE_FORMAT, new Date());
  const end = parse(eventEndDate, EVENT_DATE_FORMAT, new Date());

  let dates: string[] = [];
  switch (frequency) {
    case EnumEventRecurrencePatternFrequency.Daily: {
      dates = getDailyDates(repeatInterval ?? 1, start, end);
      break;
    }
    case EnumEventRecurrencePatternFrequency.Weekly: {
      const daysOfWeek = recurrencePattern.daysOfWeek ?? [];
      const daysOfWeekNumbers = convertDaysOfWeekToNumbers(
        (daysOfWeek as string[]) ?? [],
      );
      dates = getWeeklyDates(repeatInterval, start, end, daysOfWeekNumbers);
      break;
    }
    case EnumEventRecurrencePatternFrequency.Monthly: {
      const dateOfMonth = recurrencePattern.dateOfMonth;
      const dayOfWeek = recurrencePattern.dayOfMonth;
      const dayOfMonthOccurrence = recurrencePattern.dayOfMonthOccurrence;
      dates = getMonthlyDates(
        repeatInterval,
        start,
        end,
        dateOfMonth,
        convertDaysOfWeekToNumbers([dayOfWeek ?? ""])[0],
        dayOfMonthOccurrence,
      );
      break;
    }
    case EnumEventRecurrencePatternFrequency.Yearly: {
      const monthAndDateOfYear = recurrencePattern.monthAndDateOfYear ?? "";
      dates = getYearlyDates(repeatInterval, start, end, monthAndDateOfYear);
      break;
    }
  }
  return dates;
};

/**
 * Get the  dates for a daily recurrence pattern
 * @param repeatInterval - The interval between  dates
 * @param start - The start date of the event
 * @param end - The end date of the event
 * @returns An array of  dates
 */
const getDailyDates = (repeatInterval: number, start: Date, end: Date) => {
  return eachDayOfInterval({ start, end }, { step: repeatInterval }).map(
    (date) => format(date, EVENT_DATE_FORMAT),
  );
};

/**
 * Get the  dates for a weekly recurrence pattern
 * @param repeatInterval - The interval between  dates
 * @param start - The start date of the event
 * @param end - The end date of the event
 * @returns An array of  dates
 */
const getWeeklyDates = (
  repeatInterval: number,
  start: Date,
  end: Date,
  daysOfWeek: number[],
) => {
  const weeks = eachWeekOfInterval(
    { start, end },
    { step: repeatInterval, weekStartsOn: 0 },
  );
  return weeks.flatMap((week) =>
    daysOfWeek
      .map((dayOfWeek) => {
        const date = setDay(week, dayOfWeek, { weekStartsOn: 0 });
        return date >= start && date <= end
          ? format(date, EVENT_DATE_FORMAT)
          : null;
      })
      .filter((date) => !!date),
  ) as string[];
};

/**
 * Get the  dates for a monthly recurrence pattern
 * @param repeatInterval - The interval between  dates
 * @param start - The start date of the event
 * @param end - The end date of the event
 * @returns An array of  dates
 */
const getMonthlyDates = (
  repeatInterval: number,
  start: Date,
  end: Date,
  dateOfMonth?: number | null,
  dayOfWeek?: number | null,
  dayOfWeekOccurrence?: number | null,
) => {
  const months = eachMonthOfInterval({ start, end }, { step: repeatInterval });
  if (dateOfMonth) {
    return months
      .flatMap((month) => {
        const date = setDate(month, dateOfMonth);
        return date >= start && date <= end
          ? format(date, EVENT_DATE_FORMAT)
          : null;
      })
      .filter((date) => !!date) as string[];
  } else if (dayOfWeek && dayOfWeekOccurrence) {
    return months
      .flatMap((month) => {
        const date =
          dayOfWeekOccurrence <= 4
            ? getNthOccurrenceInMonth(month, dayOfWeek, dayOfWeekOccurrence)
            : getLastOccurrenceInMonth(month, dayOfWeek);
        return date >= start && date <= end
          ? format(date, EVENT_DATE_FORMAT)
          : null;
      })
      .filter((date) => !!date) as string[];
  }
  return [];
};

/**
 * Get the nth occurrence of a day in a month
 * @param firstDayOfMonth - The first day of the month
 * @param dayOfWeek - The day of the week to get the nth occurrence of
 * @param occurrence - The occurrence to get
 * @returns The nth occurrence of the day in the month
 */
const getNthOccurrenceInMonth = (
  firstDayOfMonth: Date,
  dayOfWeek: number,
  occurrence: number,
) => {
  const firstOccurrence =
    firstDayOfMonth.getDay() === dayOfWeek
      ? firstDayOfMonth
      : addDays(
          firstDayOfMonth,
          (dayOfWeek - firstDayOfMonth.getDay() + 7) % 7,
        );

  return addWeeks(firstOccurrence, occurrence - 1);
};

/**
 * Get the last occurrence of a day in a month
 * @param month - The month to get the last occurrence of the day in
 * @param dayNumber - The day of the week to get the last occurrence of
 * @returns The last occurrence of the day in the month
 */
const getLastOccurrenceInMonth = (month: Date, dayNumber: number) => {
  let date = lastDayOfMonth(month);
  for (let i = 6; i >= 0; i--) {
    const day = getDay(date);
    if (day === dayNumber) return date;

    // Subtract one day from the date
    date = subDays(date, 1);
  }
  return date;
};

/**
 * Get the  dates for a yearly recurrence pattern
 * @param repeatInterval - The interval between  dates
 * @param start - The start date of the event
 * @param end - The end date of the event
 * @returns An array of  dates
 */
const getYearlyDates = (
  repeatInterval: number,
  start: Date,
  end: Date,
  monthAndDateOfYear: string,
) => {
  const [month, date] = monthAndDateOfYear.split("-");
  return eachYearOfInterval({ start, end }, { step: repeatInterval }).map(
    (year) => {
      const formattedDate = format(
        set(year, {
          month: Number(month) - 1, // Because this uses 0-11 for months
          date: Number(date),
        }),
        EVENT_DATE_FORMAT,
      );
      return formattedDate;
    },
  ) as string[];
};
