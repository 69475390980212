import { Flex } from "styled-system/jsx";
import { useTranslation } from "react-i18next";
import { EventStepHeader } from "../EventStepHeader/EventStepHeader";
import { EnumEventLocationType } from "gql/generated";
import { InPersonEvent, InPersonEventRef } from "./InPersonEvent";
import { VirtualEvent } from "./VirtualEvent";
import { useFormContext } from "react-hook-form";
import { useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useRef } from "react";

export const EventHostAndLocations = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_HOST",
  });

  const { control } = useFormContext<EventCreateEditFormValues>();
  const locationType = useWatch({ control, name: "locationType" });
  const otherLocations = useWatch({ control, name: "otherLocations" });
  const inPersonEventRef = useRef<InPersonEventRef>(null);

  // There are two flows. One for locationType === IN_PERSON and one for locationType === VIRTUAL
  const getContent = () => {
    switch (locationType) {
      case EnumEventLocationType.InPerson:
        return <InPersonEvent ref={inPersonEventRef} />;
      case EnumEventLocationType.Virtual:
        return <VirtualEvent />;
    }
  };

  return (
    <Flex flexDir="column" gap="400">
      <EventStepHeader
        step={2}
        title={t("TITLE")}
        description={
          locationType === EnumEventLocationType.InPerson
            ? t("DESCRIPTION_IN_PERSON")
            : t("DESCRIPTION_VIRTUAL")
        }
        ctaButtonProps={
          locationType === EnumEventLocationType.InPerson &&
          (otherLocations?.length ?? 0) === 0
            ? {
                children: t("ADD_LOCATION_VENUE"),
                onClick: () => inPersonEventRef.current?.addNewLocation(),
              }
            : undefined
        }
      />

      {getContent()}
    </Flex>
  );
};
