import {
  EnumEventAccessOperatorStatus,
  EnumEventType,
  EventAccessOperator,
  EventByIdQuery,
  useEventByIdLazyQuery,
  useEventDeleteByIdMutation,
  useEventUpdateByIdMutation,
} from "gql/generated";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getMultilingualString } from "shared/utils";
import { useToastContext } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { ModalMode } from "apps/events/types/ModalMode";

type UseEventActionsProps = {
  isOpen: boolean;
  initialMode: ModalMode;
  onClose: () => void;
  onEventUpdated: (event: EventByIdQuery["eventById"]) => void;
  onEventDeleted: (eventId: string) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  eventId?: string;
  isOrganizationUser: boolean;
  isOperatorUser: boolean;
  defaultOrganizationId: string;
};

export const useEventActions = ({
  isOpen,
  initialMode,
  onClose,
  onEventUpdated,
  onEventDeleted,
  setCurrentStep,
  eventId,
  isOrganizationUser,
  isOperatorUser,
  defaultOrganizationId,
}: UseEventActionsProps) => {
  const { t } = useTranslation();
  const { toastFactory } = useToastContext();
  const organizationId = isOrganizationUser && defaultOrganizationId;

  // Keep track of the modals current mode
  const [mode, setMode] = useState<ModalMode>(initialMode);
  useEffect(() => {
    setMode(initialMode);
  }, [initialMode, isOpen]);

  // When the eventId changes, we need to fetch the event details (EventMany does not return the full details)
  const [event, setEvent] = useState<EventByIdQuery["eventById"]>();
  const [status, setStatus] = useState<EnumEventAccessOperatorStatus>(
    EnumEventAccessOperatorStatus.Draft,
  );
  const [fetchEvent, { loading: isFetchingEvent, error: fetchEventError }] =
    useEventByIdLazyQuery();

  // When the modal is opened and there is in eventId, fetch the event details
  useEffect(() => {
    if (isOpen && eventId) {
      fetchEvent({
        variables: { id: eventId },
        onCompleted: (data) => {
          setEvent(data.eventById);
          setStatus(
            data.eventById?.access.organizations[0]?.status ??
              EnumEventAccessOperatorStatus.Draft,
          );
        },
      });
    } else {
      setEvent(undefined);
      setStatus(EnumEventAccessOperatorStatus.Draft);
    }
  }, [isOpen, eventId, fetchEvent]);

  // Mutations to Approve/Cancel/Decline an event
  const [updateEventStatus, { loading: isUpdatingEventStatus }] =
    useEventUpdateByIdMutation();

  // Some of the mutations can ONLY be performed by an organization
  // This function checks if the current user is an organization user
  const isValidOrganizationUser = () => {
    if (!isOrganizationUser || !organizationId) {
      return false;
    }
    return true;
  };

  /**
   * Send the status update request to the backend
   * - This is used for the Approve/Decline/Cancel mutations
   * - Only organization users can update the status of an event
   * - Only the status for their own organization is updated
   * @param status - The status to update the event to
   * @returns void
   */
  const handleStatusUpdate = (
    status: EnumEventAccessOperatorStatus,
    allowOperator = false,
  ) => {
    if (!isValidOrganizationUser() && !allowOperator) {
      return;
    }

    updateEventStatus({
      variables: {
        id: event?._id,
        record: {
          access: {
            organizations: event?.access.organizations
              .filter((org) => !!org)
              .map((org: EventAccessOperator) => ({
                ...org,
                status:
                  org?._entityId === organizationId || allowOperator
                    ? status
                    : org.status,
              })),
          },
        },
      },
    })
      .then((result) => {
        if (!result.errors && result.data?.eventUpdateById?.record) {
          updateEvent(result.data?.eventUpdateById?.record);
          onClose();
          toastFactory.create({
            title: t("core:SUCCESS"),
            description: t(`events:APP.MODAL.MUTATION_RESULT.${status}`, {
              title: getMultilingualString(event?.title ?? {}),
            }),
            duration: 3000,
          });
        } else {
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("events:APP.MODAL.MUTATION_RESULT.ERROR", {
              title: getMultilingualString(event?.title ?? {}),
            }),
            duration: 3000,
          });
        }
      })
      .catch(() =>
        toastFactory.create({
          title: t("core:ERROR.TITLE"),
          description: t("events:APP.MODAL.MUTATION_RESULT.ERROR", {
            title: getMultilingualString(event?.title ?? {}),
          }),
          duration: 3000,
        }),
      );
  };

  /**
   * Send the approval request to the backend
   * ONLY organizations can approve events
   * - And they can only approve the status for their own organization
   * @returns void
   */
  const handleApproveClick = () =>
    handleStatusUpdate(EnumEventAccessOperatorStatus.Approved);

  /**
   * Send the declined request to the backend
   * ONLY organizations can approve events
   * - And they can only decline the status for their own organization
   * @returns void
   */
  const handleDeclineClick = () =>
    handleStatusUpdate(EnumEventAccessOperatorStatus.Declined);

  /**
   * Send the cancel request to the backend
   * - When an organization user cancels an event, it cancels it ONLY for their own organization
   * - When an operator cancels an event, it cancels it for ALL organizations
   * @returns void
   */
  const handleCancelClick = () =>
    handleStatusUpdate(EnumEventAccessOperatorStatus.Cancelled, isOperatorUser);

  /**
   * Set the mode to edit
   * - This will update the footer buttons and swap the content of the modal
   * @returns void
   */
  const handleEditClick = () => {
    setCurrentStep(1);
    setMode("edit");
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
  const [confirmationModalAction, setConfirmationModalAction] = useState<
    "cancel" | "decline" | "delete"
  >("cancel");

  /**
   * Handle the menu action
   * - The action menus has items for Cancel and Delete
   * @param item - The menu item that was selected
   * @returns void
   */
  const handleMenuAction = ({
    value,
  }: {
    value: "cancel" | "decline" | "delete";
  }) => {
    setIsConfirmationModalOpen(true);
    switch (value) {
      case "cancel":
        setConfirmationModalMessage(
          `${t("events:APP.MODAL.CONFIRM_ACTION.CANCEL")} ${event?.type !== EnumEventType.Single ? t("events:APP.MODAL.CONFIRM_ACTION.CANCEL_MULTI_OR_RECURRING") : ""}`,
        );
        setConfirmationModalAction("cancel");
        break;
      case "decline":
        setConfirmationModalMessage(
          t("events:APP.MODAL.CONFIRM_ACTION.DECLINE"),
        );
        setConfirmationModalAction("decline");
        break;
      case "delete":
        setConfirmationModalMessage(
          t("events:APP.MODAL.CONFIRM_ACTION.DELETE"),
        );
        setConfirmationModalAction("delete");
        break;
    }
  };

  const handleConfirmationModalActionClick = (confirmed: boolean) => {
    setIsConfirmationModalOpen(false);
    if (confirmed) {
      if (confirmationModalAction === "cancel") {
        handleCancelClick();
      } else if (confirmationModalAction === "decline") {
        handleDeclineClick();
      } else if (confirmationModalAction === "delete") {
        handleDeleteClick();
      }
    }
  };

  // Mutation to Delete an event
  const [deleteEvent, { loading: isDeletingEvent }] =
    useEventDeleteByIdMutation();

  /**
   * Send the delete request to the backend
   * ONLY the user who created the event can delete it
   * @returns void
   */
  const handleDeleteClick = () => {
    deleteEvent({ variables: { id: event?._id } })
      .then((result) => {
        if (!result.errors && result.data?.eventDeleteById?.record) {
          onEventDeleted(event?._id);
          onClose();
          toastFactory.create({
            title: t("core:SUCCESS"),
            description: t("events:APP.MODAL.MUTATION_RESULT.DELETED", {
              title: getMultilingualString(event?.title ?? {}),
            }),
            duration: 3000,
          });
        } else {
          toastFactory.create({
            title: t("core:ERROR.TITLE"),
            description: t("events:APP.MODAL.MUTATION_RESULT.ERROR", {
              title: getMultilingualString(event?.title ?? {}),
            }),
            duration: 3000,
          });
        }
      })
      .catch(() =>
        toastFactory.create({
          title: t("core:ERROR.TITLE"),
          description: t("events:APP.MODAL.MUTATION_RESULT.ERROR", {
            title: getMultilingualString(event?.title ?? {}),
          }),
          duration: 3000,
        }),
      );
  };

  // Update the event details in the modal and send the updated event to the parent
  const updateEvent = (event: EventByIdQuery["eventById"]) => {
    setEvent(event);
    onEventUpdated(event as EventByIdQuery["eventById"]);
  };

  return {
    mode,
    event,
    loading: isFetchingEvent,
    error: fetchEventError,
    isSaving: isUpdatingEventStatus || isDeletingEvent,
    status,
    isConfirmationModalOpen,
    confirmationModalMessage,
    handleApproveClick,
    handleConfirmationModalActionClick,
    handleEditClick,
    handleMenuAction,
    updateEvent,
  };
};
