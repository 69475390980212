import { useEffect, useState } from "react";
import { WidgetOperatorsWithOverrides } from "../shared/types";
import { usePostHog } from "posthog-js/react";
import { useOperatorsWidgetContext } from "../components/OperatorsContext/OperatorsContext";

type UseDisplayOperatorsProps = {
  filteredOperators: WidgetOperatorsWithOverrides;
};

export const useDisplayOperators = ({
  filteredOperators,
}: UseDisplayOperatorsProps) => {
  const posthog = usePostHog();

  const { gridPageSize } = useOperatorsWidgetContext();
  const BATCH_SIZE = gridPageSize ?? 25;

  const [selectedOperatorId, setSelectedOperatorId] = useState<
    string | undefined
  >(undefined);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastIndex, setLastIndex] = useState(BATCH_SIZE);
  const [displayedOperators, setDisplayedOperators] = useState(
    filteredOperators.slice(0, lastIndex),
  );

  useEffect(() => {
    setIsLoadingMore(true);
    setDisplayedOperators(filteredOperators.slice(0, lastIndex));
    setIsLoadingMore(false);
  }, [filteredOperators, lastIndex]);

  const handleLoadMoreOperators = () => {
    setIsLoadingMore(true);
    const newLastIndex =
      lastIndex + BATCH_SIZE > filteredOperators.length
        ? filteredOperators.length
        : lastIndex + BATCH_SIZE;

    setLastIndex(newLastIndex);
    setIsLoadingMore(false);
  };

  const handleExitSeeDetails = () => {
    posthog.capture("close-operator-details", {
      selectedOperatorId: selectedOperatorId,
    });
    setSelectedOperatorId(undefined);
  };
  return {
    onSeeDetails: setSelectedOperatorId,
    onLoadMoreOperators: handleLoadMoreOperators,
    onExitSeeDetails: handleExitSeeDetails,
    selectedOperatorId,
    displayedOperators,
    isLoadingMore,
  };
};
