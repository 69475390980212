import { Button, Select, Text } from "@ttc3k/trekker";
import { PageFlip, Plus, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Flex, Grid } from "styled-system/jsx";
import React from "react";
import { useTimeSlots } from "./useTimeSlots";
import { ControlledSelectRoot } from "shared/components";
import { TimeSlotNoteModal } from "./TimeSlotNoteModal";
import { useEventCreateEditContext } from "../../EventCreateEditContext/EventCreateEditContext";

type TimeSlotProps = {
  scheduleIndex: number;
  allowMultipleTimeSlots: boolean;
};

export const TimeSlots = ({
  scheduleIndex,
  allowMultipleTimeSlots,
}: TimeSlotProps) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.TIME_SLOTS",
  });

  const { locale } = useEventCreateEditContext();

  const {
    isNoteModalOpen,
    noteToEdit,
    timeSlots,
    timeSlotOptions,
    getEndTimeOptions,
    handleAddTimeSlot,
    handleDeleteTimeSlot,
    handleTimeSlotChange,
    handleNoteModalOpen,
    handleNoteModalClose,
    handleNoteModalUpdate,
  } = useTimeSlots(scheduleIndex, locale);

  return (
    <>
      <Flex flexDir="column" gap="250">
        {allowMultipleTimeSlots && (
          <Flex flexDir="column" gap="100">
            <Text visual="bodySemiBold">{t("TITLE")}</Text>
            <Text visual="bodyRegular" color="text.mid">
              {t("DESCRIPTION")}
            </Text>
          </Flex>
        )}

        {timeSlots.length > 0 && (
          <Flex flexDir="column" gap="50">
            <Grid
              gridTemplateColumns="1fr 1fr 40px 40px"
              gridColumnGap="150"
              alignItems="flex-end"
            >
              <Text visual="smallMedium" color="text.mid">
                {t("START_TIME")}
              </Text>
              <Text visual="smallMedium" color="text.mid">
                {t("END_TIME")}
              </Text>
              {/* Add spacer divs for the grid */}
              <div />
              <div />
            </Grid>
            <Grid
              gridTemplateColumns="1fr 1fr 40px 40px"
              gridColumnGap="150"
              gridRowGap="150"
              alignItems="center"
            >
              {timeSlots.map((timeSlot, i) => {
                const endTimeOptions = getEndTimeOptions(timeSlot.from || "");

                return (
                  <React.Fragment
                    key={`${scheduleIndex}-${i}-${timeSlot.from || timeSlot.to || timeSlot.note}`}
                  >
                    {/* Start time */}
                    <ControlledSelectRoot
                      name={`schedules.${scheduleIndex}.timeSlots.${i}.from`}
                      items={timeSlotOptions}
                      value={[timeSlot.from || ""]}
                      onValueChange={({ value }: { value: string[] }) =>
                        handleTimeSlotChange(i, value[0], "from")
                      }
                      multiple={false}
                      placeholder={t("SELECT")}
                    >
                      {timeSlotOptions.map((timeSlotOption, index) => (
                        <Select.Item
                          key={`${timeSlotOption.value}-${timeSlotOption.label}-${index}`}
                          item={timeSlotOption}
                        />
                      ))}
                    </ControlledSelectRoot>

                    {/* End time */}
                    <ControlledSelectRoot
                      name={`schedules.${scheduleIndex}.timeSlots.${i}.to`}
                      items={endTimeOptions}
                      value={[timeSlot.to || ""]}
                      onValueChange={({ value }: { value: string[] }) =>
                        handleTimeSlotChange(
                          i,
                          value[0],
                          "to",
                          endTimeOptions.find(
                            (endTime) => endTime.value === value[0],
                          )?.isNextDay ?? false,
                        )
                      }
                      multiple={false}
                      placeholder={t("SELECT")}
                      disabled={!timeSlot.from}
                    >
                      {endTimeOptions.map((timeSlotOption, index) => (
                        <Select.Item
                          key={`${timeSlotOption.value}-${timeSlotOption.label}-${timeSlot.endsNextDay}-${index}`}
                          item={timeSlotOption}
                          color={
                            timeSlotOption.isNextDay
                              ? "text.accent.purple.dark"
                              : "text.dark"
                          }
                        />
                      ))}
                    </ControlledSelectRoot>

                    {/* Notes */}
                    <Button
                      visual="primary"
                      mode="light"
                      Icon={PageFlip}
                      color={timeSlot?.note ? "icon.success.mid" : "icon.mid"}
                      type="button"
                      onClick={() => handleNoteModalOpen(timeSlot.note, i)}
                    />

                    {/* Delete button */}
                    <Button
                      visual="primary"
                      mode="light"
                      Icon={Trash}
                      onClick={() => handleDeleteTimeSlot(i)}
                      type="button"
                    />
                  </React.Fragment>
                );
              })}
            </Grid>
          </Flex>
        )}

        {(allowMultipleTimeSlots || !timeSlots || timeSlots.length === 0) && (
          <Button
            visual="unstyled"
            color={{
              base: "text.accent.blue.mid",
              hover: "text.accent.blue.dark",
              focusVisible: "text.accent.blue.dark",
            }}
            Icon={Plus}
            pl={0}
            width="fit-content"
            alignItems="center"
            fontSize="16px"
            rowGap="50"
            columnGap="50"
            gap="50"
            borderRadius="25"
            onClick={handleAddTimeSlot}
            type="button"
          >
            {t("ADD_TIME_SLOT")}
          </Button>
        )}
      </Flex>

      <TimeSlotNoteModal
        isOpen={isNoteModalOpen}
        note={noteToEdit}
        onClose={handleNoteModalClose}
        onUpdateNote={(updatedNote) => handleNoteModalUpdate(updatedNote)}
      />
    </>
  );
};
