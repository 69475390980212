import { useRef } from "react";
import { Portal, StyleRoot, useToastContext } from "@ttc3k/trekker";
import styles from "styled-system/styles.css?inline";
import {
  DetailsSidePaneProps as OperatorsDetailsSidePaneProps,
  DetailsSidePane as OperatorsDetailsSidePane,
} from "widgets/operators/components/DetailsSidePane";
import {
  DetailsSidePaneProps as EventsDetailsSidePaneProps,
  DetailsSidePane as EventsDetailsSidePane,
} from "widgets/events/components/DetailsSidePane";
import {
  DetailsSidePaneProps as EventSubmitDetailsSidePaneProps,
  DetailsSidePane as EventSubmitDetailsSidePane,
} from "widgets/eventSubmit/components/DetailsSidePane";

type RenderDetailsSidePaneProps = (
  | OperatorsDetailsSidePaneProps
  | EventsDetailsSidePaneProps
  | EventSubmitDetailsSidePaneProps
) & {
  sidePaneType: "operators" | "events" | "eventSubmit";
  toastFactoryOverride?: ReturnType<typeof useToastContext>["toastFactory"];
};

export const RenderDetailsSidePane = ({
  sidePaneType,
  toastFactoryOverride,
  ...sidePaneProps
}: RenderDetailsSidePaneProps) => {
  const portalRef = useRef<HTMLElement>(document.body);

  const DetailsSidePane =
    sidePaneType === "operators"
      ? OperatorsDetailsSidePane
      : sidePaneType === "events"
        ? EventsDetailsSidePane
        : EventSubmitDetailsSidePane;

  return (
    <Portal container={portalRef}>
      {/* This div is used to ensure the DetailsSidePane is rendered above other elements */}
      <div id="details-side-pane-shadow-root" style={{ zIndex: 2147483647 }}>
        <StyleRoot
          styles={[styles]}
          toastFactoryOverride={toastFactoryOverride}
          useShadowDOM
        >
          <DetailsSidePane {...sidePaneProps} />
        </StyleRoot>
      </div>
    </Portal>
  );
};
