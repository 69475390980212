import { Combobox } from "@ttc3k/trekker";
import { Text } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { Flex } from "styled-system/jsx";
import { useEventKeywords } from "./useEventKeywords";
import { EmptyState } from "shared/components";
import { useEventCreateEditContext } from "../../EventCreateEditContext/EventCreateEditContext";
import { ComboboxGroupData } from "apps/events/types/ComboboxData";
import { ComboboxData } from "apps/events/types/ComboboxData";

export const EventKeywords = () => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_DETAILS.EVENT_KEYWORDS",
  });

  const {
    globalEventTags,
    organizationEventTags,
    isOrganizationUser,
    defaultOrganizationId,
  } = useEventCreateEditContext();

  const {
    filteredGlobalTags,
    filteredOrganizationTags,
    selectedGlobalTags,
    selectedOrganizationTags,
    handleFilterGlobalTags,
    handleFilterOrganizationTags,
    handleComboboxOpenChange,
    setValue,
  } = useEventKeywords({
    globalEventTags,
    organizationEventTags,
  });

  return (
    <>
      <KeywordGroup
        title={t("GLOBAL.TITLE")}
        description={t("GLOBAL.DESCRIPTION")}
        placeholder={t("PLACEHOLDER_LABEL")}
        filteredTags={filteredGlobalTags}
        selectedTags={selectedGlobalTags}
        onTagValueChange={(value) =>
          setValue("tags.global", value, { shouldDirty: true })
        }
        onTagInputValueChange={(search) => handleFilterGlobalTags(search)}
        onComboboxOpenChange={() => handleComboboxOpenChange()}
        hasGroups={true}
      />
      {isOrganizationUser && filteredOrganizationTags.length > 0 ? (
        <KeywordGroup
          title={t("ORGANIZATION.TITLE")}
          description={t("ORGANIZATION.DESCRIPTION")}
          placeholder={t("PLACEHOLDER_LABEL")}
          filteredTags={filteredOrganizationTags}
          selectedTags={selectedOrganizationTags}
          onTagValueChange={(value) =>
            setValue("tags.organizationSpecific", value, { shouldDirty: true })
          }
          onTagInputValueChange={(search) =>
            handleFilterOrganizationTags(search)
          }
          onComboboxOpenChange={() => handleComboboxOpenChange()}
        />
      ) : isOrganizationUser && defaultOrganizationId ? (
        <EmptyState
          text={t("EMPTY")}
          ctaButtonProps={{
            children: t("EMPTY_CTA"),
            size: "md",
            onClick: () =>
              // changed from navigate because we can't use that in the eventSubmitWidget
              (location.href = `/app/organization/${defaultOrganizationId}/profile`),
          }}
        />
      ) : null}
    </>
  );
};

type KeywordGroupProps = {
  title: string;
  description: string;
  placeholder: string;
  filteredTags: ComboboxData[] | ComboboxGroupData[];
  selectedTags: string[];
  hasGroups?: boolean;
  onTagValueChange: (value: string[]) => void;
  onTagInputValueChange: (inputValue: string) => void;
  onComboboxOpenChange: ({ open }: { open: boolean }) => void;
};

const KeywordGroup = ({
  title,
  description,
  placeholder,
  filteredTags,
  selectedTags,
  onTagValueChange,
  onTagInputValueChange,
  onComboboxOpenChange,
  hasGroups,
}: KeywordGroupProps) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.LOOKUPS",
  });

  return (
    <Flex flexDir="column" gap="150">
      <Text visual="bodySemiBold">{title}</Text>
      <Text visual="bodyRegular" color="text.mid">
        {description}
      </Text>
      <Combobox.Root
        value={selectedTags}
        onValueChange={(d) => onTagValueChange(d.value)}
        onInputValueChange={(d) => onTagInputValueChange(d.inputValue)}
        positioning={{
          placement: "top",
        }}
        onOpenChange={onComboboxOpenChange}
        items={
          hasGroups
            ? (filteredTags as ComboboxGroupData[]).flatMap(
                (group) => group.tags,
              )
            : filteredTags
        }
        placeholder={selectedTags?.length === 0 ? `${placeholder}...` : ""}
        css={{
          "& [data-scope='combobox'][data-part='input'], [data-scope='combobox'][data-part='input-wrapper']":
            {
              bg: "white",
            },
        }}
        multiple
      >
        {hasGroups
          ? (filteredTags as ComboboxGroupData[]).map((group) => (
              <Combobox.ItemGroup key={group.group}>
                <Combobox.ItemGroupLabel>
                  {t(group.group)}
                </Combobox.ItemGroupLabel>
                {group.tags.map((item) => (
                  <Combobox.Item key={item.value} item={item} {...item} />
                ))}
              </Combobox.ItemGroup>
            ))
          : (filteredTags as ComboboxData[]).map((item) => (
              <Combobox.Item key={item.value} item={item} {...item} />
            ))}
      </Combobox.Root>
    </Flex>
  );
};
