import { OperatorWithOverrides } from "gql/generated";
import { getMultilingualString } from "./getMultilingualString";

export const getFormattedOperatorDetailsLink = (
  operator: Partial<OperatorWithOverrides>,
  operatorDetailsLink: string,
) => {
  const name = getMultilingualString(operator.name ?? {});
  const sanitizedName = name
    .trim()
    .normalize("NFD") // Decompose accented characters (e.g. è -> e`)
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-"); // Replace all non-alphanumeric characters with hyphens

  return operatorDetailsLink
    .replace("{OPERATOR_ID}", operator._entityId)
    .replace("{OPERATOR_NAME}", sanitizedName);
};
