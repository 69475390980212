import { usePostHog } from "posthog-js/react";
import { Swiper as SwiperType } from "swiper/types";
import { useEffect, useState } from "react";

export const useSwiperScroll = () => {
  const posthog = usePostHog();
  const [scroll, setScroll] = useState({
    isScrollOpen: true,
    isEnd: false,
    isBeginning: true,
  });
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  // Handle Swiper initialization and cleanup
  useEffect(() => {
    if (swiper && !swiper.destroyed) {
      const updateScrollState = (s: SwiperType) => {
        if (s.isBeginning && s.isEnd) {
          setScroll({
            isBeginning: true,
            isEnd: true,
            isScrollOpen: false,
          });
        } else {
          setScroll({
            isBeginning: s.isBeginning,
            isEnd: s.isEnd,
            isScrollOpen: true,
          });
        }
      };

      // Initial check
      updateScrollState(swiper);

      // Add resize listener
      swiper.on("resize", updateScrollState);
      swiper.on("slideChange", updateScrollState);
      swiper.on("scrollbarDragMove", updateScrollState);

      // Cleanup
      return () => {
        swiper.off("resize");
        swiper.off("slideChange");
        swiper.off("scrollbarDragMove");
      };
    }
  }, [swiper]);

  const handleNextClick = (postHogEventName?: string) => {
    swiper?.slideNext();
    if (postHogEventName) posthog.capture(postHogEventName);
  };

  const handlePrevClick = (postHogEventName?: string) => {
    swiper?.slidePrev();
    if (postHogEventName) posthog.capture(postHogEventName);
  };

  return { scroll, swiper, setSwiper, handleNextClick, handlePrevClick };
};
