import { useState } from "react";
import { Flex } from "styled-system/jsx";
import { FilterCarousel, OperatorCarousel, OperatorGrid } from "./components";
import { Heading, Spinner } from "@ttc3k/trekker";
import { EmptyState } from "shared/components";
import _ from "lodash";
import { useOperatorsWidgetContext } from "widgets/operators/components/OperatorsContext";
import { PostHogCustomEvent, usePostHog } from "shared/hooks/usePostHog";

type OperatorsWidgetProps = {
  viewOnly?: boolean;
  listDisplayMode?: OperatorsWidgetListDisplayMode;
};

type OperatorsWidgetListDisplayMode = "grid" | "carousel";
type OperatorsWidgetDetailsDisplayMode = "slideout" | "link";

export function OperatorsWidget({
  viewOnly = false,
  listDisplayMode = "grid",
}: OperatorsWidgetProps) {
  /* State to store the selected tag id from the filter carousel */
  const [selectedTagId, setSelectedTagId] = useState<string | undefined>(
    undefined,
  );

  const {
    operators,
    appError,
    appLoading,
    widgetName,
    tags,
    displayWidgetName,
  } = useOperatorsWidgetContext();

  const { trackPostHogEvent } = usePostHog();

  /* Get filtered operators based on selected tag id */
  const filteredOperators = selectedTagId
    ? operators.filter(op => {
        /**
         * Flat map all operator tags because they are nested inside the tag object
         * Omit `__typename` as we are filtering data directly from the backend
         */
        const allOpTags = Object.values(_.omit(op.tags, "__typename"))
          .flat()
          .map(tag => tag._id);
        return allOpTags.includes(selectedTagId);
      })
    : operators;

  /* Set the tag selection on click, and send event to Posthog */
  const handleTagSelection = (id: string) => {
    const isCurrentId = id === selectedTagId;
    setSelectedTagId(isCurrentId ? undefined : id);
    trackPostHogEvent(
      isCurrentId
        ? PostHogCustomEvent.FilterTagRemoved
        : PostHogCustomEvent.FilterTagAdded,
      {
        selectedFilterTagId: id,
      },
    );
  };

  if (appLoading)
    return (
      <Flex
        w={"full"}
        h={"full"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner width={"40px"} height={"40px"} />
      </Flex>
    );

  if (appError)
    return <EmptyState height={"500px"} text={"Error loading widget"} />;

  return (
    <Flex
      flexDir={"column"}
      mx={"auto"}
      alignItems={"center"}
      gap={"24px"}
      width={"100%"}
    >
      {displayWidgetName && widgetName && (
        <Heading size={"h4"} alignSelf="flex-start" part={"widget__title"}>
          {widgetName}
        </Heading>
      )}
      {listDisplayMode === "grid" && (
        <>
          <FilterCarousel
            tags={tags}
            selectedTagId={selectedTagId}
            onTagSelect={id => handleTagSelection(id)}
          />
          <OperatorGrid
            filteredOperators={filteredOperators}
            viewOnly={viewOnly}
          />
        </>
      )}
      {listDisplayMode === "carousel" && (
        <OperatorCarousel
          filteredOperators={filteredOperators}
          viewOnly={viewOnly}
        />
      )}
    </Flex>
  );
}

export type {
  OperatorsWidgetListDisplayMode,
  OperatorsWidgetDetailsDisplayMode,
};
export default OperatorsWidget;
