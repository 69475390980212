import { Box, Flex } from "styled-system/jsx";
import { EventStatusIndicator } from "shared/components";
import { Heading } from "@ttc3k/trekker";
import { useTranslation } from "react-i18next";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";

export const EventModalHeader = () => {
  const { t } = useTranslation("events", { keyPrefix: "APP.MODAL.HEADER" });

  const { mode, status } = useEventCreateEditContext();

  if (mode === "approve") return null;

  return (
    <Box pb="250" mb="300" borderBottom="1px solid" borderColor="border.light">
      <Flex flexDir={"column"} gap={"150"}>
        <EventStatusIndicator status={status} />
        <Heading size="h4">{t(`TITLE_${mode.toUpperCase()}`)}</Heading>
      </Flex>
    </Box>
  );
};
