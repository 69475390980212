import { format, Locale } from "date-fns";
import { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";

export const useTimeSlots = (
  scheduleIndex: number,
  locale: Locale | undefined,
) => {
  const { control, getValues, setValue, register } =
    useFormContext<EventCreateEditFormValues>();

  register(`schedules.${scheduleIndex}`);
  const timeSlots =
    useWatch({
      control,
      name: `schedules.${scheduleIndex}.timeSlots`,
    })?.filter((slot) => !!slot) ?? [];

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 4 }, (_, i) => i * 15);

  // Memoize the time slot options
  const timeSlotOptions = useMemo(() => {
    const now = new Date().toISOString().split("T")[0];
    return hours.flatMap((hour) =>
      minutes.flatMap((minute) => ({
        label: format(
          `${now}T${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`,
          "h:mma",
          { locale },
        ).toLowerCase(),
        value: `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`,
        isNextDay: false,
      })),
    );
  }, [hours, minutes, locale]);

  /**
   * Add a new time slot
   */
  const handleAddTimeSlot = () => {
    setValue(
      `schedules.${scheduleIndex}.timeSlots`,
      [
        ...timeSlots,
        {
          from: "",
          to: "",
          endsNextDay: false,
          note: "",
        },
      ],
      { shouldDirty: true },
    );
  };

  /**
   * Delete a time slot
   */
  const handleDeleteTimeSlot = (index: number) => {
    setValue(
      `schedules.${scheduleIndex}.timeSlots`,
      [...timeSlots.slice(0, index), ...timeSlots.slice(index + 1)],
      {
        shouldDirty: true,
      },
    );
    // Marks the schedule array as dirty (even though it should be from the above setValue)
    // But because we aren't tied to a form control, we need to do this to trigger the form to update
    setValue(
      `schedules.${scheduleIndex}`,
      getValues(`schedules.${scheduleIndex}`),
      { shouldDirty: true },
    );
  };

  /**
   * Change the time slot start/end time
   */
  const handleTimeSlotChange = (
    index: number,
    value: string,
    type: "from" | "to" | "note",
    isNextDay?: boolean,
  ) => {
    setValue(`schedules.${scheduleIndex}.timeSlots.${index}.${type}`, value, {
      shouldDirty: true,
    });
    // If we're setting the end time, we need to set the endsNextDay flag
    if (type === "to") {
      setValue(
        `schedules.${scheduleIndex}.timeSlots.${index}.endsNextDay`,
        isNextDay ?? false,
        { shouldDirty: true },
      );
    }
  };

  /**
   * Get the end time options
   * - Takes the start time and returns the next 24 hours
   * - Times into the next day have isNextDay set to true
   */
  const getEndTimeOptions = (startTime: string) => {
    const startTimeIndex = timeSlotOptions.findIndex(
      (option) => option.value === startTime,
    );
    const restOfTheDay = timeSlotOptions.slice(startTimeIndex + 1);
    const nextDay = timeSlotOptions
      .slice(0, startTimeIndex)
      .map((timeSlot) => ({
        ...timeSlot,
        isNextDay: true,
      }));
    return [...restOfTheDay, ...nextDay];
  };

  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState("");
  const [noteToEditIndex, setNoteToEditIndex] = useState<number | null>(null);

  const handleNoteModalOpen = (note: string, index: number) => {
    setIsNoteModalOpen(true);
    setNoteToEdit(note);
    setNoteToEditIndex(index);
  };

  const handleNoteModalClose = () => {
    setIsNoteModalOpen(false);
    setNoteToEdit("");
    setNoteToEditIndex(null);
  };

  const handleNoteModalUpdate = (updatedNote: string) => {
    if (noteToEditIndex !== null) {
      handleTimeSlotChange(noteToEditIndex, updatedNote, "note");
    }
    handleNoteModalClose();
  };

  return {
    isNoteModalOpen,
    noteToEdit,
    timeSlots,
    timeSlotOptions,
    getEndTimeOptions,
    handleAddTimeSlot,
    handleDeleteTimeSlot,
    handleTimeSlotChange,
    handleNoteModalOpen,
    handleNoteModalClose,
    handleNoteModalUpdate,
  };
};
