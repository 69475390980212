import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { Locale } from "date-fns";
import {
  EnumEventRecurrencePatternDayOfMonth,
  EnumEventRecurrencePatternDaysOfWeek,
  EnumEventRecurrencePatternFrequency,
  EventRecurrencePattern,
} from "gql/generated";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { getDay } from "date-fns";
import { getDate } from "date-fns";
import { parse } from "date-fns";
import { EVENT_DATE_FORMAT, EventDayOfWeek, MAX_DATE } from "shared/utils";
import { useEffect, useMemo, useState, useCallback } from "react";
import { indexOf } from "lodash";
import _ from "lodash";

export enum RecurrencePatternOptionValues {
  Daily = "DAILY",
  Weekly = "WEEKLY",
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
  Weekdays = "WEEKDAYS",
  Custom = "CUSTOM",
}

type UseRecurringEventRepeatRuleProps = {
  locale: Locale | undefined;
};

export const useRecurringEventRepeatRule = ({
  locale,
}: UseRecurringEventRepeatRuleProps) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.RECURRING_EVENT.REPEAT_RULE",
  });

  const { control, getValues, setValue } =
    useFormContext<EventCreateEditFormValues>();
  const { defaultValues } = useFormState({ control });

  const startDateString = useWatch({ control, name: "startDate" });
  const recurrencePattern = useWatch({ control, name: "recurrencePattern" });

  const {
    dateOfMonth,
    monthAndDayNumber,
    weekdayName,
    weekNumber,
    startDayOfWeekCode,
    monthAndDateOfYear,
  } = useMemo(() => {
    const startDate = parse(startDateString, EVENT_DATE_FORMAT, new Date());
    const dateOfMonth = getDate(startDate);
    const monthAndDayNumber = format(startDate, "MMMM d");
    const weekdayName = format(startDate, "EEEE", { locale });
    const weekNumber = Math.floor((dateOfMonth - 1) / 7) + 1;

    const startDayOfWeek = getDay(startDate);
    const startDayOfWeekCode = Object.values(EventDayOfWeek).find(
      (_, i) => i === startDayOfWeek,
    );
    const monthAndDateOfYear = format(startDate, "MM-dd");

    return {
      dateOfMonth,
      monthAndDayNumber,
      weekdayName,
      weekNumber,
      startDayOfWeekCode,
      monthAndDateOfYear,
    };
  }, [locale, startDateString]);

  const options = [
    {
      label: t("DAILY"),
      value: RecurrencePatternOptionValues.Daily,
    },
    {
      label: t("WEEKLY_ON_DAY", { day: weekdayName }),
      value: RecurrencePatternOptionValues.Weekly,
    },
    {
      label: t("MONTHLY_ON_OCCURRENCE", {
        occurrence: t(`${weekNumber}_ORDINAL`),
        day: weekdayName,
      }),
      value: RecurrencePatternOptionValues.Monthly,
    },
    {
      label: t("YEARLY_ON_DAY", { day: monthAndDayNumber }),
      value: RecurrencePatternOptionValues.Yearly,
    },
    {
      label: t("WEEKDAYS"),
      value: RecurrencePatternOptionValues.Weekdays,
    },
    {
      label: t("CUSTOM"),
      value: RecurrencePatternOptionValues.Custom,
    },
  ];

  // This sets the initial selectedOption based on the recurrencePattern
  const [selectedOption, setSelectedOption] =
    useState<RecurrencePatternOptionValues>(
      getSelectedOption(
        recurrencePattern,
        startDayOfWeekCode as EventDayOfWeek,
        weekNumber,
        monthAndDateOfYear,
      ),
    );

  /**
   * When the default pattern is changed, update the recurrence pattern accordingly
   * @param value
   */
  const handleDefaultPatternChange = (value: RecurrencePatternOptionValues) => {
    // Get the current recurrenctPattern, reset the interval AND delete the rest of the fields
    recurrencePattern.interval = 1;
    recurrencePattern.endDate = recurrencePattern.endDate ?? MAX_DATE;
    delete recurrencePattern.multiDates;
    delete recurrencePattern.daysOfWeek;
    delete recurrencePattern.dateOfMonth;
    delete recurrencePattern.dayOfMonthOccurrence;
    delete recurrencePattern.dayOfMonth;
    delete recurrencePattern.monthAndDateOfYear;

    switch (value) {
      case RecurrencePatternOptionValues.Daily: {
        recurrencePattern.frequency = EnumEventRecurrencePatternFrequency.Daily;
        break;
      }
      case RecurrencePatternOptionValues.Weekly: {
        recurrencePattern.frequency =
          EnumEventRecurrencePatternFrequency.Weekly;
        recurrencePattern.daysOfWeek = [
          startDayOfWeekCode as unknown as EnumEventRecurrencePatternDaysOfWeek,
        ];
        break;
      }
      case RecurrencePatternOptionValues.Monthly: {
        recurrencePattern.frequency =
          EnumEventRecurrencePatternFrequency.Monthly;
        recurrencePattern.dayOfMonthOccurrence = weekNumber;
        recurrencePattern.dayOfMonth =
          startDayOfWeekCode as unknown as EnumEventRecurrencePatternDayOfMonth;
        break;
      }
      case RecurrencePatternOptionValues.Yearly: {
        recurrencePattern.frequency =
          EnumEventRecurrencePatternFrequency.Yearly;
        recurrencePattern.monthAndDateOfYear = monthAndDateOfYear;
        break;
      }
      case RecurrencePatternOptionValues.Weekdays: {
        recurrencePattern.frequency =
          EnumEventRecurrencePatternFrequency.Weekly;
        recurrencePattern.daysOfWeek = [
          EnumEventRecurrencePatternDaysOfWeek.Mo,
          EnumEventRecurrencePatternDaysOfWeek.Tu,
          EnumEventRecurrencePatternDaysOfWeek.We,
          EnumEventRecurrencePatternDaysOfWeek.Th,
          EnumEventRecurrencePatternDaysOfWeek.Fr,
        ];
        break;
      }
      case RecurrencePatternOptionValues.Custom: {
        recurrencePattern.frequency = EnumEventRecurrencePatternFrequency.Daily;
        break;
      }
    }

    setValue("recurrencePattern", recurrencePattern, {
      shouldDirty: true,
    });
    setSelectedOption(value);
  };

  /**
   * Interval options is the time span in days/weeks/months/years between each occurrence
   */
  const [intervalOptions] = useState<{ label: string; value: string }[]>(
    Array.from({ length: 52 }, (_, i) => ({
      label: (i + 1).toString(),
      value: (i + 1).toString(),
    })),
  );

  /**
   * Frequency options is the frequency of the recurrence pattern
   */
  const [frequencyOptions, setFrequencyOptions] = useState<
    { label: string; value: string }[]
  >([
    {
      label: t("DAY", { count: 1 }),
      value: EnumEventRecurrencePatternFrequency.Daily,
    },
    {
      label: t("WEEK", { count: 1 }),
      value: EnumEventRecurrencePatternFrequency.Weekly,
    },
    {
      label: t("MONTH", { count: 1 }),
      value: EnumEventRecurrencePatternFrequency.Monthly,
    },
    {
      label: t("YEAR", { count: 1 }),
      value: EnumEventRecurrencePatternFrequency.Yearly,
    },
  ]);
  const selectedInterval = useWatch({
    control,
    name: "recurrencePattern.interval",
  });
  useEffect(() => {
    setFrequencyOptions([
      {
        label: t("DAY", { count: selectedInterval ?? 1 }),
        value: EnumEventRecurrencePatternFrequency.Daily,
      },
      {
        label: t("WEEK", { count: selectedInterval ?? 1 }),
        value: EnumEventRecurrencePatternFrequency.Weekly,
      },
      {
        label: t("MONTH", { count: selectedInterval ?? 1 }),
        value: EnumEventRecurrencePatternFrequency.Monthly,
      },
      {
        label: t("YEAR", { count: selectedInterval ?? 1 }),
        value: EnumEventRecurrencePatternFrequency.Yearly,
      },
    ]);
  }, [t, selectedInterval]);

  /**
   * When the frequency is changed, we need to update the recurrence pattern accordingly
   * - For MONTHLY events, we need to update the two options for the drop-down
   * - The selected value would either set (dayOfMonthOccurrence and dayOfMonth) or (dateOfMonth)
   * @param value
   */
  const [monthlyOptions, setMonthlyOptions] = useState<
    {
      label: string;
      value: string;
      formValue: {
        dateOfMonth?: number;
        dayOfMonthOccurrence?: number;
        dayOfMonth?: EnumEventRecurrencePatternDayOfMonth;
      };
    }[]
  >([]);
  const updateMonthlyOptions = useCallback(() => {
    const options = [
      {
        label: t("MONTHLY_ON_DAY", { day: dateOfMonth }),
        value: "1",
        formValue: {
          dateOfMonth,
        },
      },
      {
        label: t("MONTHLY_ON_OCCURRENCE", {
          occurrence: t(`${weekNumber}_ORDINAL`),
          day: weekdayName,
        }),
        value: "2",
        formValue: {
          dayOfMonthOccurrence: weekNumber,
          dayOfMonth:
            startDayOfWeekCode as unknown as EnumEventRecurrencePatternDayOfMonth,
        },
      },
    ];
    // Add the "last of" option if the week number is 4 or higher
    if (weekNumber >= 4) {
      options.push({
        label: t("MONTHLY_ON_OCCURRENCE", {
          occurrence: t(`5_ORDINAL`),
          day: weekdayName,
        }),
        value: "3",
        formValue: {
          dayOfMonthOccurrence: 5,
          dayOfMonth:
            startDayOfWeekCode as unknown as EnumEventRecurrencePatternDayOfMonth,
        },
      });
    }
    setMonthlyOptions(options);
  }, [dateOfMonth, weekNumber, startDayOfWeekCode, weekdayName, t]);

  useEffect(() => {
    updateMonthlyOptions();
  }, [updateMonthlyOptions]);

  /**
   * When the monthly option is changed, we need to update the recurrence pattern accordingly
   * @param value
   */
  const [selectedMonthlyOption, setSelectedMonthlyOption] =
    useState<string>("1");
  const handleMonthlyOptionChange = useCallback(
    (value: string) => {
      const option = monthlyOptions.find((option) => option.value === value);
      if (option) {
        setSelectedMonthlyOption(value);
        const recurrencePattern = getValues("recurrencePattern");
        delete recurrencePattern.dayOfMonthOccurrence;
        delete recurrencePattern.dayOfMonth;
        delete recurrencePattern.dateOfMonth;
        setValue("recurrencePattern", recurrencePattern, {
          shouldDirty: true,
        });

        if ("dateOfMonth" in option.formValue) {
          setValue(
            "recurrencePattern.dateOfMonth",
            option.formValue.dateOfMonth,
            { shouldDirty: true },
          );
        } else {
          setValue(
            "recurrencePattern.dayOfMonthOccurrence",
            option.formValue.dayOfMonthOccurrence,
            { shouldDirty: true },
          );
          setValue(
            "recurrencePattern.dayOfMonth",
            option.formValue.dayOfMonth,
            {
              shouldDirty: true,
            },
          );
        }
      }
    },
    [getValues, setValue, monthlyOptions],
  );

  /**
   * For WEEKLY events, we need to keep track of which days of the week are selected
   * @param day
   */
  const handleDayOfWeekClick = useCallback(
    (day: EventDayOfWeek) => {
      let daysOfWeek = getValues("recurrencePattern.daysOfWeek") ?? [];
      if (
        daysOfWeek.includes(
          day as unknown as EnumEventRecurrencePatternDaysOfWeek,
        )
      ) {
        // Remove the day from the daysOfWeek
        daysOfWeek = daysOfWeek.filter(
          (d) => d !== (day as unknown as EnumEventRecurrencePatternDaysOfWeek),
        );
      } else {
        // Add the day of the week to the array (and sort the array into the correct order)
        daysOfWeek = _.sortBy(
          [
            ...daysOfWeek,
            day as unknown as EnumEventRecurrencePatternDaysOfWeek,
          ],
          (day) =>
            indexOf(
              Object.values(EventDayOfWeek),
              day as unknown as EventDayOfWeek,
            ),
        );
      }
      setValue("recurrencePattern.daysOfWeek", daysOfWeek, {
        shouldDirty: true,
      });
    },
    [getValues, setValue],
  );

  /**
   * When the frequency is changed, we need to update the recurrence pattern accordingly
   * @param value
   */
  const handleFrequencyChange = useCallback(
    (value: EnumEventRecurrencePatternFrequency) => {
      // Clear the recurrence pattern items that are will need to be set for the new frequency
      const recurrencePattern = getValues("recurrencePattern");
      delete recurrencePattern.multiDates;
      delete recurrencePattern.daysOfWeek;
      delete recurrencePattern.dateOfMonth;
      delete recurrencePattern.dayOfMonthOccurrence;
      delete recurrencePattern.dayOfMonth;
      delete recurrencePattern.monthAndDateOfYear;

      setValue("recurrencePattern", recurrencePattern, {
        shouldDirty: true,
      });

      // Set this separately to trigger the "watch" effect
      setValue("recurrencePattern.frequency", value, {
        shouldDirty: true,
      });

      // For daily events, we don't need to do anything extra here
      // For weekly events, we can select the day of the week of the startDate
      if (value === EnumEventRecurrencePatternFrequency.Weekly) {
        handleDayOfWeekClick(startDayOfWeekCode as EventDayOfWeek);
      }
      // For monthly events, we need to create the options for the dropdown
      else if (value === EnumEventRecurrencePatternFrequency.Monthly) {
        updateMonthlyOptions();
        setValue("recurrencePattern.dateOfMonth", dateOfMonth, {
          shouldDirty: true,
        });
        setSelectedMonthlyOption("1");
      }
      // For yearly events, we need to set the monthAndDateOfYear
      else if (value === EnumEventRecurrencePatternFrequency.Yearly) {
        setValue("recurrencePattern.monthAndDateOfYear", monthAndDateOfYear, {
          shouldDirty: true,
        });
      }
    },
    [
      getValues,
      setValue,
      handleDayOfWeekClick,
      updateMonthlyOptions,
      startDayOfWeekCode,
      dateOfMonth,
      monthAndDateOfYear,
    ],
  );

  /**
   * For monthly frequencies, we need to ensure the correct monthly pattern is selected
   */
  useEffect(() => {
    if (
      recurrencePattern.frequency ===
      EnumEventRecurrencePatternFrequency.Monthly
    ) {
      setSelectedMonthlyOption(
        (recurrencePattern.dateOfMonth ?? 0) > 0
          ? "1"
          : (recurrencePattern.dayOfMonthOccurrence ?? 0) < 5
            ? "2"
            : "3",
      );
    }
  }, [recurrencePattern]);

  /**
   * When the recurrence pattern is changed, we need to ensure that all of the fields are set correctly
   * - To do so, we call the method to handle the frequency change and set it to DAILY
   * - We also set the selected option to DAILY
   */
  useEffect(() => {
    if (defaultValues?.startDate !== startDateString) {
      handleFrequencyChange(EnumEventRecurrencePatternFrequency.Daily);
      setSelectedOption(RecurrencePatternOptionValues.Daily);
    }
  }, [defaultValues, startDateString, handleFrequencyChange, getValues]);

  return {
    options,
    selectedOption,
    intervalOptions,
    frequencyOptions,
    monthlyOptions,
    selectedFrequency: useWatch({
      control,
      name: "recurrencePattern.frequency",
    }),
    selectedMonthlyOption,
    handleDefaultPatternChange,
    handleFrequencyChange,
    handleMonthlyOptionChange,
    handleDayOfWeekClick,
  };
};

/**
 * Get the selected option based on the recurrence pattern
 * @param recurrencePattern - The recurrence pattern to check
 * @param startDayOfWeekCode - The start day of the week code
 * @param weekNumber - The week number
 * @param monthAndDateOfYear - The month and date of the year
 * @returns The selected option
 */
const getSelectedOption = (
  recurrencePattern: EventRecurrencePattern,
  startDayOfWeekCode: EventDayOfWeek,
  weekNumber: number,
  monthAndDateOfYear: string,
) =>
  isDaily(recurrencePattern)
    ? RecurrencePatternOptionValues.Daily
    : isWeeklyOnDayOfWeek(recurrencePattern, startDayOfWeekCode)
      ? RecurrencePatternOptionValues.Weekly
      : isMonthlyOnWeekAndDayOfWeek(
            recurrencePattern,
            weekNumber,
            startDayOfWeekCode,
          )
        ? RecurrencePatternOptionValues.Monthly
        : isYearlyOnDay(recurrencePattern, monthAndDateOfYear)
          ? RecurrencePatternOptionValues.Yearly
          : isWeekdays(recurrencePattern)
            ? RecurrencePatternOptionValues.Weekdays
            : RecurrencePatternOptionValues.Custom;

/**
 * Checks if the recurrence pattern is daily
 * - frequency is DAILY
 * - interval is 1
 * - daysOfWeek.length is 7 (every day)
 * @param recurrencePattern - The recurrence pattern to check
 * @returns True if the recurrence pattern is daily, false otherwise
 */
const isDaily = (recurrencePattern: EventRecurrencePattern) =>
  recurrencePattern.frequency === EnumEventRecurrencePatternFrequency.Daily &&
  recurrencePattern.interval === 1;

/**
 * Checks if the recurrence pattern is weekly on a specific day of the week
 * - frequency is WEEKLY
 * - interval is 1
 * - daysOfWeek.length is 1
 * - daysOfWeek[0] is the weekday code that matches the startDate weekday code
 * @param recurrencePattern - The recurrence pattern to check
 * @param weekdayCode - The weekday code to check
 */
const isWeeklyOnDayOfWeek = (
  recurrencePattern: EventRecurrencePattern,
  weekdayCode?: EventDayOfWeek,
) =>
  recurrencePattern.frequency === EnumEventRecurrencePatternFrequency.Weekly &&
  recurrencePattern.interval === 1 &&
  recurrencePattern.daysOfWeek?.length === 1 &&
  recurrencePattern.daysOfWeek?.[0] ===
    (weekdayCode as unknown as EnumEventRecurrencePatternDaysOfWeek);

/**
 * Checks if the recurrence pattern is monthly on a specific week and day of the week
 * - frequency is MONTHLY
 * - interval is 1
 * - dayOfMonthOccurrence is the week number of the month that matches the startDate week number
 * - dayOfMonth is the weekday code that matches the startDate weekday code
 * @param recurrencePattern - The recurrence pattern to check
 * @param weekNumber - The week number of the month to check
 * @param weekdayCode - The weekday code to check
 */
const isMonthlyOnWeekAndDayOfWeek = (
  recurrencePattern: EventRecurrencePattern,
  weekNumber?: number,
  weekdayCode?: EventDayOfWeek,
) =>
  recurrencePattern.frequency === EnumEventRecurrencePatternFrequency.Monthly &&
  recurrencePattern.interval === 1 &&
  recurrencePattern.dayOfMonthOccurrence === weekNumber &&
  recurrencePattern.dayOfMonth === weekdayCode;

/**
 * Checks if the recurrence pattern is yearly on a specific day
 * - frequency is YEARLY
 * - interval is 1
 * - monthAndDateOfYear is the month and date of the year to check
 */
const isYearlyOnDay = (
  recurrencePattern: EventRecurrencePattern,
  monthAndDateOfYear?: string,
) =>
  recurrencePattern.frequency === EnumEventRecurrencePatternFrequency.Yearly &&
  recurrencePattern.interval === 1 &&
  recurrencePattern.monthAndDateOfYear === monthAndDateOfYear;

/**
 * Checks if the recurrence pattern is weekdays
 * - frequency is DAILY
 * - interval is 1
 * - daysOfWeek.length is 5 (every weekday)
 */
const isWeekdays = (recurrencePattern: EventRecurrencePattern) =>
  recurrencePattern.frequency === EnumEventRecurrencePatternFrequency.Weekly &&
  recurrencePattern.interval === 1 &&
  recurrencePattern.daysOfWeek?.length === 5 &&
  recurrencePattern.daysOfWeek.every(
    (dayOfWeek) => ["MO", "TU", "WE", "TH", "FR"].indexOf(dayOfWeek ?? "") > -1,
  );
