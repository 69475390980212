import { cva } from "styled-system/css";

export const progressIndicatorLineRecipe = cva({
  base: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: "full",
    zIndex: 0,
    transition: "all 0.2s ease",
  },
  variants: {
    color: {
      complete: {
        bgColor: "bg.success.dark",
      },
      incomplete: {
        bgColor: "bg.accent.blue.dark",
      },
    },
  },
  defaultVariants: {
    color: "incomplete",
  },
});

export const progressIndicatorDotRecipe = cva({
  base: {
    bgColor: "#D9D9D9",
    borderColor: "transparent",
    borderWidth: 2,
    borderRadius: "full",
    zIndex: 1,
    transition: "all 0.4s ease",
  },
  compoundVariants: [
    {
      isPastStep: true,
      isComplete: true,
      isThisStep: false,
      css: {
        bgColor: "bg.success.dark",
        borderColor: "border.success",
      },
    },
    {
      isPastStep: true,
      isComplete: false,
      isThisStep: false,
      css: {
        bgColor: "bg.access.blue.dark",
        borderColor: "border.accent.blue",
      },
    },
    {
      isPastStep: false,
      isComplete: false,
      isThisStep: true,
      css: {
        bgColor: "#FFF",
        borderColor: "border.accent.blue",
      },
    },
    {
      isPastStep: false,
      isComplete: true,
      isThisStep: true,
      css: {
        bgColor: "#FFF",
        borderColor: "border.success",
      },
    },
  ],
  defaultVariants: {
    isPastStep: false,
    isComplete: false,
    isThisStep: false,
  },
});
