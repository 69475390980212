import { useFormContext } from "react-hook-form";
import { EventCreateEditFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useEffect, useState } from "react";
import {
  EnumEventRecurrencePatternFrequency,
  EnumEventType,
} from "gql/generated";
import { MAX_DATE } from "shared/utils/eventHelpers";

export const useEventSchedule = () => {
  const { getValues, setValue } = useFormContext<EventCreateEditFormValues>();

  // Track the initial values of the objects so we can determine if they have changed
  const [initialType, setInitialType] = useState<string>(EnumEventType.Single);
  const [initialRecurrencePattern, setInitialRecurrencePattern] =
    useState<string>("");
  const [initialSchedules, setInitialSchedules] = useState<string>("");
  useEffect(() => {
    setInitialType(getValues("type"));
    setInitialRecurrencePattern(JSON.stringify(getValues("recurrencePattern")));
    setInitialSchedules(JSON.stringify(getValues("schedules")));
  }, [getValues]);

  /**
   * When we switch between event types, we need to reset the schedule to a single item
   * - This is because MULTI events get a schedule for each date and each date can only
   *   have one time slot.
   */
  const resetScheduleToSingleItem = (isInitial: boolean) => {
    if (isInitial) {
      // Reset to the initial schedules
      setValue("schedules", JSON.parse(initialSchedules), {
        shouldDirty: true,
      });
    } else {
      // Reset to a single array schedule
      const schedule = getValues("schedules.0") ?? {
        allDay: false,
        timeSlots: [{ from: "", to: "", endsNextDay: false, note: "" }],
      };
      setValue(
        "schedules",
        [
          {
            allDay: schedule.allDay,
            timeSlots: schedule.timeSlots
              ? schedule.timeSlots.slice(0, 1)
              : [{ from: "", to: "", endsNextDay: false, note: "" }],
          },
        ],
        { shouldDirty: true },
      );
    }
  };

  /**
   * When we switch between event types, we need to set the recurrencePattern to the correct default value
   * - Update recurrencePattern
   * - Update the schedules array to be length 1 with a single time slot
   * - Update the endDate
   */
  const handleEventTypeChange = (eventType: EnumEventType) => {
    setValue("type", eventType, {
      shouldDirty: true,
    });

    switch (eventType) {
      case EnumEventType.Single:
        setValue(
          "recurrencePattern",
          initialType === eventType
            ? JSON.parse(initialRecurrencePattern)
            : {
                frequency: EnumEventRecurrencePatternFrequency.Once,
              },
          { shouldDirty: true },
        );
        resetScheduleToSingleItem(initialType === eventType);
        // For single events, the endDate will be the same as the startDate
        setValue("endDate", getValues("startDate"), {
          shouldDirty: true,
        });
        break;
      case EnumEventType.Multi: {
        // Update the recurrencePattern
        setValue(
          "recurrencePattern",
          initialType === eventType
            ? JSON.parse(initialRecurrencePattern)
            : {
                frequency: EnumEventRecurrencePatternFrequency.Once,
                multiDates: [getValues("startDate")],
                endDate: getValues("startDate"),
              },
          { shouldDirty: true },
        );
        // Update the schedules array (ensure there is only one time slot)
        resetScheduleToSingleItem(initialType === eventType);
        // The end date is stored in the recurrentPattern for type MULTI
        setValue("endDate", "", { shouldDirty: true });
        break;
      }
      case EnumEventType.Recurring:
        setValue(
          "recurrencePattern",
          initialType === eventType
            ? JSON.parse(initialRecurrencePattern)
            : {
                frequency: EnumEventRecurrencePatternFrequency.Daily,
                interval: 1,
                endDate: MAX_DATE,
              },
          { shouldDirty: true },
        );
        resetScheduleToSingleItem(initialType === eventType);
        // The end date is stored in the recurrentPattern for type RECURRING
        setValue("endDate", "", { shouldDirty: true });
        break;
    }
  };

  return {
    handleEventTypeChange,
  };
};
