import { styled } from "styled-system/jsx";

export const FilterButton = styled("button", {
  base: {
    minW: "max-content",
    cursor: "pointer",
    borderRadius: "full",
    padding: "10px 20px",
    border: "1px solid token(colors.border.light)",
    boxShadow: "sm",
    color: "text.dark",
    fontFamily: "body",
    fontSize: "2xs",
    lineHeight: "2xs",
    fontWeight: "semiBold",
    letterSpacing: "wide",
    transitionDuration: "normal",
    transitionProperty: "background, border",
    transitionTimingFunction: "default",
    position: "relative",
    /** Use a pseudo element for the border hover because:
     * - boxShadow is already being used
     * - border would cause layout shift
     * - outline is cut off when padding is added by a parent component
     */
    "&::after": {
      content: "''",
      position: "absolute",
      inset: 0,
      borderRadius: "inherit",
      border: "1px solid transparent",
      transitionProperty: "border",
      transitionDuration: "normal",
      transitionTimingFunction: "default",
      pointerEvents: "none", // Ensure it doesn't interfere with clicks
    },
    _hover: {
      "&::after": {
        borderColor: "border.mid",
      },
      borderColor: "border.mid",
    },
    "&[data-selected=true]": {
      color: "white",
      bg: "bg.darkest",
      border: "1px solid token(colors.border.dark)",
      _hover: {
        bg: "bg.mid",
        borderColor: "bg.mid",
        "&::after": {
          borderColor: "bg.mid",
        },
      },
    },
  },
});
