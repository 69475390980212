import { usePostHog as usePostHogHook } from "posthog-js/react";

export enum PostHogCustomEvent {
  /**
   * The following three events (`details-click`, `expanded-details-click`, and `pageview`)
   * are used to track all "IMPRESSIONS" clicks from the Operator, Events and Booker widgets.
   * These events will contribute to the total "IMPRESSIONS" metric on the Stats dashboard.
   */
  DetailsClick = "details-click", // Use when tracking clicks to open Details modal
  ExpandedDetailsClick = "expanded-details-click", // Use when tracking clicks on "See Details" or "See More" button that leads to external website
  ExpandedDetailsPageview = "expanded-details-pageview", // Use when tracking pageviews on external websites

  /**
   * The following event (`outbound-click`) is used to track
   * all "EXTERNAL" clicks from the Operator, Events and Booker widgets. These events
   * will contribute to the total "Clicks to External Sites" metric on the Stats dashboard.
   */
  OutboundClick = "outbound-click", // Use when tracking clicks to external social media sites (website, Facebook, etc.)

  /**
   * The following events track more specific events from the Operator, Events and Booker widgets
   * There events are not used for tracking metrics yet but can be organized and used in the future
   */
  OpenBookNowLink = "open-book-now-link", // Use when tracking clicks on "Book Now" link
  CloseOperatorDetails = "close-operator-details", // Use when tracking clicks on "Close" button in Details modal
  FilterTagAdded = "filter-tag-added", // Use when tracking clicks on filter tags that add the filters
  FilterTagRemoved = "filter-tag-removed", // Use when tracking clicks on filter tags that remove the filters
  ScrollViewMoreTags = "scroll-view-more-tags", // Use when tracking scrolls on "View More" button in Filter Carousel
  ScrollViewPreviousTags = "scroll-view-previous-tags", // Use when tracking scrolls on "View Previous" button in Filter Carousel
  ScrollRecurringEventsUp = "scroll-recurring-events-up", // Use when tracking up scrolls on "Recurring" section of Events widget
  ScrollRecurringEventsDown = "scroll-recurring-events-down", // Use when tracking down scrolls on "Recurring" section of Events widget
  ScrollOperatorsLeft = "scroll-operators-left", // Use when tracking left scrolls on Operators widget
  ScrollOperatorsRight = "scroll-operators-right", // Use when tracking right scrolls on Operators widget
  ScrollEventsLeft = "scroll-events-left", // Use when tracking left scrolls on Events widget
  ScrollEventsRight = "scroll-events-right", // Use when tracking right scrolls on Events widget
  /** -- Booker Widget Events -- */
  BookerLoaded = "booker-loaded", // Use when tracking when a booker widget is loaded
  ProductAddedToCart = "product-added-to-cart", // Use when tracking when a product is added to the cart
  ProductRemovedFromCart = "product-removed-from-cart", // Use when tracking when a product is removed from the cart
  ProductUpdatedInCart = "product-updated-in-cart", // Use when tracking when a product is updated in the cart
  PromoCodeApplied = "promo-code-applied", // Use when tracking when a promo code is applied
  PurchaseCompleted = "purchase-completed", // Use when tracking when a purchase is completed
  AmenitiesViewed = "amenities-viewed", // Use when tracking when amenities are viewed
  DatesSelected = "dates-selected", // Use when tracking when dates are selected
  GuestsSelected = "guests-selected", // Use when tracking when guests are selected
  PaymentMethodAdded = "payment-method-added", // Use when tracking when a payment method is added
  PoliciesAccepted = "policies-accepted", // Use when tracking when policies are accepted
  CustomerDetailsAdded = "customer-details-added", // Use when tracking when customer details are added
}

export type PostHogCustomProperty =
  | "appContext"
  | "organizationId"
  | "operatorEntityId"
  | "widgetId"
  | "eventId"
  | "bookNowLink"
  | "selectedOperatorId"
  | "selectedFilterTagId"
  | "bookerId"
  | "productId"
  | "promoCode"
  | "orderId"
  | "instanceId";

/**
 * Use this hook to track PostHog events with type safety for event type
 * Must be used within a PostHogProvider context
 * @returns A function to track PostHog events with type safety for event type
 */
export const usePostHog = () => {
  const posthog = usePostHogHook();

  const initPostHog = () => {
    posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
      api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
    });
  };

  /**
   * Register a PostHog property with type safety for property type
   * @param posthog - The PostHog instance
   * @param properties - The properties to register
   */
  const registerPostHog = (
    properties: Partial<Record<PostHogCustomProperty, string | number>>,
  ) => {
    posthog.register(properties);
  };

  /**
   * Track a PostHog event with type safety for event type
   * @param event - The event to track
   * @param properties - The properties to track with the event
   */
  const trackPostHogEvent = (
    event: PostHogCustomEvent,
    properties?: Partial<Record<PostHogCustomProperty, string | number>>,
  ) => {
    posthog.capture(event, properties);
  };

  return { posthog, initPostHog, registerPostHog, trackPostHogEvent };
};
