import { Button, Card, Icon, Link, Text } from "@ttc3k/trekker";
import { ArrowRight, OpenNewWindow } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Box, BoxProps, styled } from "styled-system/jsx";
import { operatorCardRecipe, OperatorCardRecipeProps } from "./recipe";
import { OperatorWithOverrides } from "gql/generated";
import {
  getFormattedOperatorDetailsLink,
  getMultilingualString,
  isLandscape,
} from "shared/utils";
import { CloudinaryImage } from "shared/components";
import { limitFill, scale } from "@cloudinary/url-gen/actions/resize";
import { css } from "styled-system/css";
import { autoGravity } from "@cloudinary/transformation-builder-sdk/qualifiers/gravity";
import { MultilingualLangKey } from "shared/types";
import { OperatorsWidgetDetailsDisplayMode } from "widgets/operators/OperatorsWidget";
import { useOperatorsWidgetContext } from "widgets/operators/components/OperatorsContext";
import { usePostHog, PostHogCustomEvent } from "shared/hooks/usePostHog";

const StyledLink = styled(Link, {
  base: {
    display: "flex",
    gap: "50",
    color: "text.dark",
    fontWeight: "semiBold",
    transitionDuration: "normal",
    transitionProperty: "color",
    transitionTimingFunction: "default",
    _hover: {
      color: "text.accent.blue.mid",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

type OperatorCardProps = OperatorCardRecipeProps & {
  operator: Partial<OperatorWithOverrides>;
  onClick?: (operatorId: string) => void;
  viewOnly?: boolean;
  cardSurfaceProps?: BoxProps;
  lang?: MultilingualLangKey;
  operatorDetailsDisplayMode?: OperatorsWidgetDetailsDisplayMode;
  operatorDetailsLink?: string;
};

export const OperatorWidgetCard = ({
  operator,
  onClick,
  orientation,
  viewOnly = false,
  cardSurfaceProps,
  lang = "en",
  operatorDetailsDisplayMode,
  operatorDetailsLink,
}: OperatorCardProps) => {
  const classes = operatorCardRecipe({ orientation });
  const { t } = useTranslation();
  const { trackPostHogEvent } = usePostHog();
  const { widgetId, organizationId } = useOperatorsWidgetContext();
  function handleDetailsClick() {
    onClick!(operator._entityId);
    trackPostHogEvent(
      operatorDetailsDisplayMode === "slideout"
        ? PostHogCustomEvent.DetailsClick
        : PostHogCustomEvent.ExpandedDetailsClick,
      {
        organizationId: organizationId,
        operatorEntityId: operator?._entityId,
        widgetId: widgetId,
      },
    );
  }

  /* TODO: when Booker is added, handle click for Booker vs. Book now link */
  const handleBookNowClick = () => {
    if (!operator.contact?.links?.bookNow) return;
    trackPostHogEvent(PostHogCustomEvent.OpenBookNowLink, {
      bookNowLink: operator.contact.links.bookNow,
    });
    window.open(operator.contact.links.bookNow);
  };

  const previewImage =
    operator.overrides?.images && operator.overrides.images.length > 0
      ? operator.overrides.images[0]
      : operator.images?.[0];

  const previewImageIsLandscape = isLandscape(previewImage);
  const previewImageHasText = previewImage?.detectedObjects?.hasText;

  /**
   * If the image has text, we scale it to the width or height (the widget is a guess as the card grows in width based on screen size)
   * For non-text images, we use the limitFill to have Cloudinary pick the "best" part of the image to display
   * @returns
   */
  const getHeroResize = () =>
    previewImageHasText
      ? previewImageIsLandscape
        ? scale().height(200)
        : scale().width(500)
      : previewImageIsLandscape
        ? limitFill().height(200).width(500).gravity(autoGravity())
        : limitFill().width(500).height(200).gravity(autoGravity());

  const shortDescription = operator.overrides?.shortDescription
    ? getMultilingualString(operator.overrides.shortDescription, lang)
    : getMultilingualString(operator.shortDescription ?? {}, lang);

  const isPortraitOrientation = orientation === "portrait";

  // The Card.Hero has a lot of unneeded css properties when the orientation is
  // horizontal they are not needed so use a Box in this case
  const HeroContainer = isPortraitOrientation ? Card.Hero : Box;

  return (
    <Card.Surface
      part={"card__surface"}
      key={operator._entityId}
      className={classes.surface}
      {...cardSurfaceProps}
    >
      <HeroContainer part={"card__hero"} className={classes.hero}>
        <CloudinaryImage
          includeBgBlurHash={true}
          image={previewImage}
          resize={getHeroResize()}
          imageStyleProps={{
            className: css({
              aspectRatio: "unset",
              objectFit: previewImageHasText ? "contain" : "cover",
              h: isPortraitOrientation ? "200px" : "262px",
              maxH: isPortraitOrientation ? "200px" : "262px",
              width: isPortraitOrientation ? "full" : "240px",
            }),
          }}
          parts={{ container: "card__image-container", image: "card__image" }}
        />
      </HeroContainer>
      <Card.Body className={classes.body}>
        <Box className={classes.textContent}>
          <Text visual={"bodySemiBold"} lineClamp={2} part={"card__title"}>
            {getMultilingualString(operator.name ?? {}, lang)}
          </Text>
          <Text
            visual={"smallMedium"}
            lineClamp={isPortraitOrientation ? 2 : 3}
            color={"text.light"}
            part={"card__description"}
          >
            {shortDescription}
          </Text>
        </Box>
        <Card.BodyContent gap={"200"} minH={"38px"}>
          <StyledLink
            onClick={!viewOnly ? handleDetailsClick : undefined}
            href={
              operatorDetailsDisplayMode === "link"
                ? getFormattedOperatorDetailsLink(
                    operator,
                    operatorDetailsLink!,
                  )
                : undefined
            }
            target={
              operatorDetailsDisplayMode === "link" ? "_blank" : undefined
            }
            part={"card__btn-details"}
          >
            {t("widgets:OPERATOR.CARD.SEE_DETAILS")}
            <Icon
              Element={
                operatorDetailsDisplayMode === "link"
                  ? OpenNewWindow
                  : ArrowRight
              }
            ></Icon>
          </StyledLink>
          {operator.contact?.links?.bookNow && (
            <Button
              visual={"accent.blue"}
              onClick={!viewOnly ? handleBookNowClick : undefined}
              part={"card__btn-book"}
            >
              {t("widgets:OPERATOR.CARD.BOOK_NOW")}
            </Button>
          )}
        </Card.BodyContent>
      </Card.Body>
    </Card.Surface>
  );
};
