import { useTranslation } from "react-i18next";
import { ControlledInputProps, FlyupEditingModal } from "shared/components";
import { HostLocationSelection } from "./HostLocationSelection";
import { FieldValues, Path } from "react-hook-form";
import { EventLocationFormValues } from "../EventCreateEditModal/useEventCreateEditForm";
import { useState } from "react";
import { LocationListData } from "apps/events/types/LocationListData";

type EventLocationEditModalProps<T extends FieldValues> = {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  isHost: boolean;
  index: number;
  allowCustom: boolean;
  allLocations: LocationListData[];
  locationCopy: EventLocationFormValues | null;
  formFieldRoot: Path<T>;
  eventLinkControlledInputProps: ControlledInputProps;
  emailControlledInputProps: ControlledInputProps;
  phoneControlledInputProps: ControlledInputProps;
};

export const EventLocationEditModal = <T extends FieldValues>({
  isOpen,
  onClose,
  onDone,
  isHost,
  index,
  allowCustom,
  allLocations,
  locationCopy,
  formFieldRoot,
  eventLinkControlledInputProps,
  emailControlledInputProps,
  phoneControlledInputProps,
}: EventLocationEditModalProps<T>) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_HOST.LOCATION_SELECTION",
  });

  const [isEmpty, setIsEmpty] = useState(true);

  return (
    <FlyupEditingModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        locationCopy?.venue || locationCopy?.contact?.address
          ? t("EDIT_LOCATION")
          : t("ADD_LOCATION")
      }
      cancelButtonProps={{
        visual: "primary",
        mode: "light",
        type: "button",
        onClick: onClose,
        children: t("CANCEL"),
      }}
      saveButtonProps={{
        visual: "accent.blue",
        type: "button",
        onClick: onDone,
        children: t("DONE"),
        disabled: isEmpty,
      }}
    >
      <HostLocationSelection
        isHost={isHost}
        index={index}
        allowCustom={allowCustom}
        allLocations={allLocations}
        formFieldRoot={formFieldRoot}
        eventLinkControlledInputProps={eventLinkControlledInputProps}
        emailControlledInputProps={emailControlledInputProps}
        phoneControlledInputProps={phoneControlledInputProps}
        setIsEmpty={setIsEmpty}
      />
    </FlyupEditingModal>
  );
};
