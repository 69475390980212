import { scale } from "@cloudinary/transformation-builder-sdk/actions/resize";
import { Button, Icon, SimpleDialog, Tag, Text, Tooltip } from "@ttc3k/trekker";
import {
  ImageQualityRating,
  EnumDKeyAssetType,
  type AssetFile,
  type AssetImage,
} from "gql/generated";
import {
  ChatLines,
  ChatLinesSolid,
  Check,
  Download,
  MediaImage,
  Trash,
  WarningTriangle,
} from "iconoir-react";
import { useTranslation } from "react-i18next";
import { CloudinaryImage } from "shared/components/atoms";
import { Flex } from "styled-system/jsx";
import { ImageMediaCaptionModal } from "./ImageMediaCaptionModal";
import { useImageMedia } from "./useImageMedia";
import { css } from "styled-system/css";
import { useEffect, useState } from "react";
import { isLandscape } from "shared/utils/isLandscape";

export type ImageMediaProps = {
  image: Partial<AssetImage>;
  onDelete?: (image: Partial<AssetImage>) => void;
  onCaptionUpdated: (asset: Partial<AssetFile> | Partial<AssetImage>) => void;
  onModalToggle?: (isOpen: boolean) => void;
  showTag?: boolean;
};

export const ImageMedia = ({
  image,
  onDelete,
  onCaptionUpdated,
  showTag = false,
  onModalToggle,
}: ImageMediaProps) => {
  const { t } = useTranslation();

  const { caption, isCaptionOpen, handleCaptionClick, handleDownload } =
    useImageMedia({ image });

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  useEffect(() => {
    onModalToggle?.(isCaptionOpen || isDeleteConfirmOpen);
  }, [isCaptionOpen, isDeleteConfirmOpen, onModalToggle]);

  if (!image._id) {
    return <Icon Element={MediaImage} size="lg" color="icon.icon.light" />;
  }

  return (
    <>
      <Flex flexDir="column" width="full" gap="100" position="relative">
        {showTag && (
          <Tag
            visual="accent.blue"
            shape="rounded"
            Icon={Check}
            position="absolute"
            top="12px"
            left="12px"
            pointerEvents="none"
          >
            {t("core:IMAGE.FEATURED")}
          </Tag>
        )}
        <CloudinaryImage
          imageStyleProps={{
            className: css({
              aspectRatio: "square",
              borderRadius: "100",
              objectFit: "cover",
              maxH: "350px",
              width: "full",
              maxW: "350px",
              _hover: {
                backgroundColor: "bg.darkest",
                objectFit: "contain",
              },
            }),
          }}
          resize={isLandscape(image) ? scale().height(350) : scale().width(350)}
          image={image}
          includeBgBlurHash={true}
        />
        <Flex
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          gap="250"
        >
          {image.qualityScore === ImageQualityRating.Low ? (
            <Flex display="flex" flexDir="row" alignItems="center" gap="100">
              <Icon Element={WarningTriangle} color="icon.warning.mid" />
              <Text color="text.warning.mid" visual="smallSemiBold">
                {t("core:IMAGE.LOW_QUALITY")}
              </Text>
            </Flex>
          ) : (
            <div></div>
          )}

          <Flex
            flexDir="row"
            justifyContent="flex-end"
            alignItems="center"
            gap="75"
          >
            <Tooltip
              content={t("core:MEDIA.ICONS.CAPTION")}
              positioning={{ placement: "top" }}
            >
              <Button
                visual="unstyled"
                borderRadius="full"
                onClick={handleCaptionClick}
              >
                <Icon Element={caption ? ChatLinesSolid : ChatLines} />
              </Button>
            </Tooltip>
            <Tooltip
              content={t("core:MEDIA.ICONS.DOWNLOAD")}
              positioning={{ placement: "top" }}
            >
              <Button
                visual="unstyled"
                borderRadius="full"
                onClick={handleDownload}
              >
                <Icon Element={Download} />
              </Button>
            </Tooltip>
            <Tooltip
              content={t("core:MEDIA.ICONS.DELETE")}
              positioning={{ placement: "top" }}
            >
              <Button
                visual="unstyled"
                borderRadius="full"
                onClick={() => setIsDeleteConfirmOpen(true)}
              >
                <Icon Element={Trash} />
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>

      <ImageMediaCaptionModal
        isOpen={isCaptionOpen}
        assetId={image._id}
        assetType={EnumDKeyAssetType.AssetImage}
        caption={caption}
        onClose={handleCaptionClick}
        onCaptionUpdated={onCaptionUpdated}
      />

      <SimpleDialog
        open={isDeleteConfirmOpen}
        title={t("core:CONFIRM.TITLE")}
        message={t("core:MEDIA.CONFIRM_DELETE")}
        cancelButtonCopy={t("core:CONFIRM.NO")}
        onCancelClick={() => setIsDeleteConfirmOpen(false)}
        confirmButtonCopy={t("core:CONFIRM.YES")}
        confirmButtonVisual="destructive"
        onConfirmClick={() => {
          setIsDeleteConfirmOpen(false);
          onDelete?.(image);
        }}
        size="md"
      />
    </>
  );
};
