import { Locale } from "date-fns";
import { es } from "date-fns/locale";
import { fr } from "date-fns/locale";
import { i18nLanguage } from "i18n";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useMemo,
  SetStateAction,
} from "react";
import { MultilingualLangKey } from "shared/types";

export type EventSubmitWidgetContextValues = {
  organizationId: string;
  lang: MultilingualLangKey;
  locale: Locale | undefined;
  isCreateFlowOpen: boolean;
  setIsCreateFlowOpen: Dispatch<SetStateAction<boolean>>;
};

export type EventSubmitWidgetContextProps = Omit<
  EventSubmitWidgetContextValues,
  "locale"
> &
  PropsWithChildren;

const context = createContext<EventSubmitWidgetContextValues | null>(null);

export const EventSubmitWidgetContextProvider = ({
  organizationId,
  lang,
  isCreateFlowOpen,
  setIsCreateFlowOpen,
  children,
}: EventSubmitWidgetContextProps) => {
  const contextValue = useMemo(
    () => ({
      organizationId,
      lang,
      locale:
        lang === i18nLanguage.fr
          ? fr
          : lang === i18nLanguage.es
            ? es
            : undefined,
      isCreateFlowOpen,
      setIsCreateFlowOpen,
    }),
    [organizationId, lang, isCreateFlowOpen, setIsCreateFlowOpen],
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const useEventSubmitWidgetContext = () => {
  const eventSubmitContext = useContext(context);

  if (!eventSubmitContext) {
    throw new Error(
      "useEventSubmitWidgetContext must be used within a EventSubmitWidgetContextProvider",
    );
  }

  return eventSubmitContext;
};
