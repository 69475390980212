import { Address } from "gql/generated.ts";

export const getAddressString = (address: Address) =>
  [
    address.line1,
    address.line2,
    address.city,
    `${address.subdivision ? `${address.subdivision} ` : ""}${address.postalCode}`,
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
