import { AssetImage } from "gql/generated";

/**
 * Return true if the image is landscape (width > height)
 * - If the width is undefined, default to 1 which should default to landscape
 * @param image
 * @returns
 */
export const isLandscape = (image?: Partial<AssetImage> | null) =>
  (image?.width || 1) > (image?.height || 0);
