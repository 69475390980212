import {
  EnumEventType,
  EventWithOccurrences,
  OrganizationsByIdsQuery,
  useOrganizationsByIdsLazyQuery,
} from "gql/generated";
import { useMemo, useEffect, useState } from "react";
import { Event } from "gql/generated";
import { getRecurrencePatternDates } from "shared/utils";

type EventPreviewProps = {
  defaultOrganizationId?: string;
  event: Event;
  mode: "approve" | "edit" | "create";
};

export const useEventPreview = ({
  defaultOrganizationId,
  event,
  mode,
}: EventPreviewProps) => {
  // We need to fecth the organizations for the event
  const [getOrganizations, { loading: organizationsLoading }] =
    useOrganizationsByIdsLazyQuery();

  /**
   * When the event changes, we need to fetch the organizations for the event
   */
  const [organizations, setOrganizations] = useState<
    OrganizationsByIdsQuery["organizationsByIds"]
  >([]);
  useEffect(() => {
    if (!event || !event._id) return;

    const organizationIds = event?.access?.organizations.map(
      (organization) => organization?._entityId,
    ) ?? [defaultOrganizationId];
    if (organizationIds.length > 0) {
      getOrganizations({
        variables: {
          ids: organizationIds,
        },
        onCompleted: (data) => {
          setOrganizations(data.organizationsByIds);
        },
      });
    } else {
      setOrganizations([]);
    }
  }, [defaultOrganizationId, event, getOrganizations]);

  /**
   * When the event changes, we need to get the first 5 occurrences of the event
   */
  const eventWithOccurrences = useMemo(() => {
    if (!event || !event._id) return;

    const obj = { ...event } as EventWithOccurrences;
    const eventType = obj.type;
    switch (eventType) {
      case EnumEventType.Single:
        obj.occurrences = [obj.startDate];
        break;
      case EnumEventType.Multi:
        obj.occurrences = obj.recurrencePattern?.multiDates ?? [];
        break;
      case EnumEventType.Recurring: {
        const skipDates = obj.recurrencePattern?.skipDates ?? [];
        obj.occurrences = getRecurrencePatternDates(
          obj.recurrencePattern,
          obj.startDate,
        )
          .filter((date) => !skipDates.includes(date))
          .slice(0, 5);
        break;
      }
    }
    return obj;
  }, [event]);

  /**
   * When the mode changes, we need to update the content box and carousel box props
   */
  const { contentBoxProps, carouselBoxProps } = useMemo(() => {
    if (mode !== "approve") {
      return {
        contentBoxProps: {
          border: "1px solid",
          borderColor: "border.light",
          borderRadius: "200",
          overflow: "hidden",
        },
        carouselBoxProps: {
          position: "static",
        },
      };
    }
    return {
      contentBoxProps: {},
      carouselBoxProps: {},
    };
  }, [mode]);

  return {
    eventWithOccurrences,
    organizations,
    organizationsLoading,
    contentBoxProps,
    carouselBoxProps,
  };
};
