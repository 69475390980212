import { UploadApiResponse } from "cloudinary";

type UploadWithProgressProps = {
  url: string;
  formData: FormData;
  onProgress: (bytesUploaded: number) => void;
};

export const uploadWithProgress = ({
  url,
  formData,
  onProgress,
}: UploadWithProgressProps): Promise<UploadApiResponse> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Configure the request
    xhr.open("POST", url);

    // Set up a progress event listener on the upload object
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded);
      }
    });

    // Set up a load event listener to detect when the upload is complete
    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        const response = xhr.response;
        if (
          response &&
          typeof response === "string" &&
          response.startsWith("{")
        ) {
          resolve(JSON.parse(response));
        }
        reject();
      } else {
        reject();
      }
    });

    // Handle errors
    xhr.addEventListener("error", () => reject());

    // Send the file
    xhr.send(formData);
  });
