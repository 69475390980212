import { Button, ButtonProps, Heading, Tag, Text } from "@ttc3k/trekker";
import { Flex } from "styled-system/jsx";

type EventStepHeaderProps = {
  step: number;
  title: string;
  description: string;
  ctaButtonProps?: ButtonProps;
};

export const EventStepHeader = ({
  step,
  title,
  description,
  ctaButtonProps,
}: EventStepHeaderProps) => {
  return (
    <Flex flexDir={"column"} gap={"200"}>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        gap="100"
        h="42px"
      >
        <Flex flexDir="row" alignItems="center" gap="150">
          <Tag visual="primary" w="24px" h="24px">
            {step}
          </Tag>
          <Heading size="h5">{title}</Heading>
        </Flex>

        {ctaButtonProps && (
          // Set the button's type to "button" to avoid triggering form submission
          // when the button is clicked
          <Button
            visual="primary"
            mode="light"
            {...ctaButtonProps}
            type="button"
          />
        )}
      </Flex>
      <Text visual="bodyRegular" color="text.mid">
        {description}
      </Text>
    </Flex>
  );
};
