import { useAuth0 } from "@auth0/auth0-react";

const url = `${import.meta.env.VITE_API_SERVER_URL}/cloudinary-upload-signature`;

export const useCloudinaryUploadSignature = (isPublicUse: boolean) => {
  const { getAccessTokenSilently } = useAuth0();

  const getCloudinaryUploadSignature = async (): Promise<{
    timestamp?: number;
    signature?: string;
  }> => {
    const token = isPublicUse
      ? "PUBLIC_WIDGET_USER"
      : await getAccessTokenSilently({
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          },
        });

    return new Promise((resolve) => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          const data = await response.json();
          resolve(data);
        })
        .catch(() => resolve({}));
    });
  };

  return { getCloudinaryUploadSignature };
};
