import {
  Icon,
  Modal,
  SimpleDialog,
  Spinner,
  useToastContext,
} from "@ttc3k/trekker";
import { EmptyState } from "shared/components";
import { Box, Center } from "styled-system/jsx";
import { Grid } from "styled-system/jsx";
import { EventModalHeader } from "../EventModalHeader/EventModalHeader";
import { FormProvider } from "react-hook-form";
import { EventCreateEditModalFooter } from "../EventCreateEditModalFooter/EventCreateEditModalFooter";
import { EventPreview } from "../EventPreview/EventPreview";
import { EventDetails } from "../EventDetails/EventDetails";
import { EventHostAndLocations } from "../EventHostAndLocations/EventHostAndLocations";
import { EventSchedule } from "../EventSchedule/EventSchedule";
import { EventPhotos } from "../EventPhotos/EventPhotos";
import { useEventCreateEditForm } from "./useEventCreateEditForm";
import { useEventCreateEditContext } from "../EventCreateEditContext/EventCreateEditContext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventByIdQuery } from "gql/generated";
import { getMultilingualString } from "shared/utils";
import { Xmark } from "iconoir-react";

export const EventCreateEditModalContent = () => {
  const { t } = useTranslation();
  const { toastFactory } = useToastContext();

  // Grab the required values from the context
  const {
    defaultOrganizationId,
    userEmailAddress,
    event,
    isOpen,
    mode,
    isConfirmationModalOpen,
    isSavingStatusChange,
    confirmationModalMessage,
    loading,
    error,
    handleApproveClick,
    handleConfirmationModalActionClick,
    handleEditClick,
    handleMenuAction,
    updateEvent,
    onClose,
    currentStep,
    totalSteps,
    handleNextClick,
    handlePreviousClick,
    userType,
  } = useEventCreateEditContext();

  // This is used to determine what action to take after the form is submitted
  // Either handleNextClick or handlePreviousClick
  // These are Refs because they are used in the onSubmit method of the form and
  // their values are changed just prior to form submission, so it ensures that
  // they are correct when onSuccess runs
  const actionAfterSubmit = useRef<() => void>(() => {});
  const closeOnSuccess = useRef(false);

  // This is used to determine if the form is currently being saved
  const [isSaving, setIsSaving] = useState(false);

  const {
    form,
    onSubmit,
    // error: formError,
    // defaultValues,
  } = useEventCreateEditForm({
    event,
    defaultOrganizationId,
    userEmailAddress,
    onSuccess: (event: EventByIdQuery["eventById"]) => {
      setIsSaving(false);
      // Update the event in useEventCreateEditModal AND useEvents
      updateEvent(event);
      // Reset the dirty states on the form with the current values
      // We do this because we save with each "next"/"prev" click
      // and we don't want to retrigger the same save action
      form.reset(form.getValues());
      // Show a toast to the user to let them know the event was updated
      toastFactory.create({
        title: t("core:SUCCESS"),
        description: t("events:APP.MODAL.MUTATION_RESULT.SUCCESS", {
          title: getMultilingualString(event?.title ?? {}),
        }),
        duration: 3000,
      });
      // This will usually either be handleNextClick or handlePreviousClick
      if (typeof actionAfterSubmit.current === "function") {
        actionAfterSubmit.current();
      }
      if (closeOnSuccess.current) {
        onClose();
      }
    },
    onError: () => {
      setIsSaving(false);
      // Show a toast to the user to let them know there was an error
      toastFactory.create({
        title: t("core:ERROR.TITLE"),
        description: t("events:APP.MODAL.MUTATION_RESULT.ERROR", {
          title: getMultilingualString(event?.title ?? {}),
        }),
        duration: 3000,
      });
    },
  });

  // When the modal is closed, we need to reset the form
  // The ensures the UI does not show error states when the
  // modal is reopened
  useEffect(() => {
    if (!isOpen) {
      closeOnSuccess.current = false;
      setIsSaving(false);
      form.reset();
    } else {
      // There is no UI for this form control, so we need to register it
      // so that it can be reset correctly
      form.register("imagesToKeep");
    }
  }, [form, isOpen]);

  // When clicking next/prev, we need to trigger the form validation and possibly
  // save the current values
  // and then call the onSuccessAction callback
  const triggerForm = async (
    onSuccessAction: () => void,
    isSubmitting = false,
  ) => {
    // If we're submitting, we need to set the isSubmitting field to true
    // and mark it as dirty
    if (isSubmitting) {
      form.setValue("isSubmitting", true, { shouldDirty: true });
    }
    const success = await form.trigger();
    if (success) {
      if (Object.keys(form.formState.dirtyFields).length > 0) {
        // We need to wrap the onSuccessAction in an anonymous function
        // so that it isn't called immediately
        actionAfterSubmit.current = () => onSuccessAction();
        closeOnSuccess.current = isSubmitting;
        setIsSaving(true);
        onSubmit();
      } else {
        onSuccessAction();
      }
    }
  };

  const getModalContent = () => {
    if (mode === "approve") return <EventPreview />;

    switch (currentStep) {
      case 1:
        return <EventDetails />;
      case 2:
        return <EventHostAndLocations />;
      case 3:
        return <EventPhotos />;
      case 4:
        return <EventSchedule />;
      case 5:
        return <EventPreview />;
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        placement={"rightLargePane"}
        onOpenChange={onClose}
        closeTriggerVisual={"subtle"}
        closeTriggerPlacement="whereabouts"
        closeTriggerProps={{
          bgColor: "ink.1000/75",
          backdropFilter: "blur(4px)",
          borderRadius: "full",
          top: "32px",
          right: { base: "16px", md: "32px" },
          _hover: {
            bgColor: "ink.1000/95",
            "&[disabled]": {
              bgColor: "ink.1000/75",
            },
          },
          _focusVisible: {
            bgColor: "ink.1000/95",
            "&[disabled]": {
              bgColor: "ink.1000/75",
            },
          },
          style: {
            transition: "background-color 0.2s ease-in-out",
          },
          children: (
            <Icon Element={Xmark} w="16px" h="16px" color="icon.inverse" />
          ),
        }}
        noMaxH
        userSelect="none"
        zIndex={"medium"}
        closeOnInteractOutside={false}
      >
        <Grid
          gridTemplateRows={"1fr 88px"}
          columnGap={0}
          gridColumnGap={0}
          gap={0}
          w={{ base: "100%", md: "520px" }}
          h="full"
          bgColor="#FFF"
        >
          {loading ? (
            <Center h="full">
              <Spinner w="32px" h="32px" />
            </Center>
          ) : error ? (
            <EmptyState text="Error loading event" p="500" h="full" />
          ) : (
            <Box p={mode === "approve" ? "0" : "500"} overflowY="auto">
              <EventModalHeader />
              <FormProvider {...form}>
                <form id="event-create-edit-form" onSubmit={onSubmit}>
                  {getModalContent()}
                </form>
              </FormProvider>
            </Box>
          )}
          <EventCreateEditModalFooter
            isSaving={isSaving || isSavingStatusChange}
            userType={userType}
            currentStep={currentStep}
            totalSteps={totalSteps}
            handleApproveClick={handleApproveClick}
            handleDeclineClick={() => handleMenuAction({ value: "decline" })}
            handleEditClick={handleEditClick}
            handleMenuAction={handleMenuAction}
            handleNextClick={() => triggerForm(handleNextClick)}
            handlePreviousClick={() => triggerForm(handlePreviousClick)}
            handleSubmitClick={() => triggerForm(() => {}, true)}
          />
        </Grid>
      </Modal>
      <SimpleDialog
        open={isConfirmationModalOpen}
        title={t("core:CONFIRM.TITLE")}
        message={confirmationModalMessage}
        cancelButtonCopy={t("core:CONFIRM.NO")}
        onCancelClick={() => handleConfirmationModalActionClick(false)}
        confirmButtonCopy={t("core:CONFIRM.YES")}
        confirmButtonVisual="destructive"
        onConfirmClick={() => handleConfirmationModalActionClick(true)}
        size="md"
      />
    </>
  );
};
