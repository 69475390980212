import {
  EnumDKeyAssetType,
  type AssetFile,
  FileUpdateMutation,
  type AssetImage,
  ImageUpdateMutation,
  useFileUpdateMutation,
  useImageUpdateMutation,
} from "gql/generated";
import { useCallback } from "react";
import { useFormHandler } from "shared/hooks";
import { z } from "zod";
import i18n, { i18nNamespace, loadLanguagePacks } from "i18n";
import { FetchResult } from "@apollo/client";
import { createMultilingual } from "shared/utils";

// Ensure the required language packs are loaded
await loadLanguagePacks([i18nNamespace.Core, i18nNamespace.Directory]);

export const captionFormSchema = z.object({
  caption: z
    .string({
      required_error: `${i18n.t("core:IMAGE.CAPTION.LABEL")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
    })
    .trim()
    .min(1, {
      message: `${i18n.t("core:IMAGE.CAPTION.LABEL")} ${i18n.t("core:FORM_ERRORS.REQUIRED")}`,
    }),
});

export type CaptionFormValues = z.infer<typeof captionFormSchema>;

type UseCaptionFormProps = {
  assetId: string;
  assetType: EnumDKeyAssetType.AssetFile | EnumDKeyAssetType.AssetImage;
  defaultCaption: string;
  onSuccess: (asset: Partial<AssetFile> | Partial<AssetImage>) => void;
  onError: (error: string) => void;
};

export const useImageCaptionModalForm = ({
  assetId,
  assetType,
  defaultCaption,
  onSuccess,
  onError,
}: UseCaptionFormProps) => {
  const defaultValues: CaptionFormValues = { caption: defaultCaption };

  const [fileUpdateMutation] = useFileUpdateMutation();
  const [imageUpdateMutation] = useImageUpdateMutation();
  const updateMutation =
    assetType === EnumDKeyAssetType.AssetFile
      ? fileUpdateMutation
      : imageUpdateMutation;

  return useFormHandler<CaptionFormValues>(
    useCallback(
      async (data) => {
        if (data) {
          await updateMutation({
            variables: {
              id: assetId,
              record: { caption: createMultilingual(data.caption ?? "") },
            },
          })
            .then((response) => {
              if (assetType === EnumDKeyAssetType.AssetFile) {
                onSuccess(
                  (response as FetchResult<FileUpdateMutation>).data?.fileUpdate
                    ?.record as Partial<AssetFile>,
                );
              } else {
                onSuccess(
                  (response as FetchResult<ImageUpdateMutation>).data
                    ?.imageUpdate?.record as Partial<AssetImage>,
                );
              }
            })
            .catch(() =>
              onError(
                `${i18n.t("core:IMAGE.CAPTION.ERROR")} ${i18n.t("core:FORM_ERRORS.PLEASE_TRY_AGAIN")}`,
              ),
            );
        }
      },
      [assetId, assetType, onError, onSuccess, updateMutation],
    ),
    defaultValues,
    { schema: captionFormSchema, mode: "onBlur" },
  );
};
