import { Text } from "@ttc3k/trekker";
import { WidgetOperatorWithOverrides } from "../../shared/types";
import { styled } from "styled-system/jsx";
import { useTranslation } from "react-i18next";
import { DateRangeIso } from "gql/generated.ts";
import i18n from "i18next";
import { DATE_PATTERN_TIME, formatDate } from "shared/utils";
import { toZonedTime } from "date-fns-tz";

const formatDayHours = (day: DateRangeIso | null) => {
  return day === null
    ? i18n.t(`widgets:OPERATOR.DETAILS_SIDE_PANE.OPERATING_HOURS.CLOSED`)
    : `${formatDate(toZonedTime(day.start, "UTC"), DATE_PATTERN_TIME)} - ${formatDate(toZonedTime(day.end, "UTC"), DATE_PATTERN_TIME)}`;
};

export const HoursOfOperation = ({
  data,
}: {
  data: WidgetOperatorWithOverrides["hoursOfOperation"];
}) => {
  const { t } = useTranslation();
  if (!data) return null;

  return (
    <table>
      <tbody>
        {Object.entries(data).map(([day, value]) => {
          if (day === "__typename") return;
          return (
            <tr key={day}>
              <styled.td paddingRight={"300"}>
                <Text visual={"bodyRegular"}>
                  {t(`widgets:LOOKUPS.DAYS.${day.toUpperCase()}_FULL`)}
                </Text>
              </styled.td>
              <td>
                <Text visual={"bodyRegular"} color={"text.mid"}>
                  {formatDayHours(value as DateRangeIso)}
                </Text>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
