import { MotionStyle, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export type CollapsibleProps = React.PropsWithChildren & {
  open: boolean;
  style?: MotionStyle | undefined;
  contentContainerClassName?: string;
};

/**
 * This component animates the height of its children. If the height of
 * its children changes this component will smoothly scroll that change.
 */
export const Collapsible = ({
  open,
  style,
  contentContainerClassName,
  children,
}: CollapsibleProps) => {
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | "auto">("auto");

  // This useEffect sets up a ResizeObserver to dynamically update the height
  // state based on the content's size, ensuring smooth open/close animations.
  useEffect(() => {
    if (contentContainerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // There is only one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(contentContainerRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  const variants = {
    open: {
      height: height,
      opacity: 1,
      overflow: "visible",
    },
    closed: {
      height: 0,
      opacity: 0,
      overflow: "hidden",
    },
  };
  return (
    <motion.div
      initial={open ? "open" : "closed"}
      animate={open ? "open" : "closed"}
      inherit={false}
      variants={variants}
      style={{ height, ...style }}
      transition={{
        ease: "linear",
        duration: 0.15,
      }}
    >
      <div ref={contentContainerRef} className={contentContainerClassName}>
        {children}
      </div>
    </motion.div>
  );
};
