import {
  FieldValues,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  EventCreateEditFormValues,
  EventLocationFormValues,
  getEventLocationFormValues,
  isEmptyLocation,
} from "../EventCreateEditModal/useEventCreateEditForm";
import {
  EnumEventAccessOperatorStatus,
  EnumEventEventLocationLocationInfoType,
  EventAccess,
} from "gql/generated";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { getAddressString } from "shared/utils";
import { LocationListData } from "apps/events/types/LocationListData";

type HostLocationSelectionProps<T extends FieldValues> = {
  allLocations: LocationListData[];
  formFieldRoot: Path<T>;
  setIsEmpty?: Dispatch<SetStateAction<boolean>>;
  limitHostToOperatorEntityId?: string;
};

export const useHostAndLocationSelection = <T extends FieldValues>({
  allLocations,
  formFieldRoot,
  setIsEmpty,
  limitHostToOperatorEntityId,
}: HostLocationSelectionProps<T>) => {
  const { control, getValues, register, setValue } =
    useFormContext<EventCreateEditFormValues>();
  const locationType = useWatch({ control, name: "locationType" });
  const locationBeingUpdated = useWatch({
    control,
    name: formFieldRoot as PathValue<T, Path<T>>,
  });
  // We need to register changes to the access fields so their dirty
  // state is updated correctly
  register("access.operator");
  register("access.organizations");

  // When accessing as the operator, we will only show that location in the host combobox
  const allLocationsFilteredForHostLimiting = useMemo(
    () =>
      limitHostToOperatorEntityId
        ? allLocations.filter(
            (location) => location.value === limitHostToOperatorEntityId,
          )
        : allLocations,
    [allLocations, limitHostToOperatorEntityId],
  );

  const [lastSearch, setLastSearch] = useState<string>("");
  const [locationMode, setLocationMode] = useState<"db" | "new">("db");
  const [filteredLocationList, setFilteredLocationList] = useState<
    LocationListData[]
  >(allLocationsFilteredForHostLimiting);

  /**
   * We need to keep track of the selected location ID and mode so we can
   * update the combobox value when the location is updated
   */
  const [selectedLocationId, setSelectedLocationId] = useState<string>("");
  useEffect(() => {
    if (allLocationsFilteredForHostLimiting.length > 0) {
      const formValue = getValues(formFieldRoot as PathValue<T, Path<T>>);
      const locationId = formValue?.locationInfo?._entityId ?? "";
      const location = allLocationsFilteredForHostLimiting.find(
        (location) => location.value === locationId,
      );
      if (location || !formValue || isEmptyLocation(formValue)) {
        setSelectedLocationId(locationId);
        setLocationMode("db");
      } else {
        setSelectedLocationId("");
        setLocationMode("new");
      }
    }
  }, [formFieldRoot, allLocationsFilteredForHostLimiting, getValues]);

  // Whenever the location data is updated, we need to check if it is empty
  // If it is, we need to disable the Done button in the modal
  useEffect(() => {
    if (locationBeingUpdated && setIsEmpty) {
      setIsEmpty(
        isEmptyLocation(locationBeingUpdated as EventLocationFormValues),
      );
    }
  }, [locationBeingUpdated, setIsEmpty]);

  /**
   * Handle the change of the combobox
   * - Whenever the combobox opens or closes, we reset the filtered location list to all locations
   */
  const handleComboboxOpenChange = () =>
    setFilteredLocationList(allLocationsFilteredForHostLimiting);

  /**
   * Handle the change of the event location combobox
   * - We need to set each field individually because setting the parent object does
   *   not cause the controlled inputs to update correctly
   * @param value - The value of the combobox
   */
  const handleEventLocationComboboxValueChange = (value: string) => {
    if (value === "NEW") {
      // setLocationMode("new");
      handleSwitchEventLocationMode("new");
      setSelectedLocationId("");
      setValue("eventLocation.venue", lastSearch, { shouldDirty: true });
    } else {
      setLocationMode("db");
      // Find the location based on the ID
      const location = allLocationsFilteredForHostLimiting.find(
        (location) => location.value === value,
      );
      if (location) {
        setSelectedLocationId(location.data.locationInfo._entityId ?? "");
        const formData = getEventLocationFormValues(location.data);
        setValue(
          "eventLocation.contact.fullAddress",
          getAddressString({
            line1: formData.contact.address?.line1,
            line2: formData.contact.address?.line2,
            city: formData.contact.address?.city,
            subdivision: formData.contact.address?.subdivision,
            postalCode: formData.contact.address?.postalCode,
            country: formData.contact.address?.country,
          }),
          { shouldDirty: true },
        );
        setValue(
          "eventLocation.contact.address",
          formData.contact.address ?? {},
          {
            shouldDirty: true,
          },
        );
        setValue("eventLocation.contact.email", formData.contact.email ?? "", {
          shouldDirty: true,
        });
        setValue("eventLocation.contact.phone", formData.contact.phone ?? "", {
          shouldDirty: true,
        });
        setValue(
          "eventLocation.contact.links.website",
          formData.contact.links.website ?? "",
          {
            shouldDirty: true,
          },
        );
        setValue("eventLocation.venue", formData.venue, {
          shouldDirty: true,
        });
        setValue("eventLocation.locationInfo", formData.locationInfo, {
          shouldDirty: true,
        });
        // Update the access field
        updateEventAccess(location);
      }
    }
  };

  /**
   * Handle the change of the other locations combobox
   * - We need to set each field individually because setting the parent object does
   *   not cause the controlled inputs to update correctly
   * @param value - The value of the combobox
   * @param index - The index of the combobox
   */
  const handleOtherLocationsComboboxValueChange = (
    value: string,
    index: number,
  ) => {
    if (value === "NEW") {
      // setLocationMode("new");
      handleSwitchOtherLocationsMode("new", index);
      setSelectedLocationId("");
      setValue(`otherLocations.${index}.venue`, lastSearch, {
        shouldDirty: true,
      });
    } else {
      setLocationMode("db");
      // Find the location based on the ID
      const location = allLocationsFilteredForHostLimiting.find(
        (location) => location.value === value,
      );
      if (location) {
        setSelectedLocationId(location.data.locationInfo._entityId ?? "");
        const formData = getEventLocationFormValues(location.data);
        setValue(
          `otherLocations.${index}.contact.fullAddress`,
          getAddressString({
            line1: formData.contact.address?.line1,
            line2: formData.contact.address?.line2,
            city: formData.contact.address?.city,
            subdivision: formData.contact.address?.subdivision,
            postalCode: formData.contact.address?.postalCode,
            country: formData.contact.address?.country,
          }),
          { shouldDirty: true },
        );
        setValue(
          `otherLocations.${index}.contact.address`,
          formData.contact.address ?? {},
          {
            shouldDirty: true,
          },
        );
        setValue(
          `otherLocations.${index}.contact.email`,
          formData.contact.email ?? "",
          {
            shouldDirty: true,
          },
        );
        setValue(
          `otherLocations.${index}.contact.phone`,
          formData.contact.phone ?? "",
          {
            shouldDirty: true,
          },
        );
        setValue(
          `otherLocations.${index}.contact.links.website`,
          formData.contact.links.website ?? "",
          {
            shouldDirty: true,
          },
        );
        setValue(`otherLocations.${index}.venue`, formData.venue, {
          shouldDirty: true,
        });
        setValue(
          `otherLocations.${index}.locationInfo`,
          formData.locationInfo,
          {
            shouldDirty: true,
          },
        );
      }
    }
  };

  /**
   * Handle the change of the combobox input value
   * @param search - The search value
   */
  const handleComboboxInputValueChange = (search: string) => {
    // Unlike other searchs, if nothing is returned, we want to show a
    // button to allow the user to create a new location
    setFilteredLocationList(
      allLocationsFilteredForHostLimiting.filter((date) =>
        date.label.toLowerCase().includes(search.toLowerCase()),
      ),
    );
    setLastSearch(search);
  };

  /**
   * Handle the change of the event location mode
   * @param mode - The mode to change to
   */
  const handleSwitchEventLocationMode = (mode: "db" | "new") => {
    setLocationMode(mode);
    setValue("eventLocation", getEventLocationFormValues({ venue: {} }), {
      shouldDirty: true,
    });
  };

  /**
   * Handle the change of the other locations mode
   * @param mode - The mode to change to
   * @param index - The index of the other locations combobox
   */
  const handleSwitchOtherLocationsMode = (
    mode: "db" | "new",
    index: number,
  ) => {
    setLocationMode(mode);
    setValue(
      `otherLocations.${index}`,
      getEventLocationFormValues({ venue: {} }),
      { shouldDirty: true },
    );
  };

  const handleCustomLocationNameBlur = () => {
    updateEventAccess();
  };

  /**
   * Update the event access based on the location type
   * - When the host record is updated, we need to update the event access
   *   to ensure that the host operator can also access this event
   * @param location - The location to update the access for
   */
  const updateEventAccess = (location?: LocationListData) => {
    const currentAccess = getValues("access");

    if (
      location &&
      location.data.locationInfo.type ===
        EnumEventEventLocationLocationInfoType.Operator
    ) {
      // If the access _entityId is different than the location's _entityId,
      // we need to update the access _entityId
      if (
        currentAccess.operator?._entityId !==
        location.data.locationInfo._entityId
      ) {
        setValue(
          "access.operator._entityId",
          location.data.locationInfo._entityId,
          {
            shouldDirty: true,
          },
        );
      }

      // Ensure all of the location's organizations are in the access list
      updateOrganizationAccess(location, currentAccess);
    } else if (
      location &&
      location.data.locationInfo.type ===
        EnumEventEventLocationLocationInfoType.Organization
    ) {
      // If the host is an organization location, we need to clear the operator access
      setValue("access.operator", undefined, { shouldDirty: true });
      // We need to make sure the organization is in the access list
      updateOrganizationAccess(location, currentAccess);
    } else if (!location) {
      // The host location is custom, so we need to clear the access fields
      setValue("access.operator", undefined, { shouldDirty: true });
      // And we'll keep the current organizations, so won't touch that value
    }
  };

  /**
   * Update the organization access based on the location type
   * - We need to ensure all organizations of the location have
   *   access to the event
   * @param location - The location to update the access for
   * @param currentAccess - The current access values
   */
  const updateOrganizationAccess = (
    location: LocationListData,
    currentAccess: EventAccess,
  ) => {
    // Ensure all of the location's organizations are in the access list
    const organizations = [...currentAccess.organizations];
    const status =
      organizations[0]?.status ?? EnumEventAccessOperatorStatus.Draft;
    location.data.enrollments?.forEach((enrollment) => {
      if (
        !organizations.find(
          (organization) => organization?._entityId === enrollment,
        )
      ) {
        organizations.push({
          _entityId: enrollment,
          status,
        });
      }
    });
    if (organizations.length !== currentAccess.organizations.length) {
      setValue(
        "access.organizations",
        organizations
          .filter((o) => !!o)
          .map((o) => ({
            _entityId: o._entityId,
            status: o.status ?? EnumEventAccessOperatorStatus.Draft,
          })),
        { shouldDirty: true },
      );
    }
  };

  return {
    locationType,
    filteredLocationList,
    locationMode,
    selectedLocationId,
    setLocationMode,
    handleComboboxOpenChange,
    handleEventLocationComboboxValueChange,
    handleOtherLocationsComboboxValueChange,
    handleComboboxInputValueChange,
    handleSwitchEventLocationMode,
    handleSwitchOtherLocationsMode,
    handleCustomLocationNameBlur,
  };
};
