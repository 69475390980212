import { parse, subDays } from "date-fns";
import { addDays } from "date-fns";

import { format } from "date-fns";
import { EVENT_DATE_FORMAT } from "shared/utils";
import { EventCreateEditFormValues } from "../../EventCreateEditModal/useEventCreateEditForm";
import { useFormContext, useWatch } from "react-hook-form";

export const useMultiEventSchedule = () => {
  const { control, setValue, register } =
    useFormContext<EventCreateEditFormValues>();

  const startDate = useWatch({ control, name: "startDate" });
  const dates = useWatch({ control, name: "recurrencePattern.multiDates" }) ?? [
    startDate,
  ];
  const schedules = useWatch({ control, name: "schedules" }) ?? [
    {
      allDay: false,
      timeSlots: [{ from: "", to: "", endsNextDay: false, note: "" }],
    },
  ];
  // Needed to ensure the schedules array is marked as dirty correctly as dates are added / removed
  register("schedules");

  /**
   * Handles the change of a date in the multi-event schedule
   * - When the date changes, the min / max dates for the calendar popovers
   *   will be updated to ensure dates are not duplicated and don't overlap
   * @param date - The new date to set
   * @param index - The index of the date to change
   */
  const handleDateChange = (date: string, index: number) => {
    setValue("recurrencePattern.multiDates", [
      ...dates.slice(0, index),
      date,
      ...dates.slice(index + 1),
    ]);
    // If this is the first date, set the startDate to the new date
    if (index === 0) {
      setValue("startDate", date, {
        shouldDirty: true,
      });
    }
    // If this is the last date, set the endDate
    if (index === dates.length - 1) {
      setValue("endDate", date, {
        shouldDirty: true,
      });
    }
  };

  /**
   * Handles the addition of a new date to the multi-event schedule
   * - Adds a new date to the end of the array
   * - Sets the endDate to the new date
   */
  const handleAddDate = () => {
    // Add a new date (one day after the last date)
    const newDates = [...dates, addOneToDate(dates[dates.length - 1])];
    setValue("recurrencePattern.multiDates", newDates, {
      shouldDirty: true,
    });
    // Add a new schedule for the new date
    const newSchedules = [...schedules, schedules[schedules.length - 1]];
    setValue("schedules", newSchedules, { shouldDirty: true });
    // Update the endDate to the latest date
    setValue("recurrencePattern.endDate", newDates[newDates.length - 1], {
      shouldDirty: true,
    });
  };

  /**
   * Handles the removal of a date from the multi-event schedule
   * - Removes the date from the array
   * - Sets the endDate to the new last date in the array
   */
  const handleRemoveDate = (index: number) => {
    // Remove the date from the array
    const newDates = [...dates.slice(0, index), ...dates.slice(index + 1)];
    setValue("recurrencePattern.multiDates", newDates);
    setValue("recurrencePattern.endDate", newDates[newDates.length - 1], {
      shouldDirty: true,
    });
    // Remove the schedule from the schedules array
    const newSchedules = [
      ...schedules.slice(0, index),
      ...schedules.slice(index + 1),
    ];
    setValue("schedules", newSchedules, { shouldDirty: true });
    // Ensure the startDate and endDate are updated correctly
    setValue("startDate", newDates[0], {
      shouldDirty: true,
    });
    setValue("endDate", newDates[newDates.length - 1], {
      shouldDirty: true,
    });
  };

  /**
   * Adds one day to a date
   * @param date - The date to add one day to
   * @returns The new date
   */
  const addOneToDate = (date: string) =>
    format(
      addDays(parse(date, EVENT_DATE_FORMAT, new Date()), 1),
      EVENT_DATE_FORMAT,
    );

  /**
   * Subtracts one day from a date
   * @param date - The date to subtract one day from
   * @returns The new date
   */
  const subtractOneFromDate = (date: string) =>
    format(
      subDays(parse(date, EVENT_DATE_FORMAT, new Date()), 1),
      EVENT_DATE_FORMAT,
    );

  return {
    dates,
    handleDateChange,
    handleAddDate,
    handleRemoveDate,
    addOneToDate,
    subtractOneFromDate,
  };
};
