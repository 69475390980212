import { ButtonProps, Text, Button } from "@ttc3k/trekker";
import { Skeleton, SkeletonProps } from "shared/components";
import { JsxStyleProps } from "styled-system/types";

type JsxStylePropsWithClassName = JsxStyleProps & { className?: string };
export interface EmptyStateProps
  extends SkeletonProps,
    JsxStylePropsWithClassName {
  text?: string;
  ctaButtonProps?: ButtonProps;
  part?: string;
}

export const EmptyState = ({
  text,
  ctaButtonProps,
  ...props
}: EmptyStateProps) => {
  return (
    <Skeleton {...props}>
      {text && (
        <Text visual={"bodyMedium"} color={"text.mid"} textAlign={"center"}>
          {text}
        </Text>
      )}
      {ctaButtonProps && (
        <Button
          size={"lg"}
          visual={"primary"}
          mode={"light"}
          {...ctaButtonProps}
        />
      )}
    </Skeleton>
  );
};
