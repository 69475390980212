import { Button, Combobox, Text } from "@ttc3k/trekker";
import { Box, Flex } from "styled-system/jsx";
import { useTranslation } from "react-i18next";
import { useHostAndLocationSelection } from "./useHostLocationSelection";
import {
  ControlledInput,
  ControlledInputProps,
} from "shared/components/formControl/ControlledInput";
import { EnumEventLocationType } from "gql/generated";
import { ControlledAddressAutofill } from "shared/components/formControl/ControlledAddressAutofill";
import { FieldValues, Path } from "react-hook-form";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { LocationListData } from "apps/events/types/LocationListData";

type HostLocationSelectionProps<T extends FieldValues> = {
  isHost: boolean;
  index: number;
  allowCustom: boolean;
  allLocations: LocationListData[];
  formFieldRoot: Path<T>;
  eventLinkControlledInputProps: ControlledInputProps;
  emailControlledInputProps: ControlledInputProps;
  phoneControlledInputProps: ControlledInputProps;
  setIsEmpty?: Dispatch<SetStateAction<boolean>>;
};

export const HostLocationSelection = <T extends FieldValues>({
  isHost,
  index = 0,
  allowCustom,
  allLocations,
  formFieldRoot,
  eventLinkControlledInputProps,
  emailControlledInputProps,
  phoneControlledInputProps,
  setIsEmpty,
}: HostLocationSelectionProps<T>) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_HOST.LOCATION_SELECTION",
  });

  const {
    locationType,
    filteredLocationList,
    locationMode,
    selectedLocationId,
    handleComboboxOpenChange,
    handleEventLocationComboboxValueChange,
    handleOtherLocationsComboboxValueChange,
    handleComboboxInputValueChange,
    handleSwitchEventLocationMode,
    handleSwitchOtherLocationsMode,
    handleCustomLocationNameBlur,
  } = useHostAndLocationSelection({
    allLocations,
    formFieldRoot,
    setIsEmpty,
  });

  return (
    <Flex flexDir="column" gap="200">
      {/* Location name */}
      {locationMode === "db" ? (
        <Combobox.Root
          label={isHost ? t("HOST") : t("LOCATION")}
          items={allLocations}
          value={[selectedLocationId]}
          tagVisualVariant={"accent.blue"}
          onOpenChange={handleComboboxOpenChange}
          onValueChange={(d) =>
            isHost
              ? handleEventLocationComboboxValueChange(d.value[0])
              : handleOtherLocationsComboboxValueChange(d.value[0], index)
          }
          onInputValueChange={(d) =>
            handleComboboxInputValueChange(d.inputValue)
          }
        >
          {filteredLocationList.length > 0
            ? filteredLocationList.map((location) => (
                <Combobox.Item
                  key={location.value}
                  item={location}
                  {...location}
                />
              ))
            : allowCustom && (
                <Combobox.Item
                  label={t("ADD_NEW_LOCATION")}
                  item={{
                    label: t("ADD_NEW_LOCATION"),
                    value: "NEW",
                  }}
                />
              )}
        </Combobox.Root>
      ) : (
        <Box position="relative">
          <ControlledInput
            name={`${formFieldRoot}.venue`}
            label={isHost ? t("HOST") : t("LOCATION")}
            onBlurCapture={handleCustomLocationNameBlur}
          />
          <Button
            visual="unstyled"
            color={{
              base: "text.accent.blue.mid",
              hover: "text.accent.blue.dark",
              focusVisible: "text.accent.blue.dark",
            }}
            onClick={() =>
              isHost
                ? handleSwitchEventLocationMode("db")
                : handleSwitchOtherLocationsMode("db", index)
            }
            type="button"
            position="absolute"
            right="0"
            top="0"
          >
            {t("SELECT_LOCATION")}
          </Button>
        </Box>
      )}

      {/* Event link */}
      <ControlledInput {...eventLinkControlledInputProps} />

      {/* Event address */}
      {locationType === EnumEventLocationType.InPerson && (
        <ControlledAddressAutofill
          addressInputName={`${formFieldRoot}.contact.fullAddress`}
          addressObjInputName={`${formFieldRoot}.contact.address`}
          coordsInputName={`${formFieldRoot}.contact.address.location.coordinates`}
          lngInputName={`${formFieldRoot}.contact.address.location.coordinates.lng`}
          latInputName={`${formFieldRoot}.contact.address.location.coordinates.lat`}
          displayAddressOnly={true}
        />
      )}

      {/* Email and Phone */}
      <Text visual="bodyRegular" color="text.mid">
        {t("EMAIL_AND_PHONE_HINT")}
      </Text>
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="flex-start"
        gap="200"
      >
        <ControlledInput {...emailControlledInputProps} />
        <ControlledInput {...phoneControlledInputProps} />
      </Flex>
    </Flex>
  );
};
