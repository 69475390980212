import { WidgetOperatorsWithOverrides } from "widgets/operators/shared/types";
import { useDisplayOperators } from "widgets/operators/hooks";
import { Box, Flex } from "styled-system/jsx";
import { Button } from "@ttc3k/trekker";
import { OperatorWidgetCard } from "shared/components";
import { OperatorWithOverrides } from "gql/generated";
import { useOperatorsWidgetContext } from "../OperatorsContext";
import { ArrowLeft, ArrowRight } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { css } from "styled-system/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "./swiper.css";
import { useMediaQuery } from "react-responsive";
import { useSwiperScroll } from "shared/hooks";
import { RenderDetailsSidePane } from "shared/components/organisms/RenderDetailsSidePane";
import { PostHogCustomEvent } from "shared/hooks/usePostHog";

type OperatorCarouselProps = {
  filteredOperators: WidgetOperatorsWithOverrides;
  viewOnly?: boolean;
};

export const OperatorCarousel = ({
  filteredOperators,
  viewOnly = false,
}: OperatorCarouselProps) => {
  const { t } = useTranslation("widgets", { keyPrefix: "OPERATORS_WIDGET" });
  const { lang, operatorDetailsDisplayMode, operatorDetailsLink } =
    useOperatorsWidgetContext();
  const {
    displayedOperators,
    onSeeDetails,
    isLoadingMore,
    onLoadMoreOperators,
    selectedOperatorId,
    onExitSeeDetails,
  } = useDisplayOperators({
    filteredOperators,
  });

  const isSm = useMediaQuery({ maxWidth: "39.9975rem" });
  const isMd = useMediaQuery({
    maxWidth: "47.9975rem",
    minWidth: "39.9975rem",
  });
  const isLg = useMediaQuery({ minWidth: "63.9975rem" });

  const isReachedEndOfData =
    displayedOperators.length === filteredOperators.length;

  const { scroll, setSwiper, handleNextClick, handlePrevClick } =
    useSwiperScroll();

  if (displayedOperators.length === 0) return null;
  return (
    <>
      <Flex flexDir={"column"} gap={"300"} width={"full"} position={"relative"}>
        <Box
          position={"relative"}
          overflowX={"hidden"}
          part={"carousel__container"}
        >
          <Swiper
            scrollbar={{
              hide: false,
              draggable: true,
              snapOnRelease: true,
            }}
            modules={[Scrollbar]}
            slidesPerView={"auto"}
            freeMode={true}
            spaceBetween={
              isSm ? "16px" : isMd ? "24px" : isLg ? "32px" : "16px"
            }
            onInit={setSwiper}
          >
            {displayedOperators.map(operator => (
              <SwiperSlide key={operator._entityId} style={{ width: "auto" }}>
                <OperatorWidgetCard
                  operator={operator as Partial<OperatorWithOverrides>}
                  viewOnly={viewOnly}
                  onClick={onSeeDetails}
                  lang={lang}
                  orientation={"portrait"}
                  cardSurfaceProps={{
                    className: css({
                      width: { base: "260px", md: "340px" },
                    }),
                  }}
                  operatorDetailsDisplayMode={operatorDetailsDisplayMode}
                  operatorDetailsLink={operatorDetailsLink}
                />
              </SwiperSlide>
            ))}
            {/* Load more button */}
            <SwiperSlide
              key={"load-more"}
              style={{
                width: "auto",
                display: isReachedEndOfData ? "none" : "initial",
              }}
            >
              <Box
                w={{ base: "260px", md: "340px" }}
                h={"full"}
                display={"flex"}
                p={"400"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  mode={"light"}
                  size={"lg"}
                  w={"max"}
                  onClick={onLoadMoreOperators}
                  isLoading={isLoadingMore}
                  part={"widget__btn-load-more"}
                >
                  {t("LOAD_MORE")}
                </Button>
              </Box>
            </SwiperSlide>
          </Swiper>
          <Box
            position={"absolute"}
            h={"full"}
            w={{ base: "100px", sm: "128px" }}
            right={0}
            top={0}
            bg={
              "linear-gradient(90deg, rgba(255, 253, 251, 0.00) 0%, #FFFDFB 83.98%)"
            }
            pointerEvents={"none"}
            display={scroll.isEnd || !scroll.isScrollOpen ? "none" : "block"}
            zIndex={"10"}
            part={"carousel__gradient"}
          />
        </Box>
        {scroll.isScrollOpen && (
          <Flex
            part={"widget__controls"}
            gap={"150"}
            position={"absolute"}
            bottom={"-11px"}
            right={0}
            zIndex={"20"}
          >
            <Button
              Icon={ArrowLeft}
              mode={"light"}
              outlineWidth={"2px"}
              outlineColor={"border.dark"}
              onClick={() =>
                handlePrevClick(PostHogCustomEvent.ScrollOperatorsLeft)
              }
              part={"widget__btn-prev"}
              disabled={scroll.isBeginning}
            />
            <Button
              Icon={ArrowRight}
              mode={"light"}
              outlineWidth={"2px"}
              outlineColor={"border.dark"}
              onClick={() =>
                handleNextClick(PostHogCustomEvent.ScrollOperatorsRight)
              }
              part={"widget__btn-next"}
              disabled={scroll.isEnd}
            />
          </Flex>
        )}
      </Flex>
      <RenderDetailsSidePane
        selectedOperatorId={selectedOperatorId}
        onExitComplete={onExitSeeDetails}
        sidePaneType="operators"
      />
    </>
  );
};
