import { Text, Textarea } from "@ttc3k/trekker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlyupEditingModal } from "shared/components/organisms";
import { Flex } from "styled-system/jsx";

type TimeSlotNoteModalProps = {
  isOpen: boolean;
  note: string;
  onClose: () => void;
  onUpdateNote: (note: string) => void;
};

export const TimeSlotNoteModal = ({
  isOpen,
  note,
  onClose,
  onUpdateNote,
}: TimeSlotNoteModalProps) => {
  const { t } = useTranslation("events", {
    keyPrefix: "APP.MODAL.EVENT_SCHEDULE.TIME_SLOTS.NOTE",
  });
  const [updatedNote, setUpdatedNote] = useState(note);
  useEffect(() => setUpdatedNote(note), [note]);

  return (
    <FlyupEditingModal
      isOpen={isOpen}
      title={t("TITLE")}
      onClose={onClose}
      cancelButtonProps={{
        visual: "primary",
        mode: "light",
        type: "button",
        onClick: onClose,
        children: t("CANCEL"),
      }}
      saveButtonProps={{
        visual: "accent.blue",
        type: "button",
        onClick: () => onUpdateNote(updatedNote),
        children: t("DONE"),
      }}
    >
      <Flex flexDir="column" gap="400">
        <Text visual="bodyRegular" color="text.mid">
          {t("DESCRIPTION")}
        </Text>
        <Textarea
          label={t("LABEL")}
          name="note"
          rows={3}
          onChange={(e) => setUpdatedNote(e.target.value)}
          value={updatedNote}
        />
      </Flex>
    </FlyupEditingModal>
  );
};
