import { decode } from "blurhash";

/**
 * getBlurhashDataUrl
 * - Returns the blurhash as a dataUrl wrapped in a CSS url() function
 * @param blurhash
 * @returns
 */
export const getBlurhashDataUrl = (blurhash: string | undefined) => {
  if (!blurhash) return undefined;

  // Decode the blurhash into an image
  const pixels = decode(blurhash, 32, 32); // Decode into 32x32 pixels
  const canvas = document.createElement("canvas");
  canvas.width = 32;
  canvas.height = 32;
  const ctx = canvas.getContext("2d");

  // Create an ImageData object from the decoded pixels
  const imageData = new ImageData(new Uint8ClampedArray(pixels), 32, 32);
  ctx?.putImageData(imageData, 0, 0);

  return `url("${canvas.toDataURL()}")`;
};
